import { useCallback, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { setColumns } from '../../../../store/wardrobe/actions';
import {
    getCurrentWardrobeMeasurementsWall,
    getRemoveColumnWardrobeBySelectedColumn,
    getSelectedColumn,
    getSelectedColumnProductCode,
    getSplittedWardrobeBySelectedColumn,
    getVisibleColumnWall,
} from '../../../../store/wardrobe/selectors';
import { useMediaByType } from '../../../../store/media/selectors';
import { getWardrobeDimensions } from '../../../../store/products/selectors';
import { useLockEffect } from '../../../../hooks/useLockEffect';
import { fetchWardrobePrice } from '../../../../store/configurations/actions';
import { RootState } from '../../../../store';
import { PriceVisitor } from '../../../../services/nodes/wardrobe/visitor/PriceVisitor';
import { SimplifyMedia } from '../../../../store/media/reducer';
import { StoreVisitor } from '../../../../services/nodes/wardrobe/visitor/StoreVisitor';
import { createEmptyWrapper } from '../../../../services/nodes/wardrobe/wardrobe';
import { splitNumberValueToArray } from '../../../WardrobeWidth/helpers/splitNumberValueToArray';
import { CardOptionProps } from '../../../Card/components/CardOption/CardOption';
import { DetailsContentElement } from '../Details';
import { useCurrency } from '../../../Currency/CurrencyProvider';

const INITIAL_STATE: [null, null] = [null, null];

export const getSplitActionVisibility = (state: RootState) => {
    const selectedColumn = getSelectedColumn(state);
    const wall = selectedColumn?.wall || 'A';
    const { doubleClosetWidth, singleClosetWidth } = getWardrobeDimensions(state);
    const columns = getVisibleColumnWall(wall)(state);
    const columnCode = getSelectedColumnProductCode(state);
    const { width: columnsTotalWidth } = getCurrentWardrobeMeasurementsWall(wall)(state);

    if (selectedColumn === null || !singleClosetWidth || !doubleClosetWidth) {
        return { splitIsVisible: false, mergeIsVisible: false, removeIsVisible: false };
    }

    const columnWidths = splitNumberValueToArray({
        length: columns.length - 1,
        value: columnsTotalWidth,
        max: doubleClosetWidth.max,
        min: singleClosetWidth.min,
    });

    const isCornerColumn =
        wall === 'B' && (selectedColumn === columns[0] || selectedColumn === columns[columns.length - 1]);

    const removeIsVisible = columnWidths.length === columns.length - 1;
    const splitIsVisible = selectedColumn.data.width >= singleClosetWidth.min * 2 && !isCornerColumn;
    const mergeIsVisible = columns.length > 1 && columnCode === 'single_closet';

    return { splitIsVisible, mergeIsVisible, removeIsVisible };
};

export function useAllSplitActions(
    onOpenDetails: (detailsValues: DetailsContentElement) => void,
    shouldFetchPrice: boolean,
) {
    const selectedColumn = useSelector(getSelectedColumn);
    const { dispatch, getState } = useStore<RootState, any>();
    const splitColumnsMedia = useMediaByType('split_columns');
    const removeColumnsMedia = useMediaByType('merge_columns');
    const splittedColumn = useSelector(getSplittedWardrobeBySelectedColumn);
    const removedColumns = useSelector(getRemoveColumnWardrobeBySelectedColumn);
    const priceService = useSelector((state: RootState) => state.dependency.services?.price);
    const [prices, setPrices] = useState<[number | null, number | null]>(INITIAL_STATE);
    const [priceSplitted, priceRemove] = prices;
    const { singleClosetWidth } = useSelector(getWardrobeDimensions);
    const { currentCurrency } = useCurrency();

    const showItemDetails = useCallback(
        (media: SimplifyMedia) => {
            onOpenDetails({
                type: 'media',
                props: {
                    details: media,
                },
            });
        },
        [onOpenDetails],
    );

    const fetchPrices = useCallback<() => Promise<[number | null, number | null]>>(() => {
        if (!shouldFetchPrice) {
            return Promise.resolve([null, null]);
        }

        if (!priceService) {
            return Promise.reject(new Error('priceService not exist'));
        }

        const state = getState();
        const formattedDataSplitted = createEmptyWrapper(state);

        formattedDataSplitted.addChildren(splittedColumn);

        const formattedDataRemove = createEmptyWrapper(state);

        formattedDataRemove.addChildren(removedColumns);

        if (formattedDataSplitted === null || formattedDataRemove === null) {
            throw new Error('formattedDataSplitted or formattedDataSplitted not exist');
        }

        const priceVisitor = new PriceVisitor();

        setPrices([null, null]);

        return Promise.all([
            priceService.fetchPrice(priceVisitor.visitWrapperNode(formattedDataSplitted), currentCurrency),
            priceService.fetchPrice(priceVisitor.visitWrapperNode(formattedDataRemove), currentCurrency),
        ]).then(([dataSplitted, dataRemove]) => [
            dataSplitted.unitPriceWithChildItems,
            dataRemove.unitPriceWithChildItems,
        ]);
    }, [getState, priceService, removedColumns, shouldFetchPrice, splittedColumn, currentCurrency]);

    useLockEffect(fetchPrices, setPrices);

    const { splitIsVisible, removeIsVisible } = useSelector(getSplitActionVisibility);

    if (!selectedColumn || !singleClosetWidth) {
        return [];
    }

    const result: CardOptionProps[] = [];

    if (splitIsVisible) {
        result.push({
            variant: 'text-outside',
            imgPath: splitColumnsMedia.image,
            title: splitColumnsMedia.name,
            price: priceSplitted || 0,
            isLoadingPrice: priceSplitted === null,
            onDetailsClick: () => showItemDetails(splitColumnsMedia),
            onClick: () => {
                requestAnimationFrame(() => {
                    const visitor = new StoreVisitor();

                    dispatch(setColumns(splittedColumn.map((column) => visitor.visitColumnNode(column))));

                    dispatch(
                        fetchWardrobePrice({
                            currencyCode: currentCurrency,
                        }),
                    );
                });
            },
        });
    }

    if (removeIsVisible) {
        result.push({
            variant: 'text-outside',
            imgPath: removeColumnsMedia.image,
            title: removeColumnsMedia.name,
            price: priceRemove || 0,
            isLoadingPrice: priceRemove === null,
            onDetailsClick: () => showItemDetails(removeColumnsMedia),
            onClick: () => {
                requestAnimationFrame(() => {
                    const visitor = new StoreVisitor();

                    dispatch(setColumns(removedColumns.map((column) => visitor.visitColumnNode(column))));

                    dispatch(
                        fetchWardrobePrice({
                            currencyCode: currentCurrency,
                        }),
                    );
                });
            },
        });
    }

    return result;
}
