import React from 'react';
import { useSelector } from 'react-redux';
import { CardComponent } from '../../Card/components/CardComponent/CardComponent';
import {
    getNumberOfDoorsInCurrentWardrobe,
    getSelectedColumnIndex,
    getSelectedDoorHandle,
} from '../../../store/wardrobe/selectors';
import { getSelectedDoorProductForWardrobe } from '../../../store/products/selectors';
import { useMediaByType } from '../../../store/media/selectors';
import { useLanguage } from '../../Translations/LanguageProvider';

interface DoorHandlesItemProps {
    onClick(): void;
}

export const DoorHandlesItem = ({ onClick }: DoorHandlesItemProps) => {
    const selectedDoorProduct = useSelector(getSelectedDoorProductForWardrobe);
    const selectedColumnIndex = useSelector(getSelectedColumnIndex);
    const selectedDoorHandle = useSelector(getSelectedDoorHandle);
    const numbersOfDoors = useSelector(getNumberOfDoorsInCurrentWardrobe);
    const withoutHandleMedia = useMediaByType('without_handle');
    const { currentLanguage } = useLanguage();

    const isDoorHandleOptionVisible =
        (selectedDoorProduct !== null || numbersOfDoors > 0) && selectedColumnIndex === null;

    if (!isDoorHandleOptionVisible) {
        return null;
    }

    return (
        <CardComponent
            subtitle="Handles"
            key="Handles"
            title={
                selectedDoorHandle === null
                    ? withoutHandleMedia.name
                    : selectedDoorHandle?.name?.[currentLanguage] || ''
            }
            onClick={onClick}
            imgPath={selectedDoorHandle === null ? withoutHandleMedia.image : selectedDoorHandle?.images[0]?.path}
            dataTestId="door-handles"
        />
    );
};
