import React, { FC } from 'react';
import { CardMobileOption } from '../../../Card/components/CardMobileOption/CardMobileOption';
import { MobileMenuItemTooltip } from '../../../MobileMenuItemTooltip/MobileMenuItemTooltip';
import { MobileMenuContainer } from '../../../atoms/MobileMenuContainer/MobileMenuContainer';
import { MobileMenuEditComponentProps } from '../../constants';
import { useHandleOptions } from './useHandleOptions';

export const HandlesMobile: FC<MobileMenuEditComponentProps> = ({ onOpenDetails }) => {
    const options = useHandleOptions(onOpenDetails, false);
    const activeOption = options.find((option) => option.isSelected);

    return (
        <MobileMenuContainer>
            {activeOption && (
                <MobileMenuItemTooltip
                    label={activeOption.title}
                    onClick={activeOption.onDetailsClick}
                    bottomSpacing={180}
                ></MobileMenuItemTooltip>
            )}
            <div className="w-full flex justify-center">
                {options.map((option, index) => (
                    <CardMobileOption
                        key={index}
                        isSelected={option.isSelected}
                        imgPath={option.imgPath}
                        onClick={option.onClick}
                    />
                ))}
            </div>
        </MobileMenuContainer>
    );
};
