import React, { FC } from 'react';
import { CardMobileOption } from '../../../../Card/components/CardMobileOption/CardMobileOption';
import { MobileMenuItemTooltip } from '../../../../MobileMenuItemTooltip/MobileMenuItemTooltip';
import { MobileMenuContainer } from '../../../../atoms/MobileMenuContainer/MobileMenuContainer';
import { useDoorOptions } from '../useDoorOptions';
import { MobileMenuEditComponentProps } from '../../../constants';

export const DoorOptionsMobile: FC<MobileMenuEditComponentProps> = ({ onOpenDetails }) => {
    const options = useDoorOptions(onOpenDetails, false);
    const activeOption = options.find((option) => option.isSelected);

    return (
        <MobileMenuContainer>
            {activeOption && (
                <MobileMenuItemTooltip
                    label={activeOption.title}
                    onClick={activeOption.onDetailsClick}
                    bottomSpacing={180}
                ></MobileMenuItemTooltip>
            )}
            <div className="w-full flex justify-center">
                {options.map((option) => (
                    <CardMobileOption
                        key={option.title}
                        isSelected={option.isSelected}
                        imgPath={option.imgPath}
                        onClick={option.onClick}
                    />
                ))}
            </div>
        </MobileMenuContainer>
    );
};
