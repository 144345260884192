import styled from 'styled-components';
import Breakpoint from '../../../../constants/Breakpoint.enum';
import { Color } from '../../../../constants/Color.enum';

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    padding: 0 32px;
`;

export const Wrapper = styled.div`
    padding-bottom: 48px;

    @media (max-width: ${Breakpoint.Tablet}) {
        padding-bottom: 95px;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - var(--dynamic-offset));
    justify-content: space-between;
    overflow: auto;
    flex-direction: column;
    transition: height 0.5s;

    @media (max-width: ${Breakpoint.Tablet}) {
        background-color: ${Color.Surface};
        position: fixed;
        z-index: 100;
        height: 100%;
    }
`;

export const ItemMobileButtonWrapper = styled.div`
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
`;

export const ImageWithShimmerEffectWrapper = styled.div`
    @media (max-width: ${Breakpoint.Tablet}) {
        text-align: center;
    }
    background-color: ${Color.Background};
`;
