import React, { FC } from 'react';
import { CardOption } from '../../../Card/components/CardOption/CardOption';
import { SidePanelEditComponentProps } from '../../constants';
import { TwoColumnCardContainer } from '../../../Card/components/CardOption/CardOption.styled';
import { useShelvesOptions } from './useShelvesOptions';

type ShelvesProps = SidePanelEditComponentProps;

export const Shelves: FC<ShelvesProps> = ({ isOpen = true, onOpenDetails }) => {
    const options = useShelvesOptions(onOpenDetails, isOpen);

    return (
        <TwoColumnCardContainer data-test="two_column_card_container">
            {options.map((option, index) => (
                <CardOption key={index} {...option} />
            ))}
        </TwoColumnCardContainer>
    );
};
