import React from 'react';
import { useSelector } from 'react-redux';
import { CardComponent } from '../../Card/components/CardComponent/CardComponent';
import { getSelectedFrameMaterial } from '../../../store/wardrobe/selectors';
import { useLanguage } from '../../Translations/LanguageProvider';

interface FramesItemProps {
    onClick(): void;
}

export const FramesItem = ({ onClick }: FramesItemProps) => {
    const selectedFrameMaterial = useSelector(getSelectedFrameMaterial);
    const { currentLanguage } = useLanguage();

    return (
        <CardComponent
            subtitle="Frames"
            title={selectedFrameMaterial?.name?.[currentLanguage] || ''}
            onClick={onClick}
            imgPath={selectedFrameMaterial?.image.path}
            dataTestId="frames"
        />
    );
};
