import { useSelector } from 'react-redux';
import {
    getSelectedConfigurationVariant,
    getSelectedConfigurationId,
    getSelectedColumnIndex,
} from '../../../store/wardrobe/selectors';
import { CardComponent } from '../../Card/components/CardComponent/CardComponent';
import { useLayoutScreen } from '../components/Layout/components/LayoutItem/LayoutItem';

interface LayoutItemProps {
    onClick(): void;
}

export const LayoutItem = ({ onClick }: LayoutItemProps) => {
    const selectedConfigurationVariant = useSelector(getSelectedConfigurationVariant);
    const configId = useSelector(getSelectedConfigurationId);
    const selectedColumnIndex = useSelector(getSelectedColumnIndex);
    const path = useLayoutScreen(configId || '');

    if (selectedColumnIndex === null) {
        return null;
    }

    return (
        <CardComponent
            key="Layout"
            subtitle="Layout"
            title={selectedConfigurationVariant?.name || ''}
            onClick={onClick}
            imgPath={path}
            dataTestId="layout"
        />
    );
};
