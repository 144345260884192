import React, { FC } from 'react';
import { Button } from '@formify/frontend-components';
import { InfoTextIcon } from '../icons/InfoText/InfoText';
import { InfoButtonWrapper } from './InfoButton.styled';

export interface InfoButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const InfoButton: FC<InfoButtonProps> = ({ onClick }) => (
    <InfoButtonWrapper>
        <Button variant="secondary" onClick={onClick} icon={<InfoTextIcon />}></Button>
    </InfoButtonWrapper>
);
