import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Color } from '../../../../constants/Color.enum';
import { convertValueFromCentsToEuro } from '../../../../store/configurations/utils';
import { checkWardrobeTotalPriceIsFetching, getWardrobeTotalPrice } from '../../../../store/wardrobe/selectors';
import { LinkL } from '@formify/frontend-components';
import { SubtitleS, SubtitleXS } from '@formify/frontend-components';
import { Tr } from '../../../Translations/Tr';
import { useCurrency } from '../../../Currency/CurrencyProvider';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchWardrobePrice } from '../../../../store/configurations/actions';

interface WardrobePriceProps {
    isHidden?: boolean;
    small?: boolean;
}

const PriceTextColor = styled.span<{ isFetching: boolean }>`
    color: ${({ isFetching }) => (isFetching ? Color.Variant_1 : Color.Primary)};
    transition: color 0.2s linear;
`;

export const WardrobePrice = ({ small }: WardrobePriceProps) => {
    const wardrobeTotalPrice = useSelector(getWardrobeTotalPrice);
    const wardrobeTotalPriceIsFetching = useSelector(checkWardrobeTotalPriceIsFetching);
    const TextComponent = small ? SubtitleS : LinkL;
    const { currentCurrency, formatPrice } = useCurrency();
    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(
            fetchWardrobePrice({
                currencyCode: currentCurrency,
            }),
        );
    }, [currentCurrency, dispatch]);

    return (
        <div>
            {typeof wardrobeTotalPrice === 'number' ? (
                <PriceTextColor isFetching={wardrobeTotalPriceIsFetching}>
                    <TextComponent>{formatPrice(convertValueFromCentsToEuro(wardrobeTotalPrice))}</TextComponent>
                    {!small && (
                        <SubtitleXS>
                            <Tr labelKey="(incl. VAT)" defaultValue="(incl. VAT)" />
                        </SubtitleXS>
                    )}
                </PriceTextColor>
            ) : (
                <Skeleton inline width={90} />
            )}
        </div>
    );
};
