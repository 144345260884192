import { useCallback, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import {
    getDoorHandleProductsBaseOnDoor,
    getDoorProductForWardrobe,
    getProductByType,
} from '../../../../store/products/selectors';
import { CardOptionProps } from '../../../Card/components/CardOption/CardOption';
import { Product } from '../../../../types/Product';
import { useLockEffect } from '../../../../hooks/useLockEffect';
import { PriceVisitor } from '../../../../services/nodes/wardrobe/visitor/PriceVisitor';
import { WoodDoorHandleNode } from '../../../../services/nodes/wardrobe/nodes/WoodDoorHandleNode';
import { ChromeDoorHandleNode } from '../../../../services/nodes/wardrobe/nodes/ChromeDoorHandleNode';
import { assignDoorHandleType } from '../../../../store/wardrobe/actions';
import { getSelectedDoorHandle } from '../../../../store/wardrobe/selectors';
import { DoorHandleCode, isDoorHandleCode } from '../../../../services/products/domain/ProductCodes';
import { GoldDoorHandleNode } from '../../../../services/nodes/wardrobe/nodes/GoldDoorHandleNode';
import { fetchWardrobePrice } from '../../../../store/configurations/actions';
import { createEmptyWrapper } from '../../../../services/nodes/wardrobe/wardrobe';
import { RootState } from '../../../../store';
import { useMediaByType } from '../../../../store/media/selectors';
import { DetailsContentElement } from '../Details';
import { useLanguage } from '../../../Translations/LanguageProvider';
import { useCurrency } from '../../../Currency/CurrencyProvider';

const INITIAL_STATE: {
    [key in DoorHandleCode]: null | number;
} = {
    door_handle_wood: null,
    door_handle_chrome: null,
    door_handle_gold: null,
};

export const useHandleOptions = (onOpenDetails: (detailsValues: DetailsContentElement) => void, isOpen: boolean) => {
    const { dispatch, getState } = useStore<RootState, any>();
    const selectedDoorHandle = useSelector(getSelectedDoorHandle);
    const selectedDoorProduct = useSelector(getDoorProductForWardrobe);
    const doorHandleProducts = useSelector(getDoorHandleProductsBaseOnDoor(selectedDoorProduct));
    const doorHandleWood = useSelector(getProductByType('door_handle_wood'));
    const doorHandleChrome = useSelector(getProductByType('door_handle_chrome'));
    const doorHandleGold = useSelector(getProductByType('door_handle_gold'));
    const priceService = useSelector((state: RootState) => state.dependency.services?.price);
    const { currentCurrency } = useCurrency();

    const [prices, setPrices] = useState<{
        [key in DoorHandleCode]: null | number;
    }>(INITIAL_STATE);

    const fetchPrices = useCallback<
        () => Promise<{
            [key in DoorHandleCode]: null | number;
        }>
    >(() => {
        if (!priceService) {
            return Promise.reject(new Error('priceService not exist'));
        }

        if (isOpen && doorHandleWood && doorHandleChrome && doorHandleGold) {
            const woodDoorHandleNode = new WoodDoorHandleNode({ product: doorHandleWood });
            const chromeDoorHandleNode = new ChromeDoorHandleNode({ product: doorHandleChrome });
            const goldDoorHandleNode = new GoldDoorHandleNode({ product: doorHandleGold });
            const state = getState();
            const wardrobeWithWoodDoorHandleNode = createEmptyWrapper(state);
            const wardrobeWithChromeDoorNode = createEmptyWrapper(state);
            const wardrobeWithGoldDoorNode = createEmptyWrapper(state);

            if (wardrobeWithWoodDoorHandleNode && wardrobeWithChromeDoorNode && wardrobeWithGoldDoorNode) {
                wardrobeWithWoodDoorHandleNode.addChild(woodDoorHandleNode);
                wardrobeWithChromeDoorNode.addChild(chromeDoorHandleNode);
                wardrobeWithGoldDoorNode.addChild(goldDoorHandleNode);

                const priceVisitor = new PriceVisitor();
                const wood = priceVisitor.visitWrapperNode(wardrobeWithWoodDoorHandleNode);
                const chrome = priceVisitor.visitWrapperNode(wardrobeWithChromeDoorNode);
                const gold = priceVisitor.visitWrapperNode(wardrobeWithGoldDoorNode);

                return Promise.all([
                    priceService.fetchPrice(wood, currentCurrency),
                    priceService.fetchPrice(chrome, currentCurrency),
                    priceService.fetchPrice(gold, currentCurrency),
                ]).then(([woodData, chromeData, goldData]) => ({
                    door_handle_wood: woodData.unitPriceWithChildItems,
                    door_handle_chrome: chromeData.unitPriceWithChildItems,
                    door_handle_gold: goldData.unitPriceWithChildItems,
                }));
            }
        }

        return Promise.resolve({
            door_handle_wood: null,
            door_handle_chrome: null,
            door_handle_gold: null,
        });
    }, [isOpen, doorHandleWood, doorHandleChrome, doorHandleGold, getState, priceService, currentCurrency]);

    useLockEffect(fetchPrices, setPrices);

    const showItemDetails = useCallback(
        (product: Product) => {
            onOpenDetails({
                type: 'product',
                props: {
                    product,
                },
            });
        },
        [onOpenDetails],
    );

    const withoutHandleMedia = useMediaByType('without_handle');
    const { currentLanguage } = useLanguage();

    const result = doorHandleProducts.map<CardOptionProps>((doorHandle) => ({
        isLoadingPrice: prices[doorHandle.code as DoorHandleCode] === null,
        isSelected: selectedDoorHandle?.code === doorHandle.code,
        variant: 'text-outside',
        imgPath: doorHandle.images[0]?.path,
        title: doorHandle.name[currentLanguage] || '',
        dataTestId: doorHandle.code,
        price: prices[doorHandle.code as DoorHandleCode] || 0,
        onDetailsClick: () => showItemDetails(doorHandle),
        onClick: () => {
            if (isDoorHandleCode(doorHandle.code)) {
                dispatch(assignDoorHandleType(doorHandle.code));

                dispatch(
                    fetchWardrobePrice({
                        currencyCode: currentCurrency,
                    }),
                );
            }
        },
    }));

    result.push({
        dataTestId: 'without_handles',
        isLoadingPrice: false,
        isSelected: selectedDoorHandle === null,
        variant: 'text-outside',
        imgPath: withoutHandleMedia.image,
        title: withoutHandleMedia.name,
        price: 0,
        onDetailsClick: () => {
            onOpenDetails({
                type: 'media',
                props: {
                    details: withoutHandleMedia,
                },
            });
        },
        onClick: () => {
            dispatch(assignDoorHandleType(null));

            dispatch(
                fetchWardrobePrice({
                    currencyCode: currentCurrency,
                }),
            );
        },
    });

    return result;
};
