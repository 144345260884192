import React, { FC } from 'react';
import { CardOption } from '../../../Card/components/CardOption/CardOption';
import { SidePanelEditComponentProps } from '../../constants';
import { TwoColumnCardContainer } from '../../../Card/components/CardOption/CardOption.styled';
import { useLedOptions } from './useLedOptions';

type LedOptionsProps = SidePanelEditComponentProps;

export const LedOptions: FC<LedOptionsProps> = ({ isOpen = true, onOpenDetails }) => {
    const options = useLedOptions(onOpenDetails, isOpen);

    return (
        <TwoColumnCardContainer data-test="two_column_card_container">
            {options.map((option) => (
                <CardOption key={option.title} {...option} />
            ))}
        </TwoColumnCardContainer>
    );
};
