import styled from 'styled-components';
import { Color } from '../../../../constants/Color.enum';
import { CardVariant } from './CardOption';
import { InfoButtonWrapper } from '../../../InfoButton/InfoButton.styled';

interface CardOptionProps {
    disabled?: boolean;
    variant?: CardVariant;
    isSelected?: boolean;
}

interface CardImageProps {
    isImageEmpty?: boolean;
    imgHeightPercent?: number;
}

interface ImageCroppedStyledProps {
    isSelected?: boolean;
    background?: string;
}

const BaseCardOptionContainer = styled.div<CardOptionProps>`
    opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
`;

export const TwoColumnCardContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 16px;
`;

export const CardOptionInitialContainer = styled(BaseCardOptionContainer)`
    border-radius: 16px;
    width: 100%;
`;

export const CardOptionImageContainer = styled(BaseCardOptionContainer)`
    width: 100%;
    position: relative;
    padding-bottom: 54px;
`;

export const BaseCardOptionWrapper = styled.div<CardImageProps>`
    display: flex;
    flex-direction: column;
    background-color: ${({ isImageEmpty }) => (isImageEmpty ? Color.Background : Color.Surface)};
    position: relative;
    height: 0;
    padding-bottom: ${({ imgHeightPercent = 100 }) => `${imgHeightPercent}%`};
    width: 100%;
    cursor: pointer;
    border-radius: 16px;

    &:hover ${InfoButtonWrapper} {
        visibility: initial;
    }
`;

export const CardOptionInitialWrapper = styled(BaseCardOptionWrapper)`
    justify-content: space-between;
    padding: 20px 30px;
`;

export const ImageWrapperStyled = styled.div<CardOptionProps>`
    display: flex;
    border-radius: 16px;
    border: 2px solid transparent;
    border-color: ${({ isSelected }) => isSelected && Color.Primary};
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;

export const TextInsideWrapper = styled.div`
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: 'center';
    align-items: center;
`;

export const TextOutsideWrapper = styled.div`
    padding: 8px 0 24px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
`;

export const ImageCroppedStyled = styled.div<ImageCroppedStyledProps>`
    display: flex;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    clip-path: ${({ isSelected }) => (isSelected ? 'inset(8px 8px 8px 8px round 8px)' : 'inset(0px)')};
    background-color: ${({ background }) => background || Color.Background};
`;

export const PriceWrapperStyled = styled.div`
    height: 20px;
`;
