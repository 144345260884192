import React, { FC } from 'react';
import { CardOption } from '../../../Card/components/CardOption/CardOption';
import { SidePanelEditComponentProps } from '../../constants';
import { TwoColumnCardContainer } from '../../../Card/components/CardOption/CardOption.styled';
import { useColorOptions } from './useColorOptions';

type FramesProps = SidePanelEditComponentProps;

export const Frames: FC<FramesProps> = ({ isOpen = true, onOpenDetails }) => {
    const options = useColorOptions(onOpenDetails, isOpen);

    return (
        <TwoColumnCardContainer data-test="two_column_card_container">
            {options.map((option) => (
                <div key={option.title} onMouseEnter={option.prepareTexture}>
                    <CardOption {...option} />
                </div>
            ))}
        </TwoColumnCardContainer>
    );
};
