import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 74px;
    width: 100%;
    padding: 0 25px;
`;

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
`;
