import React from 'react';
import { useSelector } from 'react-redux';
import { CardComponent } from '../../Card/components/CardComponent/CardComponent';
import { getWardrobeType } from '../../../store/wardrobe/selectors';
import { getProductByType } from '../../../store/products/selectors';
import { useLanguage } from '../../Translations/LanguageProvider';

interface FrameItemProps {
    onClick(): void;
}

export const FrameItem = ({ onClick }: FrameItemProps) => {
    const wardrobeType = useSelector(getWardrobeType);
    const selectedWardrobe = useSelector(getProductByType(wardrobeType));
    const { currentLanguage } = useLanguage();

    return (
        <CardComponent
            key="WardrobeType"
            subtitle="Type"
            title={selectedWardrobe?.name[currentLanguage] || ''}
            imgPath={selectedWardrobe?.images?.[0]?.path}
            onClick={onClick}
            dataTestId="WardrobeType"
        />
    );
};
