import { useCallback, useEffect, useRef } from 'react';
import {
    StepItem,
    StepItemDot,
    StepItemLabel,
    StepsContainer,
    StepsScrollable,
    StepsScrollableShadow,
} from './Steps.styled';

const MIN_DETECTABLE_SCROLL = 10;

export type StepsItemState = 'visited' | 'active' | 'disabled' | 'active-visited' | 'blocked';

export interface StepsItem {
    name: string;
    label: string;
    code?: string;
    state: StepsItemState;
    onClick: () => void;
}

interface StepsProps {
    items: StepsItem[];
}

export const Steps = ({ items }: StepsProps) => {
    const activeIndex = items.findIndex((item) => item.state === 'active' || item.state === 'active-visited');
    const scrollElementRef = useRef<HTMLDivElement | null>(null);
    const selectedElementRef = useRef<HTMLDivElement | null>(null);
    const shadowElementRef = useRef<HTMLDivElement | null>(null);

    const refreshScrollPosition = useCallback(() => {
        const scrollElement = scrollElementRef.current;
        const selectedElement = selectedElementRef.current;

        if (scrollElement && selectedElement) {
            scrollElement.scrollTo({
                left: selectedElement.offsetLeft + selectedElement.offsetWidth / 2 - scrollElement.offsetWidth / 2,
                behavior: 'smooth',
            });
        }
    }, []);

    useEffect(() => {
        refreshScrollPosition();
    }, [refreshScrollPosition, activeIndex]);

    useEffect(() => {
        const element = scrollElementRef.current;

        if (element) {
            const handler = () => {
                const showLeftCorner = Math.min(40, element.scrollLeft);

                const showRightCorner = Math.min(
                    40,
                    Math.abs(element.scrollLeft + element.clientWidth - element.scrollWidth),
                );

                const shadowElement = shadowElementRef.current;

                if (shadowElement) {
                    shadowElement.style.setProperty('--left-opacity', (showLeftCorner / 40).toString());
                    shadowElement.style.setProperty('--right-opacity', (showRightCorner / 40).toString());
                }
            };

            handler();

            element.addEventListener('scroll', handler, {
                passive: true,
            });

            return () => element.removeEventListener('scroll', handler);
        }
    });

    const activeContent = useRef(false);
    const moveContent = useRef(false);
    const lastPosition = useRef<null | [number, number]>(null);

    return (
        <StepsScrollableShadow ref={shadowElementRef}>
            <StepsScrollable ref={scrollElementRef}>
                <StepsContainer
                    onMouseLeave={(e) => {
                        e.currentTarget.style.setProperty('cursor', 'initial');
                        requestAnimationFrame(() => {
                            activeContent.current = false;
                            moveContent.current = false;
                        });
                    }}
                    onMouseDown={(e) => {
                        activeContent.current = true;
                        lastPosition.current = [e.clientX, e.clientY];
                    }}
                    onMouseMove={(e) => {
                        if (activeContent.current) {
                            if (lastPosition.current !== null && !moveContent.current) {
                                const distance = Math.hypot(
                                    lastPosition.current[0] - e.clientX,
                                    lastPosition.current[1] - e.clientY,
                                );

                                if (distance < MIN_DETECTABLE_SCROLL) {
                                    return;
                                }
                            }

                            e.currentTarget.style.setProperty('cursor', 'grabbing');
                            moveContent.current = true;
                            const lastPos = lastPosition.current;

                            if (lastPos !== null) {
                                const scrollElement = scrollElementRef.current;

                                if (scrollElement) {
                                    scrollElement.scrollTo({
                                        left: scrollElement.scrollLeft + lastPos[0] - e.clientX,
                                        behavior: 'auto',
                                    });
                                }
                            }

                            lastPosition.current = [e.clientX, e.clientY];
                        }
                    }}
                    onMouseUp={(e) => {
                        e.currentTarget.style.setProperty('cursor', 'initial');
                        requestAnimationFrame(() => {
                            activeContent.current = false;
                            moveContent.current = false;
                        });
                    }}
                >
                    {items.map(({ name, label, state, onClick }, index) => (
                        <StepItem
                            key={index}
                            state={state}
                            ref={(element: HTMLDivElement | null) => {
                                if ((state === 'active' || state === 'active-visited') && element) {
                                    selectedElementRef.current = element;
                                    refreshScrollPosition();
                                }
                            }}
                            onClick={() => {
                                if (!moveContent.current) {
                                    onClick();
                                }
                            }}
                        >
                            <StepItemDot data-test-id={name} data-test-value={state} state={state} />
                            <StepItemLabel state={state} type="horizontal">
                                {label}
                            </StepItemLabel>
                        </StepItem>
                    ))}
                </StepsContainer>
            </StepsScrollable>
        </StepsScrollableShadow>
    );
};
