import { useActor } from '@xstate/react';
import React, { useContext, useMemo } from 'react';
import { Steps, StepsItem, StepsItemState } from '../atoms/Steps/Steps';
import { ALL_OPTIONS } from './constants';
import { useSelector } from 'react-redux';
import { getNumberOfVisibleColumns } from '../../store/wardrobe/selectors';
import { selectAllVisibleOptions } from '../../hooks/useVisibleOptions';
import { configMachineServiceContext } from '../ConfiguratorStateMachineProvider/ConfiguratorStateMachineProvider';
import { useLanguage } from '../Translations/LanguageProvider';

export const STEPS_SYSTEM_ORDER = [
    'wardrobeType',
    'measurements',
    'measurementsA',
    'measurementsB',
    'measurementsC',
    'frames',
    'doors',
    'handles',
    'led',
    'shelves',
    'columnSetting',
    'summary',
] as const;

export const SidePanelSteps: React.FC<{}> = () => {
    const { configMachineService } = useContext(configMachineServiceContext);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [current, send] = useActor(configMachineService!);
    const numberOfVisibleColumns = useSelector(getNumberOfVisibleColumns);
    const allVisibleOptions = useSelector(selectAllVisibleOptions);
    const { t } = useLanguage();

    const stepsMaped = useMemo(
        () => [
            ...STEPS_SYSTEM_ORDER.map((stepName): StepsItem | StepsItem[] => {
                let state: StepsItemState = 'disabled';
                const visited = current.context.visited.includes(stepName);

                if (visited) {
                    state = 'visited';
                }
                const isActive = current.value === stepName;

                if (isActive) {
                    state = visited ? 'active-visited' : 'active';
                }
                const { label } = ALL_OPTIONS[stepName];
                const isBlocked = stepName === 'summary' ? false : !allVisibleOptions[stepName];

                if (isBlocked) {
                    state = 'blocked';
                }

                if (stepName === 'columnSetting') {
                    return new Array(numberOfVisibleColumns).fill(null).map((_, index) => {
                        const columnSettingName = `${stepName}_${index}`;

                        const visitedColumn: boolean =
                            visited && current.context.visitedColumn !== null && current.context.visitedColumn >= index;

                        const isActive = current.value === stepName && current.context.selectedColumn === index;
                        let state: StepsItemState = 'disabled';

                        if (visitedColumn) {
                            state = 'visited';
                        }

                        if (isActive) {
                            state = visitedColumn ? 'active-visited' : 'active';
                        }

                        // if (isBlocked) {
                        //     state = 'blocked';
                        // }

                        return {
                            name: columnSettingName,
                            code: label,
                            label: `${t(label)} ${index + 1}`,
                            onClick: () => {
                                if (!isActive) {
                                    send({
                                        type: 'GOTO',
                                        payload: {
                                            name: stepName,
                                            columnIndex: index,
                                        },
                                    });
                                }
                            },
                            state,
                        };
                    });
                }

                return {
                    name: stepName,
                    code: label,
                    label: t(label),
                    onClick: () => {
                        if (!isBlocked && !isActive) {
                            send({
                                type: 'GOTO',
                                payload: {
                                    name: stepName,
                                },
                            });
                        }
                    },
                    state,
                };
            })
                .flatMap((ele) => ele)
                .filter(
                    (ele) =>
                        !(
                            ele.state === 'blocked' &&
                            (ele.code === 'Wall A' ||
                                ele.code === 'Wall B' ||
                                ele.code === 'Wall C' ||
                                ele.code === 'Measurements')
                        ),
                ),
        ],
        [
            allVisibleOptions,
            current.context.selectedColumn,
            current.context.visited,
            current.context.visitedColumn,
            current.value,
            numberOfVisibleColumns,
            send,
            t,
        ],
    );

    return (
        <div
            style={{
                margin: '0 -32px 24px -32px',
                position: 'sticky',
                top: '0',
                zIndex: 99,
            }}
        >
            <Steps items={stepsMaped}></Steps>
        </div>
    );
};
