import React, { FC, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    getAdditionalFrameSize,
    getCurrentWardrobeMeasurementsWall,
    getPreparedSelectedColumn,
} from '../../../../../../store/wardrobe/selectors';
import { Container, Wrapper } from './MeasurementsMobile.styled';
import { getWardrobeDimensions } from '../../../../../../store/products/selectors';
import { Slider } from '../../../../../atoms/Slider/Slider';
import { useDimensions } from '../../../../../../hooks/useDimensions';
import { MobileMenuItemTooltip } from '../../../../../MobileMenuItemTooltip/MobileMenuItemTooltip';
import { useMediaByType } from '../../../../../../store/media/selectors';
import { SimplifyMedia } from '../../../../../../store/media/reducer';
import { ItemDetails } from '../../../ItemDetails/ItemDetails';
import { BIG_DEPTH, SMALL_DEPTH } from '../../utils';
import { convertValueFromCmToMm, convertValueFromMmToCm } from '../../../../../../store/products/utils';
import { MultipleToggle } from '../../../../../atoms/Toggle/MultipleToggle';
import { UnitStyled, ValueStyled } from '@formify/frontend-components/dist/components/Toggle/Toggle.styled';
import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { useLanguage } from '../../../../../Translations/LanguageProvider';

interface MeasurementsMobileWidthProps {
    wall: WardrobeWall;
}

export const MeasurementsMobileWidth: FC<MeasurementsMobileWidthProps> = ({ wall }) => {
    const currentWardrobeMeasurements = useSelector(getCurrentWardrobeMeasurementsWall(wall));
    const { singleClosetWidth, doubleClosetWidth, wardrobeWidth } = useSelector(getWardrobeDimensions);
    const { onChange } = useDimensions(wall);
    const currentColumn = useSelector(getPreparedSelectedColumn);
    const measuringElement = currentColumn || currentWardrobeMeasurements;
    const { additionalWidth } = useSelector(getAdditionalFrameSize);
    const additionalWidthNumber = currentColumn ? 0 : additionalWidth;
    const { t } = useLanguage();

    if (singleClosetWidth === null || doubleClosetWidth === null || wardrobeWidth === null) {
        return null;
    }

    return (
        <Container bottomSpacing={105}>
            <Wrapper>
                <MobileMenuItemTooltip
                    label={t('measurement_info_width', 'Width: ${value}cm', {
                        value: convertValueFromMmToCm(
                            currentColumn
                                ? measuringElement['width']
                                : measuringElement['width'] + additionalWidthNumber,
                        ),
                    })}
                    bottomSpacing={140}
                ></MobileMenuItemTooltip>

                <Slider
                    minValue={(currentColumn ? singleClosetWidth.min : wardrobeWidth.min) + additionalWidthNumber}
                    maxValue={currentColumn ? doubleClosetWidth.max : wardrobeWidth.max}
                    handleChange={(value) =>
                        onChange({ type: 'width', value: currentColumn ? value : value - additionalWidthNumber })
                    }
                    unit="cm"
                    value={measuringElement.width + additionalWidthNumber}
                    step={10}
                    valueModify={convertValueFromMmToCm}
                />
            </Wrapper>
        </Container>
    );
};

interface MeasurementsMobileDepthProps {
    wall: WardrobeWall;
}

export const MeasurementsMobileDepthGenerator = (wall: WardrobeWall) =>
    function MeasurementsMobileDepthGenerated(props: Omit<MeasurementsMobileDepthProps, 'wall'>) {
        return <MeasurementsMobileDepth {...props} wall={wall} />;
    };

const MeasurementsMobileDepth: FC<MeasurementsMobileDepthProps> = ({ wall }) => {
    const currentWardrobeMeasurements = useSelector(getCurrentWardrobeMeasurementsWall(wall));
    const { wardrobeDepth } = useSelector(getWardrobeDimensions);
    const { onChange } = useDimensions(wall);
    const currentColumn = useSelector(getPreparedSelectedColumn);
    const measuringElement = currentColumn || currentWardrobeMeasurements;
    const depthMediaMin = useMediaByType('43cm_depth');
    const depthMediaMax = useMediaByType('58cm_depth');
    const [details, setDetails] = useState<SimplifyMedia | null>(null);
    const { t } = useLanguage();

    const [showCustomDepth, setShowCustomDepth] = useState(
        !(wardrobeDepth && (measuringElement.depth === SMALL_DEPTH || measuringElement.depth === BIG_DEPTH)),
    );

    const depthMedia = SMALL_DEPTH >= measuringElement.depth ? depthMediaMin : depthMediaMax;

    const onDepthOptionClick = useCallback(() => {
        setDetails(depthMedia);
    }, [depthMedia]);

    if (wardrobeDepth === null) {
        return null;
    }

    return (
        <>
            <Container bottomSpacing={88}>
                <Wrapper>
                    {!showCustomDepth && (
                        <MobileMenuItemTooltip
                            label={depthMedia.name}
                            onClick={onDepthOptionClick}
                            bottomSpacing={140}
                        ></MobileMenuItemTooltip>
                    )}

                    <div className="w-full flex justify-center">
                        <MultipleToggle<number | 'custom'>
                            dataTestId="toggle-depth"
                            values={[convertValueFromMmToCm(SMALL_DEPTH), convertValueFromMmToCm(BIG_DEPTH), 'custom']}
                            render={(value) => (
                                <>
                                    <ValueStyled>{value === 'custom' ? t('Custom') : value}</ValueStyled>

                                    {value !== 'custom' && <UnitStyled className="ml-2">cm</UnitStyled>}
                                </>
                            )}
                            selectedValue={showCustomDepth ? 'custom' : convertValueFromMmToCm(measuringElement.depth)}
                            small
                            onSelect={(value) => {
                                if (value !== 'custom') {
                                    onChange({
                                        type: 'depth',
                                        value: convertValueFromCmToMm(value),
                                    });
                                }
                                setShowCustomDepth(value === 'custom');
                            }}
                        />
                    </div>

                    {showCustomDepth && (
                        <div
                            style={{
                                background: '#F5F3F0',
                                padding: '30px 20px',
                                margin: '20px -25px -28px -25px',
                            }}
                        >
                            <Slider
                                label={t('Custom')}
                                minValue={wardrobeDepth.min}
                                maxValue={wardrobeDepth.max}
                                handleChange={(value) => {
                                    onChange({
                                        type: 'depth',
                                        value: value,
                                    });
                                }}
                                unit="cm"
                                value={measuringElement.depth}
                                step={10}
                                valueModify={convertValueFromMmToCm}
                            />
                        </div>
                    )}
                </Wrapper>
            </Container>
            {details && (
                <ItemDetails
                    name={details.name}
                    description={details.description}
                    // image={details.image}
                    onClose={() => {
                        setDetails(null);
                    }}
                />
            )}
        </>
    );
};
