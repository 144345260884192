import React from 'react';
import { useSelector } from 'react-redux';
import { CardComponent } from '../../Card/components/CardComponent/CardComponent';
import { getMeasurementsDescription, getWallAtPosition } from '../../../store/wardrobe/selectors';
import { useLanguage } from '../../Translations/LanguageProvider';

interface MeasurementsItemProps {
    onClick(): void;
    wallIndex: number;
    subtitle?: string;
}

export const MeasurementsItem = ({ onClick, wallIndex, subtitle = 'Measurements' }: MeasurementsItemProps) => {
    const { t } = useLanguage();
    const wall = useSelector(getWallAtPosition(wallIndex)) || 'A';
    const measurementDescription = useSelector(getMeasurementsDescription(wall, t));

    return (
        <CardComponent
            key="Measurements"
            subtitle={subtitle}
            textOnly
            title={measurementDescription}
            onClick={onClick}
            dataTestId="measurements"
        />
    );
};
