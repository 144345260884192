import { useActor } from '@xstate/react';
import React, { useCallback, useContext } from 'react';
import { useStore } from 'react-redux';
import { Button } from '@formify/frontend-components';
import { selectAllVisibleOptions } from '../../../hooks/useVisibleOptions';
import { pushEvent } from '../../../services/analytics/analytics';
import { RootState } from '../../../store';
import { getSelectedDoorProductForWardrobe } from '../../../store/products/selectors';
import { convertValueFromMmToCm } from '../../../store/products/utils';
import {
    getCurrentWardrobeMeasurementsWall,
    getNumberOfVisibleColumns,
    getSelectedConfigurationVariant,
    getSelectedDoorHandle,
    getSelectedMaterialValue,
    getSelectedShelveForWardrobe,
    getWardrobeLed,
    getWardrobeType,
} from '../../../store/wardrobe/selectors';
import { WardrobePrice } from '../../SidePanel/components/WardrobePrice/WardrobePrice';
import { configMachineServiceContext } from '../../ConfiguratorStateMachineProvider/ConfiguratorStateMachineProvider';
import { __internal__sendEventToSDK } from 'formify-sdk';
import { Tr } from '../../Translations/Tr';

export const StickyFooterWithPrice: React.FC<{}> = () => {
    const { configMachineService } = useContext(configMachineServiceContext);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [current, send] = useActor(configMachineService!);
    const { getState } = useStore<RootState>();

    const onClickContinue = useCallback(() => {
        const state = getState();

        if (current.value === 'wardrobeType') {
            const code = getWardrobeType(state);

            pushEvent('configurator_selected', {
                configurator_step: 'wardrobeType',
                configurator_selection: code,
            });
            __internal__sendEventToSDK('userAction', {
                name: 'selectWardrobeType',
                values: [
                    {
                        name: 'type',
                        value: code,
                    },
                ],
            });
        } else if (current.value === 'measurements') {
            const { depth, height, width } = getCurrentWardrobeMeasurementsWall('A')(state);

            pushEvent('configurator_selected', {
                configurator_step: 'measurements',
                configurator_selection: `${convertValueFromMmToCm(width)}x${convertValueFromMmToCm(
                    height,
                )}x${convertValueFromMmToCm(depth)}cm`,
            });

            __internal__sendEventToSDK('userAction', {
                name: 'selectWardrobeMeasurements',
                values: [
                    {
                        name: 'width',
                        value: width.toString(),
                    },
                    {
                        name: 'height',
                        value: height.toString(),
                    },
                    {
                        name: 'depth',
                        value: depth.toString(),
                    },
                ],
            });
        } else if (current.value === 'frames') {
            const frame = getSelectedMaterialValue(state);

            if (frame) {
                pushEvent('configurator_selected', {
                    configurator_step: 'frames',
                    configurator_selection: frame,
                });
            }
        } else if (current.value === 'doors') {
            const door = getSelectedDoorProductForWardrobe(state);

            pushEvent('configurator_selected', {
                configurator_step: 'doors',
                configurator_selection: door?.code || 'without_door',
            });
        } else if (current.value === 'led') {
            const led = getWardrobeLed(state);

            pushEvent('configurator_selected', {
                configurator_step: 'led',
                configurator_selection: led ? 'with_led' : 'without_led',
            });
        } else if (current.value === 'handles') {
            const selectedDoorHandle = getSelectedDoorHandle(state);

            pushEvent('configurator_selected', {
                configurator_step: 'handles',
                configurator_selection: selectedDoorHandle?.code || 'without_handles',
            });
        } else if (current.value === 'shelves') {
            const shelves = getSelectedShelveForWardrobe(state);

            if (shelves) {
                pushEvent('configurator_selected', {
                    configurator_step: 'shelves',
                    configurator_selection: shelves.code,
                });
            }
        } else if (current.value === 'columnSetting') {
            const layout = getSelectedConfigurationVariant(state);

            if (layout) {
                pushEvent('configurator_selected', {
                    configurator_step: `columnSetting${current.context.selectedColumn}`,
                    configurator_selection: layout.name,
                });
            }
        }

        send({
            type: 'NEXT',
            payload: {
                numberOfColumn: getNumberOfVisibleColumns(state),
                visibility: selectAllVisibleOptions(state),
            },
        });
    }, [getState, current.value, send]);

    return (
        <>
            <WardrobePrice isHidden={false} />
            <Button
                variant="primary"
                onClick={onClickContinue}
                className="ml-auto"
                dataTestId="footer_sidebar_continue_button"
            >
                <Tr labelKey="continue_button" defaultValue="Continue" />
            </Button>
        </>
    );
};
