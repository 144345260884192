import React, { FC } from 'react';
import { LinkS } from '@formify/frontend-components';
import { TextS } from '@formify/frontend-components';
import { SIDEBAR_DESKTOP_WIDTH } from '../../../Sidebar/Sidebar.styled';
import { ImageWithShimmerEffectWrapper, TextWrapper } from './ItemDetails.styled';
import { ImageWithShimmerEffect } from '../../../ImageWithShimmerEffect/ImageWithShimmerEffect';
import { SliderDetails } from './SliderDetails';
import { HTMLContentStyled } from '../../../StaticPageContent/StaticPageContent.styled';

interface ItemDetailsProps {
    onClose: () => void;
    name: string;
    description: string;
    image?: string;
}

export const ItemDetails: FC<ItemDetailsProps> = ({ onClose, name, description, image }) => (
    <SliderDetails onClose={onClose}>
        {image && (
            <ImageWithShimmerEffectWrapper>
                <ImageWithShimmerEffect
                    layout="responsive"
                    alt={name}
                    src={image}
                    width={parseInt(SIDEBAR_DESKTOP_WIDTH)}
                    height={480}
                />
            </ImageWithShimmerEffectWrapper>
        )}
        <TextWrapper>
            <LinkS className="text-left pt-16">{name}</LinkS>
            <TextS>
                <HTMLContentStyled
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                    gap={16}
                ></HTMLContentStyled>
            </TextS>
        </TextWrapper>
    </SliderDetails>
);
