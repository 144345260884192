import React, { FC } from 'react';

interface InfoIconProps {
    size?: string;
}

export const InfoIcon: FC<InfoIconProps> = ({ size = '16' }) => (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6 8C14.6 11.6451 11.6451 14.6 8 14.6C4.35492 14.6 1.4 11.6451 1.4 8C1.4 4.35492 4.35492 1.4 8 1.4C11.6451 1.4 14.6 4.35492 14.6 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM7.2 5.09915C7.2 4.65778 7.55779 4.29999 7.99916 4.29999C8.44052 4.29999 8.79832 4.65778 8.79832 5.09915C8.79832 5.54051 8.44052 5.89831 7.99916 5.89831C7.55779 5.89831 7.2 5.54051 7.2 5.09915ZM7.3 7.25916C7.3 6.87302 7.61303 6.56 7.99917 6.56C8.3853 6.56 8.69833 6.87302 8.69833 7.25916V10.7525C8.69833 11.1432 8.37817 11.458 7.98754 11.4515C7.60599 11.4452 7.3 11.1341 7.3 10.7525V7.25916Z"
            fill="currentColor"
        />
    </svg>
);
