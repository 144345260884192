import { ProductWoodenMaterialOption } from '../../../products/domain/ThreeDParameter';
import { ColumnNode } from '../nodes/ColumnNode';
import { DoorNode } from '../nodes/DoorNode';
import { DoubleDrawerNode } from '../nodes/DoubleDrawerNode';
import { DrawerNode } from '../nodes/DrawerNode';
import { GlassShelveNode } from '../nodes/GlassShelveNode';
import { HangerFrontNode } from '../nodes/HangerFrontNode';
import { HangerNode } from '../nodes/HangerNode';
import { ShelveNode } from '../nodes/ShelveNode';
import { SpaceNode } from '../nodes/SpaceNode';
import { WardrobeNode } from '../nodes/WardrobeNode';
import { Node } from '../../common/nodes/Node';
import { WardrobeTreeVisitor } from './domain';
import { WoodDoorHandleNode } from '../nodes/WoodDoorHandleNode';
import { ChromeDoorHandleNode } from '../nodes/ChromeDoorHandleNode';
import { GoldDoorHandleNode } from '../nodes/GoldDoorHandleNode';
import { LedNode } from '../nodes/LedNode';
import { WrapperNode } from '../nodes/WrapperNode';
import { WardrobeWallNode } from '../nodes/WardrobeWallNode';
import { HangerFrontWithShelveNode } from '../nodes/HangerFrontWithShelveNode';

export class UpdateMaterialVisitor implements WardrobeTreeVisitor<void> {
    material: ProductWoodenMaterialOption;

    constructor(material: ProductWoodenMaterialOption) {
        this.material = material;
    }

    visitShelveNode(node: ShelveNode): void {
        node.setParam('material', this.material);
        this.visitChildrenNodes(node);
    }

    visitWardrobeNode(node: WardrobeNode): void {
        node.setParam('material', this.material);
        this.visitChildrenNodes(node);
    }

    visitWrapperNode(node: WrapperNode): void {
        this.visitChildrenNodes(node);
    }

    visitHangerFrontNode(node: HangerFrontNode): void {
        this.visitChildrenNodes(node);
    }

    visitSpaceNode(node: SpaceNode): void {
        this.visitChildrenNodes(node);
    }

    visitHangerNode(node: HangerNode): void {
        this.visitChildrenNodes(node);
    }

    visitGlassShelveNode(node: GlassShelveNode): void {
        this.visitChildrenNodes(node);
    }

    visitDrawerNode(node: DrawerNode): void {
        this.visitChildrenNodes(node);
    }

    visitDoubleDrawerNode(node: DoubleDrawerNode): void {
        node.setParam('material', this.material);
        this.visitChildrenNodes(node);
    }

    visitColumnNode(node: ColumnNode): void {
        node.setParam('material', this.material);
        this.visitChildrenNodes(node);
    }

    visitDoorNode(node: DoorNode): void {
        node.setParam('material', this.material);
        this.visitChildrenNodes(node);
    }

    visitWoodDoorHandleNode(node: WoodDoorHandleNode): void {
        this.visitChildrenNodes(node);
    }

    visitChromeDoorHandleNode(node: ChromeDoorHandleNode): void {
        this.visitChildrenNodes(node);
    }

    visitGoldDoorHandleNode(node: GoldDoorHandleNode): void {
        this.visitChildrenNodes(node);
    }

    visitLedNode(node: LedNode): void {
        this.visitChildrenNodes(node);
    }

    visitWardrobeWallNode(node: WardrobeWallNode): void {
        this.visitChildrenNodes(node);
    }

    visitHangerFrontWithShelve(node: HangerFrontWithShelveNode): void {
        this.visitChildrenNodes(node);
    }

    private visitChildrenNodes<T extends Node<any>>(node: T): void {
        node.getChildren().forEach((node) => {
            node.visit(this);
        });
    }
}
