import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useDimensions, DimensionsParams } from '../../../../../../hooks/useDimensions';
import { convertValueFromMmToCm } from '../../../../../../store/products/utils';
import {
    getCurrentWardrobeMeasurementsWall,
    getPreparedSelectedColumn,
} from '../../../../../../store/wardrobe/selectors';
import { Slider } from '../../../../../atoms/Slider/Slider';
import { useMeasurementsDepthRange } from '../../hooks/useMeasurementsDepthRange';

interface DepthMeasurementSliderProps {
    wall: WardrobeWall;
    onChange: () => void;
    label?: string;
}

export const DepthMeasurementSlider: FC<DepthMeasurementSliderProps> = ({ wall, onChange, label = 'Depth' }) => {
    const measurementsDepthRange = useMeasurementsDepthRange(wall);
    const currentWardrobeMeasurements = useSelector(getCurrentWardrobeMeasurementsWall(wall));
    const selectedColumn = useSelector(getPreparedSelectedColumn);
    const dimensions = useDimensions(wall);
    const measuringElement = selectedColumn || currentWardrobeMeasurements;

    const onMeasurementOptionChange = ({ type, value }: DimensionsParams) => {
        dimensions.onChange({ type, value });
        onChange();
    };

    if (measurementsDepthRange === null) {
        return null;
    }

    return (
        <Slider
            label={label}
            minValue={measurementsDepthRange.minValue}
            maxValue={measurementsDepthRange.maxValue}
            handleChange={(value) => {
                onMeasurementOptionChange({
                    type: 'depth',
                    value: value,
                });
            }}
            unit="cm"
            value={measuringElement.depth}
            step={10}
            valueModify={convertValueFromMmToCm}
        />
    );
};
