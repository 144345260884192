import styled from 'styled-components';
import { Color } from '../../../constants/Color.enum';
import { StepsItemState } from './Steps';
import blockBackground from './icons/block.svg';
import visitedBackground from './icons/visited.svg';

const settingMod: {
    [key in StepsItemState]: {
        backgroundImage?: React.CSSProperties['backgroundImage'];
        backgroundColor: React.CSSProperties['backgroundColor'];
        border: React.CSSProperties['border'];
        color: React.CSSProperties['color'];
    };
} = {
    'active-visited': {
        backgroundColor: '#000',
        backgroundImage: visitedBackground.src,
        border: '2px solid #000',
        color: Color.Primary,
    },
    active: {
        backgroundColor: '#fff',
        border: '2px solid #000',
        color: Color.Primary,
    },
    blocked: {
        backgroundColor: '#000',
        backgroundImage: blockBackground.src,
        border: '2px solid #000',
        color: Color.Primary,
    },
    disabled: {
        backgroundColor: '#F5F3F0',
        border: `1px solid ${Color.Secondary}`,
        color: Color.Secondary,
    },

    visited: {
        backgroundColor: '#000',
        backgroundImage: visitedBackground.src,
        border: '2px solid #000',
        color: Color.Primary,
    },
};

export const StepsScrollableShadow = styled.div`
    position: relative;
    --left-opacity: 0;
    --right-opacity: 0;

    :before,
    :after {
        width: 80px;
        height: calc(100% - 8px);
        content: '';
        display: block;
        pointer-events: none;
        position: absolute;
        top: 0;
        z-index: 99;
    }

    :before {
        left: 0;
        background: linear-gradient(90deg, #f5f3f0 0%, rgba(245, 243, 240, 0) 100%);
        opacity: var(--left-opacity);
    }

    :after {
        right: 0;
        background: linear-gradient(270deg, #f5f3f0 0%, rgba(245, 243, 240, 0) 100%);
        opacity: var(--right-opacity);
    }
`;

export const StepsScrollable = styled.div`
    width: 100%;
    overflow: auto;
    background: ${Color.Background};
    cursor: pointer;

    ::-webkit-scrollbar {
        height: 8px;
        width: 8px;
        background: #f5f3f0;
        border-radius: 1ex;
    }

    ::-webkit-scrollbar-thumb {
        background: #f5f3f0;
        border-radius: 1ex;
        cursor: pointer;
        opacity: 0;
    }

    :hover {
        ::-webkit-scrollbar-thumb {
            background: #d9d9d9;
        }
    }

    ::-webkit-scrollbar-corner {
        background: #f5f3f0;
    }
`;

export const StepsContainer = styled.div`
    display: flex;
    padding: 17px 0;
    cursor: default;
    user-select: none;
`;

export const StepsVerticalContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    padding: 0;
    margin: -25px 0;
    cursor: default;
    user-select: none;
`;

export const StepItem = styled.div<{
    state: StepsItemState;
}>`
    display: block;
    position: relative;

    :after,
    :before {
        content: '';
        width: 50%;
        border: 1px solid ${Color.Primary};
        position: absolute;
        display: none;
        top: 8px;
    }

    :not(:last-child):after {
        display: block;
        right: 0;
    }
    :not(:first-child):before {
        display: block;
        left: 0;
    }
`;

export const StepVerticalItem = styled.div<{
    state: StepsItemState;
}>`
    display: grid;
    position: relative;
    grid-template-columns: auto 1fr;
    gap: 8px;
    padding: 25px 0;

    :after,
    :before {
        content: '';
        height: 33px;
        border-right: 2px solid ${Color.Primary};
        position: absolute;
        display: none;

        left: 7px;
    }

    :not(:last-child):after {
        display: block;
        bottom: 4px;
    }
    :not(:first-child):before {
        display: block;
        top: -4px;
    }
`;

export const StepItemDot = styled.div<{
    state: StepsItemState;
}>`
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(${({ state }) => settingMod[state].backgroundImage || ''});
    background-position: center center;
    background-repeat: no-repeat;
    background-color: ${({ state }) => settingMod[state].backgroundColor};
    border: ${({ state }) => settingMod[state].border};
    border-radius: 8px;
    position: relative;
    margin: 0 auto;
    z-index: 9;

    :after {
        display: ${({ state }) => (state === 'active-visited' ? 'block' : 'none')};
        width: 24px;
        height: 24px;
        border: 1px solid ${Color.Primary};
        content: '';
        position: absolute;
        top: -6px;
        left: -6px;
        border-radius: 12px;
    }
`;

export const StepItemLabel = styled.div<{
    state: StepsItemState;
    type: 'horizontal' | 'vertical';
}>`
    display: block;
    min-width: 70px;
    padding: 0 5px;
    text-align: ${({ type }) => (type === 'horizontal' ? 'center' : 'left')};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${({ state }) => settingMod[state].color};
    white-space: nowrap;
`;
