import { setSelectedColumnDimensions, setWallDimensions, setWardrobeDimensions } from '../store/wardrobe/actions';
import { setColumnWidth, setWardrobeWidth } from '../store/wardrobe/thunks';
import { useStore } from 'react-redux';
import { getSelectedColumnIndex, getVisibleColumn } from '../store/wardrobe/selectors';
import { OptionDimensions } from '../store/products/utils';
import { fetchWardrobePrice } from '../store/configurations/actions';
import { useCallback } from 'react';
import { RootState } from '../store';
import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { WardrobeColumn } from '../store/wardrobe/reducer';
import { useCurrency } from '../components/Currency/CurrencyProvider';

export type DimensionsParams = {
    type: keyof OptionDimensions;
    value: number;
};

export const useDimensions = (wall: WardrobeWall) => {
    const { dispatch, getState } = useStore<RootState, any>();
    const { currentCurrency } = useCurrency();

    const onChange = useCallback(
        ({ type, value }: DimensionsParams) => {
            const state = getState();
            const selectedColumnIndex = getSelectedColumnIndex(state);

            const selectedColumn: WardrobeColumn | null =
                selectedColumnIndex !== null ? state.wardrobe.columns[selectedColumnIndex] || null : null;

            // const wardrobeType = getWardrobeType(state);
            const activeHeightForSingleColumn = false; //wardrobeType === 'wardrobe';

            if (selectedColumn && (activeHeightForSingleColumn || type !== 'height')) {
                if (type === 'width') {
                    dispatch(setColumnWidth(value, selectedColumn.wall));
                } else {
                    dispatch(
                        setSelectedColumnDimensions({
                            type,
                            value,
                        }),
                    );
                }
            } else {
                if (type === 'width') {
                    dispatch(setWardrobeWidth(value, wall));
                } else if (type === 'depth') {
                    dispatch(setWallDimensions({ type, value, wall }));
                } else {
                    dispatch(setWardrobeDimensions({ type, value }));
                }
            }

            dispatch(
                fetchWardrobePrice({
                    currencyCode: currentCurrency,
                }),
            );
        },
        [dispatch, getState, wall, currentCurrency],
    );

    const alignHeight = useCallback(() => {
        const state = getState();
        const columns = getVisibleColumn(state);
        const index = getSelectedColumnIndex(state);
        const heights = columns.map((column) => column.data.height);

        if (index !== null) {
            const suggestHeight = heights[index];

            if (suggestHeight) {
                dispatch(
                    setWardrobeDimensions({
                        type: 'height',
                        value: suggestHeight,
                    }),
                );
                dispatch(
                    fetchWardrobePrice({
                        currencyCode: currentCurrency,
                    }),
                );
            }

            return;
        }

        const groupedHeights = heights.reduce<{ [height: number]: number }>((acc, height) => {
            acc[height] = (acc[height] || 0) + 1;

            return acc;
        }, {});

        let currentCount = 0;
        let currentHeight = heights[0] || 0;

        Object.entries(groupedHeights).forEach(([heightStr, count]) => {
            const height = parseInt(heightStr, 10);

            if (currentCount < count) {
                currentCount = count;
                currentHeight = height;
            } else if (currentCount === count && currentHeight > height) {
                currentHeight = height;
            }
        });

        dispatch(setWardrobeDimensions({ type: 'height', value: currentHeight }));
        dispatch(
            fetchWardrobePrice({
                currencyCode: currentCurrency,
            }),
        );
    }, [dispatch, getState, currentCurrency]);

    return { onChange, alignHeight };
};
