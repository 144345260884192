import styled from 'styled-components';

export { Slider } from '@formify/frontend-components';

export const SliderGroupStyled = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 48px 20px;
    padding-bottom: 24px;
    align-items: center;
`;
