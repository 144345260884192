import React, { FC } from 'react';
import {
    CardOptionImageContainer,
    CardOptionInitialContainer,
    CardOptionInitialWrapper,
    BaseCardOptionWrapper,
    TextInsideWrapper,
    TextOutsideWrapper,
    ImageWrapperStyled,
    ImageCroppedStyled,
    PriceWrapperStyled,
} from './CardOption.styled';
import { SubtitleS } from '@formify/frontend-components';
import { convertValueFromCentsToEuro } from '../../../../store/configurations/utils';
import Skeleton from 'react-loading-skeleton';
import { InfoButton } from '../../../InfoButton/InfoButton';
import { useImageComponent } from '../../../ImageWithShimmerEffect/ImageContextComponent';
import { useCurrency } from '../../../Currency/CurrencyProvider';

export type CardVariant = 'initial' | 'background' | 'text-outside';

export interface CardOptionProps {
    dataTestId?: string;
    onClick?: () => void;
    onDetailsClick?: () => void;
    imgPath?: string;
    variant?: CardVariant;
    title: string;
    price: number | null;
    priceDisplay?: 'absolute' | 'relative';
    isSelected?: boolean;
    disabled?: boolean;
    isLoadingPrice?: boolean;
    imgHeight?: number;
    imgWidth?: number;
    backgroundColor?: string;
}

export const CardOption: FC<CardOptionProps> = ({
    dataTestId,
    onClick,
    imgPath,
    title,
    price,
    isLoadingPrice = false,
    disabled,
    onDetailsClick,
    variant = 'initial',
    isSelected = false,
    imgHeight = 336,
    backgroundColor,
    priceDisplay = 'absolute',
}) => {
    const { formatPrice } = useCurrency();

    const renderText = () => (
        <>
            <div
                style={{
                    overflow: 'hidden',
                }}
            >
                <SubtitleS
                    fontWeight={400}
                    className="text-left"
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {title}
                </SubtitleS>
            </div>
            {price !== null && (
                <PriceWrapperStyled>
                    {isLoadingPrice && <Skeleton inline width={50} />}
                    {!isLoadingPrice && (
                        <SubtitleS fontWeight={400}>
                            {priceDisplay === 'relative' && price > 0 ? '+' : ''}
                            {formatPrice(convertValueFromCentsToEuro(price || 0))}
                        </SubtitleS>
                    )}
                </PriceWrapperStyled>
            )}
        </>
    );

    const WrapperComponent = variant === 'initial' ? CardOptionInitialWrapper : BaseCardOptionWrapper;
    const ContainerComponent = variant === 'initial' ? CardOptionInitialContainer : CardOptionImageContainer;
    const Image = useImageComponent();

    return (
        <ContainerComponent data-test-id={dataTestId} onClick={onClick} disabled={disabled} variant={variant}>
            <WrapperComponent isImageEmpty={!imgPath} imgHeightPercent={(imgHeight / 336) * 100}>
                {onDetailsClick && (
                    <InfoButton
                        onClick={(e) => {
                            e.stopPropagation();
                            onDetailsClick();
                        }}
                    />
                )}
                <ImageWrapperStyled isSelected={isSelected}>
                    <ImageCroppedStyled isSelected={isSelected} background={backgroundColor}>
                        {imgPath ? (
                            <Image
                                src={imgPath}
                                alt={title}
                                height={imgHeight}
                                width={336}
                                objectFit="cover"
                                priority
                            />
                        ) : (
                            <div style={{ width: '336px', height: imgHeight }}></div>
                        )}
                    </ImageCroppedStyled>
                </ImageWrapperStyled>
                {variant !== 'text-outside' && <TextInsideWrapper>{renderText()}</TextInsideWrapper>}
            </WrapperComponent>
            {variant === 'text-outside' && <TextOutsideWrapper>{renderText()}</TextOutsideWrapper>}
        </ContainerComponent>
    );
};
