import React, { FC } from 'react';
import { OptionWrapper, IconWrapper, ImageWrapper } from './CardMobileOption.styled';
import { LoadingIcon } from '../../../icons/Loading/Loading';
import { useImageComponent } from '../../../ImageWithShimmerEffect/ImageContextComponent';

export interface CardMobileOptionProps {
    onClick?: () => void;
    imgPath?: string;
    icon?: React.ReactNode;
    disabled?: boolean;
    loading?: boolean;
    isSelected?: boolean;
}

export const CardMobileOption: FC<CardMobileOptionProps> = ({
    onClick,
    imgPath,
    icon,
    disabled,
    isSelected = false,
    loading = false,
}) => {
    const Image = useImageComponent();

    return (
        <OptionWrapper onClick={onClick} disabled={disabled} loading={loading} isSelected={isSelected}>
            {imgPath && (
                <ImageWrapper>
                    <Image src={imgPath} alt="" height={84} width={84} objectFit="cover" priority />
                </ImageWrapper>
            )}
            {(icon || imgPath) && <IconWrapper>{loading ? <LoadingIcon /> : icon}</IconWrapper>}
        </OptionWrapper>
    );
};
