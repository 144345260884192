import React, { FC } from 'react';
import { CardOption } from '../../../Card/components/CardOption/CardOption';
import { SidePanelEditComponentProps } from '../../constants';
import { TwoColumnCardContainer } from '../../../Card/components/CardOption/CardOption.styled';
import { useAllSplitActions } from './useAllSplitActions';

type SplitActionsProps = SidePanelEditComponentProps;

export const SplitActions: FC<SplitActionsProps> = ({ isOpen = true, onOpenDetails }) => {
    const actions = useAllSplitActions(onOpenDetails, isOpen);

    return (
        <TwoColumnCardContainer data-test="two_column_card_container">
            {actions.map((action) => (
                <CardOption key={action.title} {...action} />
            ))}
        </TwoColumnCardContainer>
    );
};
