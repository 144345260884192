import React, { FC } from 'react';
import { CardComponentWrapper, ImageWrapperStyled, LeftWrapper, ContainerStyled } from './CardComponent.styled';
import { SubtitleXS, SubtitleS } from '@formify/frontend-components';
import { Color } from '../../../../constants/Color.enum';
import { useImageComponent } from '../../../ImageWithShimmerEffect/ImageContextComponent';
import { useLanguage } from '../../../Translations/LanguageProvider';

export interface CardComponentProps {
    onClick?: () => void;
    imgPath?: string;
    error?: boolean;
    disabled?: boolean;
    subtitle: string;
    title: string;
    loading?: boolean;
    textOnly?: boolean;
    dataTestId?: string;
}

export const CardComponent: FC<CardComponentProps> = ({
    onClick,
    imgPath,
    error,
    subtitle,
    title,
    loading,
    disabled,
    textOnly,
    dataTestId,
}) => {
    const Image = useImageComponent();
    const { t } = useLanguage();

    return (
        <ContainerStyled
            error={error}
            disabled={disabled}
            onClick={onClick}
            data-test-id={dataTestId}
            data-test="CardComponent"
        >
            <CardComponentWrapper>
                <LeftWrapper>
                    <SubtitleXS>{t(subtitle)}</SubtitleXS>
                    <SubtitleS color={error ? Color.Error : undefined} fontWeight={400}>
                        {title}
                    </SubtitleS>
                </LeftWrapper>
                {!textOnly && (
                    <ImageWrapperStyled>
                        {imgPath && !loading && (
                            <Image src={imgPath} alt={title} height={80} width={80} objectFit="cover" />
                        )}
                    </ImageWrapperStyled>
                )}
            </CardComponentWrapper>
        </ContainerStyled>
    );
};
