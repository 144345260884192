import styled from 'styled-components';
import { Color } from '../../../../constants/Color.enum';

interface OptionWrapperProps {
    disabled?: boolean;
    loading?: boolean;
    isSelected?: boolean;
}

export const OptionWrapper = styled.div<OptionWrapperProps>`
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    height: 94px;
    width: 94px;
    margin: 0 2px;
    border-radius: 10px;
    color: ${Color.Surface};
    opacity: ${(props) => (props.disabled ? '0.2' : '1')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'initial')};
    border: 2px solid transparent;
    border-color: ${({ isSelected }) => isSelected && Color.Surface};
    padding: 2px;
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ImageWrapper = styled.div`
    display: flex;
    border-radius: 7px;
    overflow: hidden;
    background-color: ${Color.Surface};

    // iOS fix
    img {
        border-radius: 7px;
    }
`;
