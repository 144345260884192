import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { splitNumberValueToArray } from '../../components/WardrobeWidth/helpers/splitNumberValueToArray';
import { getWardrobeDimensions } from '../products/selectors';
import { setColumnsWidth, setSelectedColumnIndex } from './actions';
import {
    getSelectedColumnIndex,
    getSelectedColumnWallIndex,
    getVisibleColumnWall,
    recalculateOtherColumnsIfMoreThanTwoDimensions,
    recalculateOtherColumnsIfMoreThanTwoDimensionsForWallB,
} from './selectors';
import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';

export const setWardrobeWidth =
    (width: number, wall: WardrobeWall): ThunkAction<void, RootState, void, any> =>
    (dispatch, getState) => {
        const state = getState();
        const { doubleClosetWidth, singleClosetWidth } = getWardrobeDimensions(state);
        const column = getVisibleColumnWall(wall)(state);

        if (doubleClosetWidth === null || singleClosetWidth === null) {
            return;
        }

        if (wall === 'B') {
            const first = column[0]?.data.width || 1000;
            const last = column[column.length - 1]?.data.width || 1000;

            const columnWidths = splitNumberValueToArray({
                length: column.length - 2,
                value: Math.max(width - first - last, singleClosetWidth.min),
                max: doubleClosetWidth.max,
                min: singleClosetWidth.min,
            });

            dispatch(
                setColumnsWidth({
                    columnsWidth: [first, ...columnWidths, last],
                    wall,
                }),
            );

            return;
        }

        const columnWidths = splitNumberValueToArray({
            length: column.length,
            value: width,
            max: doubleClosetWidth.max,
            min: singleClosetWidth.min,
        });

        dispatch(
            setColumnsWidth({
                columnsWidth: columnWidths,
                wall,
            }),
        );
    };

export const setColumnWidth =
    (columnWidth: number, wall: WardrobeWall): ThunkAction<void, RootState, void, any> =>
    (dispatch, getState) => {
        const state = getState();
        const visibleColumns = getVisibleColumnWall(wall)(state);
        const selectedColumnIndex = getSelectedColumnIndex(state);
        const selectedColumnIndexInCurrentWall = getSelectedColumnWallIndex(state);
        const isLastColumnInWall = selectedColumnIndexInCurrentWall === visibleColumns.length - 1;

        const columns =
            visibleColumns.length === 1
                ? [columnWidth]
                : (wall === 'B'
                      ? recalculateOtherColumnsIfMoreThanTwoDimensionsForWallB
                      : recalculateOtherColumnsIfMoreThanTwoDimensions)(
                      columnWidth,
                      wall,
                  )(state);

        dispatch(
            setColumnsWidth({
                columnsWidth: columns,
                wall: wall,
            }),
        );

        if (isLastColumnInWall && selectedColumnIndex !== null) {
            dispatch(setSelectedColumnIndex(selectedColumnIndex + columns.length - visibleColumns.length));
        }
    };
