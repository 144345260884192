import React, { FC, useEffect } from 'react';
import { MobileMenuContainer } from '../../../atoms/MobileMenuContainer/MobileMenuContainer';
import { CardMobileOption } from '../../../Card/components/CardMobileOption/CardMobileOption';
import { MobileMenuItemTooltip } from '../../../MobileMenuItemTooltip/MobileMenuItemTooltip';
import { MobileMenuEditComponentProps } from '../../constants';
import { useColorOptions } from './useColorOptions';

export const FramesMobile: FC<MobileMenuEditComponentProps> = ({ onOpenDetails }) => {
    const options = useColorOptions(onOpenDetails, false);
    const activeOption = options.find((option) => option.isSelected);

    useEffect(() => {
        options.forEach((option) => option.prepareTexture());
    }, [options]);

    return (
        <MobileMenuContainer>
            {activeOption && (
                <MobileMenuItemTooltip
                    label={activeOption.title}
                    bottomSpacing={180}
                    onClick={activeOption.onDetailsClick}
                ></MobileMenuItemTooltip>
            )}
            <div className="w-full flex overflow-x-auto">
                {options.map((option) => (
                    <div key={option.title}>
                        <CardMobileOption
                            isSelected={option.isSelected}
                            imgPath={option.imgPath}
                            onClick={option.onClick}
                        />
                    </div>
                ))}
            </div>
        </MobileMenuContainer>
    );
};
