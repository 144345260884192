import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useDimensions, DimensionsParams } from '../../../../../../hooks/useDimensions';
import { getWardrobeDimensions } from '../../../../../../store/products/selectors';
import { convertValueFromMmToCm } from '../../../../../../store/products/utils';
import {
    getCurrentWardrobeMeasurementsWall,
    getPreparedSelectedColumn,
    getAdditionalFrameSize,
} from '../../../../../../store/wardrobe/selectors';
import { Slider } from '../../../../../atoms/Slider/Slider';
import { useLanguage } from '../../../../../Translations/LanguageProvider';

interface HeightMeasurementSliderProps {
    wall: WardrobeWall;
    onChange: () => void;
    label?: string;
}

export const HeightMeasurementSlider: FC<HeightMeasurementSliderProps> = ({ wall, onChange, label = 'Height' }) => {
    const { wardrobeHeight } = useSelector(getWardrobeDimensions);
    const currentWardrobeMeasurements = useSelector(getCurrentWardrobeMeasurementsWall(wall));
    const selectedColumn = useSelector(getPreparedSelectedColumn);
    const dimensions = useDimensions(wall);
    const measuringElement = selectedColumn || currentWardrobeMeasurements;
    const { t } = useLanguage();

    const onMeasurementOptionChange = ({ type, value }: DimensionsParams) => {
        dimensions.onChange({ type, value });
        onChange();
    };

    const { additionalHeight } = useSelector(getAdditionalFrameSize);

    if (wardrobeHeight === null) {
        return null;
    }

    return (
        <Slider
            dataTestId="height"
            label={label ? t(label) : undefined}
            minValue={wardrobeHeight.min + additionalHeight}
            maxValue={wardrobeHeight.max}
            handleChange={(value) =>
                onMeasurementOptionChange({
                    type: 'height',
                    value: value - additionalHeight,
                })
            }
            unit="cm"
            value={measuringElement.height + additionalHeight}
            step={10}
            valueModify={convertValueFromMmToCm}
        />
    );
};
