import React from 'react';
import { useSelector } from 'react-redux';
import { CardComponent } from '../../Card/components/CardComponent/CardComponent';
import { getSelectedDoorHandlePosition } from '../../../store/wardrobe/selectors';
import { getDoorHandlePositionByCode } from '../../../store/products/selectors';
import { useLanguage } from '../../Translations/LanguageProvider';

interface DoorHandlesPositionItemProps {
    onClick(): void;
}

export const DoorHandlesPositionItem = ({ onClick }: DoorHandlesPositionItemProps) => {
    const selectedDoorHandlePosition = useSelector(getSelectedDoorHandlePosition);
    const selectedDoorHandlePositionOption = useSelector(getDoorHandlePositionByCode(selectedDoorHandlePosition));
    const { currentLanguage } = useLanguage();

    return (
        <CardComponent
            subtitle="Handle position"
            key="Handle position"
            title={selectedDoorHandlePositionOption?.name?.[currentLanguage] || ''}
            onClick={onClick}
            imgPath={selectedDoorHandlePositionOption?.image.path}
            dataTestId="door-handle-position"
        />
    );
};
