import React, { FC } from 'react';
import { TooltipWrapperStyled, TextStyled } from './Tooltip.styled';

export interface TooltipProps {
    text: string;
    onClick?: () => void;
    children?: React.ReactNode;
    className?: string;
    transparent?: boolean;
}

export const Tooltip: FC<TooltipProps> = ({ text, children, onClick, className, transparent = true }) => (
    <TooltipWrapperStyled
        className={className}
        isClickable={Boolean(onClick)}
        onClick={onClick}
        transparent={transparent}
    >
        <TextStyled>{text}</TextStyled>
        {children}
    </TooltipWrapperStyled>
);
