import React from 'react';
import { WardrobePrice } from '../../SidePanel/components/WardrobePrice/WardrobePrice';
import { AddCurrentWardrobeToCart } from '../../SidePanel/components/AddCurrentWardrobeToCart/AddCurrentWardrobeToCart';

export const StickyFooterAddToCart: React.FC<{}> = () => (
    <>
        <WardrobePrice isHidden={false} />
        <AddCurrentWardrobeToCart />
    </>
);
