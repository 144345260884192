import { RootState } from '../../store';
import { getSelectedDoorProductForWardrobe } from '../../store/products/selectors';
import {
    getSelectedColumnIndex,
    getNumberOfDoorsInCurrentWardrobe,
    getSelectedDoorHandle,
    getVisibleColumnWall,
    countNumberOfVisibleColumnWall,
} from '../../store/wardrobe/selectors';
import { DetailsContentElement } from './components/Details';
import { DoorOptions } from './components/DoorOptions/DoorOptions';
import { DoorOptionsMobile } from './components/DoorOptions/DoorOptionsMobile/DoorOptionsMobile';
import { WardrobeType } from './components/Frame/WardrobeType';
import { WardrobeTypeMobile } from './components/Frame/WardrobeTypeMobile/WardrobeTypeMobile';
import { Frames } from './components/Frames/Frames';
import { FramesMobile } from './components/Frames/FramesMobile';
import { HandlePosition } from './components/HandlePosition/HandlePosition';
import { HandlePositionMobile } from './components/HandlePosition/HandlePositionMobile';
import { Handles } from './components/Handles/Handles';
import { HandlesMobile } from './components/Handles/HandlesMobile';
import { LedOptions } from './components/LedOptions/LedOptions';
import { LedOptionsMobile } from './components/LedOptions/LedOptionsMobile/LedOptionsMobile';
import {
    MeasurementsMobileDepthGenerator,
    MeasurementsMobileWidth,
} from './components/Measurements/components/MeasurementsMobile/MeasurementsMobile';
import { MeasurementsMobileHeightForAllWalls } from './components/Measurements/components/MeasurementsMobileHeightForAllWalls/MeasurementsMobileHeightForAllWalls';
import { Measurements, MeasurementsCreator } from './components/Measurements/Measurements';
import { Shelves } from './components/Shelves/Shelves';
import { ShelvesMobile } from './components/Shelves/ShelvesMobile/ShelvesMobile';
import { SplitActions } from './components/SplitActions/SplitActions';
import { SplitActionsMobile } from './components/SplitActions/SplitActionsMobile';
import { DoorHandlesItem } from './DesktopItems/DoorHandlesItem';
import { DoorHandlesPositionItem } from './DesktopItems/DoorHandlesPositionItem';
import { DoorItem } from './DesktopItems/DoorItem';
import { FrameItem } from './DesktopItems/FrameItem';
import { FramesItem } from './DesktopItems/FramesItem';
import { LayoutItem } from './DesktopItems/LayoutItem';
import { LightingItem } from './DesktopItems/LightingItem';
import { MeasurementsItem } from './DesktopItems/MeasurementsItem';
import { ShelvesItem } from './DesktopItems/ShelvesItem';
import { SplittingItem } from './DesktopItems/SplittingItem';
import { StickyFooterWithPrice } from '../Sidebar/components/StickyFooterWithPrice';
import { StickyFooterAddToCart } from '../Sidebar/components/StickyFooterAddToCart';
import { WardrobeOptions } from './components/WardrobeOptions/WardrobeOptions';
import { createWardrobeStructureBasedOnState } from '../../services/nodes/wardrobe/wardrobe';
import { ShelveNode } from '../../services/nodes/wardrobe/nodes/ShelveNode';
import { GlassShelveNode } from '../../services/nodes/wardrobe/nodes/GlassShelveNode';
import { MeasurementsMobileWall } from './components/Measurements/components/MeasurementsMobile/MeasurementsMobileWall';
import { getSplitActionVisibility } from './components/SplitActions/useAllSplitActions';
import dynamic from 'next/dynamic';
import { getPublicEnv } from '../../services/env/env';

const LayoutMobile = dynamic(
    async () => {
        const dragAndDropIsActive = (await getPublicEnv('FEAT_FLAG_DRAG')) === '1';

        if (dragAndDropIsActive) {
            return (await import('./components/LayoutCustom/LayoutCustomMobile')).LayoutCustomMobile;
        }

        return (await import('./components/Layout/LayoutMobile/LayoutMobile')).LayoutMobile;
    },
    {
        ssr: false,
        loading: () => null,
    },
);

const Layout = dynamic(
    async () => {
        const dragAndDropIsActive = (await getPublicEnv('FEAT_FLAG_DRAG')) === '1';

        if (dragAndDropIsActive) {
            return (await import('./components/LayoutCustom/LayoutCustom')).LayoutCustom;
        }

        return (await import('./components/Layout/Layout')).Layout;
    },
    {
        ssr: false,
        loading: () => null,
    },
);

const OPTION_NAMES = [
    'wardrobeType',
    'width',
    'height',
    'depth',
    'layout',
    'measurements',
    'measurementsA',
    'measurementsB',
    'measurementsC',
    'main',
    'init',
    'doors',
    'summary',
    'split',
    'shelves',
    'handles',
    'frames',
    'handle-position',
    'led',
    'columnSetting',
    'measurementsColumn',
] as const;

export type OptionName = (typeof OPTION_NAMES)[number];

export const isOptionName = (name: any): name is OptionName => OPTION_NAMES.includes(name);

export interface MobileMenuEditComponentProps {
    onOpenDetails: (detailsValues: DetailsContentElement) => void;
    isOpen: boolean;
}

export interface SidePanelEditComponentProps {
    isOpen?: boolean;
    onOpenDetails: (detailsValues: DetailsContentElement) => void;
    onChangeVisibilitySubDetails: (visibility: boolean) => void;
}

export interface OptionSettings {
    label: string;
    doorVisible: 'always' | 'never' | 'same';
    insideElementsVisible: 'always' | 'never' | 'same';
    isVisible: (state: RootState) => boolean;
    isVisibleMobile?: (state: RootState, isVisible: boolean) => boolean;
    getMobileTextIfOptionIsNotVisible?: (state: RootState) => string | null;
    selectedStateRequire: 'column' | 'wardrobe' | 'columnOrWardrobe';
    ItemComponent: React.ComponentType<{
        onClick: () => void;
    }>;
    EditComponentMobile: React.ComponentType<MobileMenuEditComponentProps>;
    EditComponent: React.ComponentType<SidePanelEditComponentProps>;
    FooterComponent: React.ComponentType<{}>;
}

export const ALL_OPTIONS: {
    [key in OptionName]: OptionSettings;
} = {
    wardrobeType: {
        label: 'Type',
        doorVisible: 'same',
        insideElementsVisible: 'same',
        isVisible: () => true,
        selectedStateRequire: 'wardrobe',
        EditComponentMobile: WardrobeTypeMobile,
        ItemComponent: FrameItem,
        EditComponent: WardrobeType,
        FooterComponent: StickyFooterWithPrice,
    },
    measurements: {
        label: 'Measurements',
        doorVisible: 'same',
        insideElementsVisible: 'same',
        isVisible: (state) =>
            getVisibleColumnWall('B')(state).length === 0 && getVisibleColumnWall('C')(state).length === 0,
        EditComponentMobile: () => null,
        selectedStateRequire: 'wardrobe',
        ItemComponent: ({ onClick }) => <MeasurementsItem onClick={onClick} wallIndex={0} />,
        EditComponent: Measurements,
        FooterComponent: StickyFooterWithPrice,
    },

    measurementsColumn: {
        label: 'Measurements',
        doorVisible: 'same',
        insideElementsVisible: 'same',
        isVisible: (state) => getSelectedColumnIndex(state) !== null,
        isVisibleMobile: () => false,
        EditComponentMobile: () => null,
        selectedStateRequire: 'column',
        ItemComponent: ({ onClick }) => <MeasurementsItem onClick={onClick} wallIndex={0} />,
        EditComponent: Measurements,
        FooterComponent: StickyFooterWithPrice,
    },

    measurementsA: {
        label: 'Wall A',
        doorVisible: 'same',
        insideElementsVisible: 'same',
        isVisible: (state) => countNumberOfVisibleColumnWall(state) > 1, // have column A and B
        isVisibleMobile: (state) => countNumberOfVisibleColumnWall(state) > 1,
        EditComponentMobile: ({ isOpen }) => <MeasurementsMobileWall wallIndex={0} isOpen={isOpen} />,
        selectedStateRequire: 'wardrobe',
        ItemComponent: ({ onClick }) => (
            <MeasurementsItem subtitle="Measurements - Wall A" onClick={onClick} wallIndex={0} />
        ),
        EditComponent: MeasurementsCreator(0),
        FooterComponent: StickyFooterWithPrice,
    },

    measurementsB: {
        label: 'Wall B',
        doorVisible: 'same',
        insideElementsVisible: 'same',
        isVisible: (state) => countNumberOfVisibleColumnWall(state) > 1,
        isVisibleMobile: (state) => countNumberOfVisibleColumnWall(state) > 1,
        EditComponentMobile: ({ isOpen }) => <MeasurementsMobileWall wallIndex={1} isOpen={isOpen} />,
        selectedStateRequire: 'wardrobe',
        ItemComponent: ({ onClick }) => (
            <MeasurementsItem subtitle="Measurements - Wall B" onClick={onClick} wallIndex={1} />
        ),
        EditComponent: MeasurementsCreator(1),
        FooterComponent: StickyFooterWithPrice,
    },

    measurementsC: {
        label: 'Wall C',
        doorVisible: 'same',
        insideElementsVisible: 'same',
        isVisible: (state) => countNumberOfVisibleColumnWall(state) > 2,
        EditComponentMobile: ({ isOpen }) => <MeasurementsMobileWall wallIndex={2} isOpen={isOpen} />,
        isVisibleMobile: (state) => countNumberOfVisibleColumnWall(state) > 2,
        selectedStateRequire: 'wardrobe',
        ItemComponent: ({ onClick }) => (
            <MeasurementsItem subtitle="Measurements - Wall C" onClick={onClick} wallIndex={2} />
        ),
        EditComponent: MeasurementsCreator(2),
        FooterComponent: StickyFooterWithPrice,
    },
    'handle-position': {
        label: 'Handle position',
        doorVisible: 'always',
        insideElementsVisible: 'same',
        isVisible: (state) => {
            const selectedDoorHandle = getSelectedDoorHandle(state);

            return selectedDoorHandle !== null;
        },
        isVisibleMobile: (state) => {
            const numbersOfDoors = getNumberOfDoorsInCurrentWardrobe(state);

            return numbersOfDoors > 0;
        },
        getMobileTextIfOptionIsNotVisible: (state) => {
            const selectedColumnIndex = getSelectedColumnIndex(state);

            if (selectedColumnIndex === null) {
                return 'Tap a closet to edit';
            }
            const column = state.wardrobe.columns[selectedColumnIndex];

            if (column?.door === null) {
                return 'Tap a closet with doors';
            }

            return null;
        },
        selectedStateRequire: 'column',
        EditComponentMobile: HandlePositionMobile,
        ItemComponent: DoorHandlesPositionItem,
        EditComponent: HandlePosition,
        FooterComponent: StickyFooterWithPrice,
    },
    depth: {
        label: 'Depth',
        doorVisible: 'same',
        insideElementsVisible: 'same',
        isVisible: (state) =>
            (getVisibleColumnWall('B')(state).length === 0 && getVisibleColumnWall('C')(state).length === 0) ||
            getSelectedColumnIndex(state) !== null,
        selectedStateRequire: 'columnOrWardrobe',
        EditComponentMobile: MeasurementsMobileDepthGenerator('A'),
        ItemComponent: () => null,
        EditComponent: () => null,
        FooterComponent: StickyFooterWithPrice,
    },
    doors: {
        label: 'Doors',
        doorVisible: 'always',
        insideElementsVisible: 'same',
        isVisible: () => true,
        selectedStateRequire: 'columnOrWardrobe',
        EditComponentMobile: DoorOptionsMobile,
        ItemComponent: DoorItem,
        EditComponent: DoorOptions,
        FooterComponent: StickyFooterWithPrice,
    },
    frames: {
        label: 'Color',
        doorVisible: 'same',
        insideElementsVisible: 'always',
        isVisible: () => true,
        selectedStateRequire: 'wardrobe',
        EditComponentMobile: FramesMobile,
        ItemComponent: FramesItem,
        EditComponent: Frames,
        FooterComponent: StickyFooterWithPrice,
    },
    handles: {
        label: 'Handles',
        doorVisible: 'always',
        insideElementsVisible: 'same',
        isVisible: (state) => {
            const selectedDoorProduct = getSelectedDoorProductForWardrobe(state);
            const numbersOfDoors = getNumberOfDoorsInCurrentWardrobe(state);
            const selectedColumnIndex = getSelectedColumnIndex(state);

            return (selectedDoorProduct !== null || numbersOfDoors > 0) && selectedColumnIndex === null;
        },
        isVisibleMobile: (state) => {
            const numbersOfDoors = getNumberOfDoorsInCurrentWardrobe(state);

            return numbersOfDoors > 0;
        },
        selectedStateRequire: 'wardrobe',
        EditComponentMobile: HandlesMobile,
        ItemComponent: DoorHandlesItem,
        EditComponent: Handles,
        FooterComponent: StickyFooterWithPrice,
    },
    height: {
        label: 'Height',
        doorVisible: 'same',
        insideElementsVisible: 'same',
        isVisible: () => true,
        selectedStateRequire: 'wardrobe',
        EditComponentMobile: MeasurementsMobileHeightForAllWalls,
        ItemComponent: () => null,
        EditComponent: () => null,
        FooterComponent: StickyFooterWithPrice,
    },
    columnSetting: {
        label: 'Layout',
        doorVisible: 'same',
        insideElementsVisible: 'same',
        isVisible: () => true,
        selectedStateRequire: 'column',
        EditComponentMobile: LayoutMobile,
        ItemComponent: LayoutItem,
        // EditComponent: ColumnOptionWithLayout,
        EditComponent: Layout,
        FooterComponent: StickyFooterWithPrice,
    },
    layout: {
        label: 'Layout',
        doorVisible: 'never',
        insideElementsVisible: 'same',
        isVisible: () => true,
        selectedStateRequire: 'column',
        EditComponentMobile: LayoutMobile,
        ItemComponent: LayoutItem,
        EditComponent: Layout,
        FooterComponent: StickyFooterWithPrice,
    },
    led: {
        label: 'Lighting',
        doorVisible: 'never',
        insideElementsVisible: 'same',
        isVisible: () => true,
        selectedStateRequire: 'wardrobe',
        EditComponentMobile: LedOptionsMobile,
        ItemComponent: LightingItem,
        EditComponent: LedOptions,
        FooterComponent: StickyFooterWithPrice,
    },
    main: {
        label: 'Summary',
        doorVisible: 'same',
        insideElementsVisible: 'same',
        isVisible: () => true,
        selectedStateRequire: 'columnOrWardrobe',
        EditComponentMobile: () => null,
        ItemComponent: () => null,
        EditComponent: WardrobeOptions,
        FooterComponent: StickyFooterAddToCart,
    },
    init: {
        label: 'Summary',
        doorVisible: 'same',
        insideElementsVisible: 'same',
        isVisible: () => true,
        selectedStateRequire: 'columnOrWardrobe',
        EditComponentMobile: () => null,
        ItemComponent: () => null,
        EditComponent: () => null,
        FooterComponent: () => null,
    },
    shelves: {
        label: 'Shelves',
        doorVisible: 'never',
        insideElementsVisible: 'same',
        isVisible: (state) => {
            const index = getSelectedColumnIndex(state);
            const wardrobe = createWardrobeStructureBasedOnState(state);

            if (wardrobe) {
                if (index === null) {
                    const shelves = wardrobe.findChildrenByInstance<ShelveNode | GlassShelveNode>([
                        ShelveNode,
                        GlassShelveNode,
                    ]);

                    return shelves.length > 0;
                } else {
                    const columns = wardrobe.getChildren();
                    const selectedColumn = columns[index];

                    if (selectedColumn) {
                        const shelves = selectedColumn.findChildrenByInstance<ShelveNode | GlassShelveNode>([
                            ShelveNode,
                            GlassShelveNode,
                        ]);

                        return shelves.length > 0;
                    }
                }
            }

            return false;
        },
        selectedStateRequire: 'wardrobe',
        EditComponentMobile: ShelvesMobile,
        ItemComponent: ShelvesItem,
        EditComponent: Shelves,
        FooterComponent: StickyFooterWithPrice,
    },
    split: {
        label: 'Split',
        doorVisible: 'never',
        insideElementsVisible: 'same',
        isVisible: (state: RootState) => {
            const { splitIsVisible, removeIsVisible } = getSplitActionVisibility(state);

            return splitIsVisible || removeIsVisible;
        },
        selectedStateRequire: 'column',
        EditComponentMobile: SplitActionsMobile,
        ItemComponent: SplittingItem,
        EditComponent: SplitActions,
        FooterComponent: StickyFooterWithPrice,
    },
    summary: {
        label: 'Summary',
        doorVisible: 'same',
        insideElementsVisible: 'same',
        isVisible: () => true,
        selectedStateRequire: 'columnOrWardrobe',
        EditComponentMobile: () => null,
        ItemComponent: () => null,
        EditComponent: WardrobeOptions,
        FooterComponent: StickyFooterAddToCart,
    },
    width: {
        label: 'Width',
        doorVisible: 'same',
        insideElementsVisible: 'same',
        isVisible: (state) =>
            (getVisibleColumnWall('B')(state).length === 0 && getVisibleColumnWall('C')(state).length === 0) ||
            getSelectedColumnIndex(state) !== null,
        selectedStateRequire: 'columnOrWardrobe',
        EditComponentMobile: () => <MeasurementsMobileWidth wall="A" />,
        ItemComponent: () => null,
        EditComponent: () => null,
        FooterComponent: StickyFooterWithPrice,
    },
};

export const OPTION_ORDER: Exclude<OptionName, 'main' | 'summary'>[] = [
    'wardrobeType',
    'measurements',
    'measurementsColumn',
    'measurementsA',
    'measurementsB',
    'measurementsC',
    'layout',
    'doors',
    'shelves',
    'split',
    'frames',
    'led',
    'handles',
    'handle-position',
];

export const OPTION_ORDER_MOBILE: Exclude<OptionName, 'main' | 'summary'>[] = [
    'wardrobeType',
    'measurementsA',
    'measurementsB',
    'measurementsC',
    'width',
    'height',
    'depth',
    'frames',
    'doors',
    'handles',
    'handle-position',
    'led',
    'shelves',
    'layout',
    'split',
];
