import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useDimensions, DimensionsParams } from '../../../../../../hooks/useDimensions';
import { convertValueFromMmToCm } from '../../../../../../store/products/utils';
import {
    getCurrentWardrobeMeasurementsWall,
    getPreparedSelectedColumn,
    getAdditionalFrameSize,
} from '../../../../../../store/wardrobe/selectors';
import { Slider } from '../../../../../atoms/Slider/Slider';
import { useMeasurementsWidthRange } from '../../hooks/useMeasurementsWidthRange';
import { useLanguage } from '../../../../../Translations/LanguageProvider';

interface WidthMeasurementSliderProps {
    wall: WardrobeWall;
    onChange: () => void;
    label?: string;
}

export const WidthMeasurementSlider: FC<WidthMeasurementSliderProps> = ({ wall, onChange, label = 'Width' }) => {
    const measurementsWidthRange = useMeasurementsWidthRange(wall);
    const currentWardrobeMeasurements = useSelector(getCurrentWardrobeMeasurementsWall(wall));
    const selectedColumn = useSelector(getPreparedSelectedColumn);
    const dimensions = useDimensions(wall);
    const measuringElement = selectedColumn || currentWardrobeMeasurements;
    const { t } = useLanguage();

    const onMeasurementOptionChange = ({ type, value }: DimensionsParams) => {
        dimensions.onChange({ type, value });
        onChange();
    };

    const { additionalWidth } = useSelector(getAdditionalFrameSize);
    const additionalWidthNumber = selectedColumn ? 0 : additionalWidth;

    if (measurementsWidthRange === null) {
        return null;
    }

    return (
        <Slider
            dataTestId="slider-width"
            label={label ? t(label) : undefined}
            minValue={measurementsWidthRange.minValue}
            maxValue={measurementsWidthRange.maxValue}
            handleChange={(value) => {
                onMeasurementOptionChange({
                    type: 'width',
                    value: selectedColumn ? value : value - additionalWidthNumber,
                });
            }}
            unit="cm"
            value={selectedColumn ? measuringElement.width : measuringElement.width + additionalWidth}
            step={10}
            valueModify={convertValueFromMmToCm}
        />
    );
};
