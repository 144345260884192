import styled from 'styled-components';

export const InfoButtonWrapper = styled.div`
    position: absolute;
    z-index: 10;
    top: 14px;
    right: 14px;

    button {
        width: 20px;
        height: 20px;
        margin: 0;
    }
`;
