import { useSelector, useStore } from 'react-redux';
import { WardrobeCode } from '../../../../services/products/domain/ProductCodes';
import { RootState } from '../../../../store';
import { fetchWardrobePrice } from '../../../../store/configurations/actions';
import { getWardrobeDimensions } from '../../../../store/products/selectors';
import { setWardrobeDimensions, setColumns, setWardrobeType } from '../../../../store/wardrobe/actions';
import {
    getWardrobeType,
    getCurrentWardrobeMeasurementsWall,
    getAdditionalFreeStandingFrameSize,
    getVisibleColumnWall,
} from '../../../../store/wardrobe/selectors';
import { WardrobeColumn } from '../../../../store/wardrobe/reducer';
import { setWardrobeWidth } from '../../../../store/wardrobe/thunks';
import { mainRendererContext } from '../../../../rendererContexts/mainRendererContext';
import { useWardrobeDispatch } from '@formify/frontend-wardrobe-renderer/dist/modules/context';
import { setCameraStateAction } from '@formify/frontend-wardrobe-renderer/dist/modules/actions';
import { useCurrency } from '../../../Currency/CurrencyProvider';

export function useWardrobeProduct() {
    const wardrobeType = useSelector(getWardrobeType);
    const { dispatch, getState } = useStore<RootState, any>();
    const currentWardrobeMeasurements = useSelector(getCurrentWardrobeMeasurementsWall('A'));
    const dispatchRenderer = useWardrobeDispatch(mainRendererContext);
    const { currentCurrency } = useCurrency();

    const setWardrobeProduct = (newType: WardrobeCode) => {
        const freeStandSize = getAdditionalFreeStandingFrameSize();
        const state = getState();
        const { wardrobeWidth, wardrobeHeight } = getWardrobeDimensions(state);

        if (wardrobeWidth && wardrobeHeight) {
            if (newType === 'wardrobe_free_standing' && wardrobeType == 'wardrobe') {
                if (currentWardrobeMeasurements.width + freeStandSize.additionalWidth > wardrobeWidth.max) {
                    dispatch(setWardrobeWidth(currentWardrobeMeasurements.width - freeStandSize.additionalWidth, 'A'));
                }
                if (currentWardrobeMeasurements.height + freeStandSize.additionalHeight > wardrobeHeight.max) {
                    dispatch(
                        setWardrobeDimensions({
                            type: 'height',
                            value: wardrobeHeight.max - freeStandSize.additionalHeight,
                        }),
                    );
                }
            }
        }
        const columns: WardrobeColumn[] = crateWardrobeColumnByType(state, newType);

        dispatch(setColumns(columns));
        dispatch(setWardrobeType(newType));
        dispatch(
            fetchWardrobePrice({
                currencyCode: currentCurrency,
            }),
        );

        if (newType === 'wardrobe' || newType === 'wardrobe_free_standing') {
            dispatchRenderer(
                setCameraStateAction({
                    type: 'singleWall',
                    position: 'center',
                }),
            );
        } else {
            dispatchRenderer(
                setCameraStateAction({
                    type: 'uShape',
                    position: 'center',
                }),
            );
        }
    };

    return { setWardrobeProduct };
}

const cloneWardrobeColumn = (column: WardrobeColumn): WardrobeColumn => ({ ...column, data: { ...column.data } });

export const crateWardrobeColumnByType = (state: RootState, newType: WardrobeCode) => {
    const columns: WardrobeColumn[] = [];
    const columnsA = getVisibleColumnWall('A')(state).map(cloneWardrobeColumn);
    const columnsB = getVisibleColumnWall('B')(state).map(cloneWardrobeColumn);
    const columnsC = getVisibleColumnWall('C')(state).map(cloneWardrobeColumn);

    function duplicateColumn(columns: WardrobeColumn[], minQuantity: number): WardrobeColumn[] {
        if (columns.length >= minQuantity) {
            return columns;
        }
        const column = columns[0];

        if (column === undefined) {
            return columns;
        }

        return [...columns, ...new Array<WardrobeColumn>(minQuantity - columns.length).fill(column)];
    }

    function createAWall(): WardrobeColumn[] {
        if (columnsA.length > 0) {
            return columnsA;
        } else if (columnsC.length > 0) {
            return columnsC.map((column): WardrobeColumn => ({ ...column, data: { ...column.data }, wall: 'A' }));
        } else if (columnsB.length > 0) {
            return columnsB.map((column): WardrobeColumn => ({ ...column, data: { ...column.data }, wall: 'A' }));
        }

        return [];
    }

    function createCWall(): WardrobeColumn[] {
        if (columnsC.length > 0) {
            return columnsC;
        } else if (columnsA.length > 0) {
            return columnsA.map((column): WardrobeColumn => ({ ...column, data: { ...column.data }, wall: 'C' }));
        } else if (columnsB.length > 0) {
            return columnsB.map((column): WardrobeColumn => ({ ...column, data: { ...column.data }, wall: 'C' }));
        }

        return [];
    }

    function createBWall(): WardrobeColumn[] {
        const columnA = createAWall();

        if (columnsB.length > 0) {
            return columnsB;
        } else {
            return columnA.map(
                // TODO change magic number 1000 to max double width
                (column): WardrobeColumn => ({ ...column, data: { ...column.data, width: 1000 }, wall: 'B' }),
            );
        }

        return [];
    }

    if (newType === 'l_shaped_wardrobe_left') {
        columns.push(...duplicateColumn(createAWall(), 2));
        columns.push(...duplicateColumn(createBWall(), 3));
    } else if (newType === 'l_shaped_wardrobe_right') {
        columns.push(...duplicateColumn(createCWall(), 2));
        columns.push(...duplicateColumn(createBWall(), 3));
    } else if (newType === 'u_shaped_wardrobe') {
        columns.push(...duplicateColumn(createAWall(), 2));
        columns.push(...duplicateColumn(createBWall(), 3));
        columns.push(...duplicateColumn(createCWall(), 2));
    } else if (newType === 'wardrobe' || newType === 'wardrobe_free_standing') {
        columns.push(...createAWall());
    }

    return columns.map(cloneWardrobeColumn);
};
