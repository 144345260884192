import { useSelector } from 'react-redux';
import { ALL_OPTIONS, OptionName, OptionSettings } from '../components/SidePanel/constants';
import { RootState } from '../store';
import { getSelectedColumnIndex } from '../store/wardrobe/selectors';

export type VisibleOptionsResult = { [key in Exclude<OptionName, 'main' | 'summary'>]: boolean };

const selectAllAvailableOptions = (state: RootState): VisibleOptionsResult => {
    const wardrobeIsActive = getSelectedColumnIndex(state) === null;
    const columnIsActive = getSelectedColumnIndex(state) !== null;

    const isActive = (option: OptionSettings) => {
        if (option.selectedStateRequire === 'column' && wardrobeIsActive) {
            return false;
        }
        if (option.selectedStateRequire === 'wardrobe' && columnIsActive) {
            return false;
        }

        return option.isVisible(state);
    };

    return {
        wardrobeType: isActive(ALL_OPTIONS.wardrobeType),
        depth: isActive(ALL_OPTIONS.depth),
        doors: isActive(ALL_OPTIONS.doors),
        frames: isActive(ALL_OPTIONS.frames),
        handles: isActive(ALL_OPTIONS.handles),
        'handle-position': isActive(ALL_OPTIONS['handle-position']),
        height: isActive(ALL_OPTIONS.height),
        layout: isActive(ALL_OPTIONS.layout),
        led: isActive(ALL_OPTIONS.led),
        measurements: isActive(ALL_OPTIONS.measurements),
        shelves: isActive(ALL_OPTIONS.shelves),
        split: isActive(ALL_OPTIONS.split),
        width: isActive(ALL_OPTIONS.width),
        columnSetting: isActive(ALL_OPTIONS.columnSetting),
        init: isActive(ALL_OPTIONS.init),
        measurementsA: isActive(ALL_OPTIONS.measurementsA),
        measurementsB: isActive(ALL_OPTIONS.measurementsB),
        measurementsC: isActive(ALL_OPTIONS.measurementsC),
        measurementsColumn: isActive(ALL_OPTIONS.measurementsColumn),
    };
};

export const selectAllVisibleOptions = (state: RootState): VisibleOptionsResult => {
    const isActive = (option: OptionSettings) => option.isVisible(state);

    return {
        wardrobeType: isActive(ALL_OPTIONS.wardrobeType),
        depth: isActive(ALL_OPTIONS.depth),
        doors: isActive(ALL_OPTIONS.doors),
        frames: isActive(ALL_OPTIONS.frames),
        handles: isActive(ALL_OPTIONS.handles),
        'handle-position': isActive(ALL_OPTIONS['handle-position']),
        height: isActive(ALL_OPTIONS.height),
        layout: isActive(ALL_OPTIONS.layout),
        led: isActive(ALL_OPTIONS.led),
        measurements: isActive(ALL_OPTIONS.measurements),
        shelves: isActive(ALL_OPTIONS.shelves),
        split: isActive(ALL_OPTIONS.split),
        width: isActive(ALL_OPTIONS.width),
        columnSetting: isActive(ALL_OPTIONS.columnSetting),
        init: isActive(ALL_OPTIONS.init),
        measurementsA: isActive(ALL_OPTIONS.measurementsA),
        measurementsB: isActive(ALL_OPTIONS.measurementsB),
        measurementsC: isActive(ALL_OPTIONS.measurementsC),
        measurementsColumn: isActive(ALL_OPTIONS.measurementsColumn),
    };
};

export const useAvailableOptions = (): VisibleOptionsResult => useSelector(selectAllAvailableOptions);
