import React, { FC, useEffect, useState } from 'react';
import { ALL_OPTIONS, OptionName, SidePanelEditComponentProps } from '../../constants';
import { useAvailableOptions } from '../../../../hooks/useVisibleOptions';
import { StickyFooterStyled, TitleWrapperStyledSmall } from '../../../Sidebar/Sidebar.styled';
import { LinkXS } from '@formify/frontend-components';
import {
    hideDoors,
    hideInnerElements,
    showDoors,
    showInnerElements,
} from '@formify/frontend-wardrobe-renderer/dist/modules/actions';
import { useWardrobeDispatch } from '@formify/frontend-wardrobe-renderer/dist/modules/context';
import { mainRendererContext } from '../../../../rendererContexts/mainRendererContext';
import { ContainerForCardComponentWrapper } from '../../../Card/components/CardComponent/CardComponent.styled';
import styled from 'styled-components';
import { Button } from '@formify/frontend-components';
import { ChevronLeftIcon } from '../../../icons/ChevronLeft/ChevronLeft';
import { getSelectedColumnIndex } from '../../../../store/wardrobe/selectors';
import { useSelector } from 'react-redux';

type ColumnOptionProps = SidePanelEditComponentProps;

const OPTION_ORDER_FOR_COLUMN_ONLY: Exclude<OptionName, 'main' | 'summary'>[] = [
    'measurements',
    'layout',
    'doors',
    'shelves',
    'split',
    'handle-position',
];

export const IconWrapperLeft = styled.div`
    cursor: pointer;
    position: absolute;
    z-index: 1;
    left: 0;
`;

export const ColumnOptionWithLayout: FC<ColumnOptionProps> = ({ onOpenDetails, onChangeVisibilitySubDetails }) => {
    const allOptionVisibilityStatus = useAvailableOptions();
    const [details, setDetails] = useState<Exclude<OptionName, 'main' | 'summary'> | null>(null);
    const dispatchRenderer = useWardrobeDispatch(mainRendererContext);
    const selectedColumnIndex = useSelector(getSelectedColumnIndex);

    useEffect(() => {
        setDetails(null);
    }, [selectedColumnIndex]);

    useEffect(() => {
        onChangeVisibilitySubDetails(details !== null);

        return () => onChangeVisibilitySubDetails(false);
    }, [details, onChangeVisibilitySubDetails]);

    return (
        <div>
            {details === null ? (
                <div>
                    <div className="text-center uppercase">
                        <LinkXS>Customize single element</LinkXS>
                    </div>
                    <ContainerForCardComponentWrapper>
                        {OPTION_ORDER_FOR_COLUMN_ONLY.map((key) => {
                            const visible = allOptionVisibilityStatus[key];
                            const { doorVisible, insideElementsVisible, ItemComponent } = ALL_OPTIONS[key];

                            return visible ? (
                                <ItemComponent
                                    onClick={() => {
                                        setDetails(key);

                                        if (doorVisible === 'never') {
                                            dispatchRenderer(hideDoors());
                                        } else if (doorVisible === 'always') {
                                            dispatchRenderer(showDoors());
                                        }

                                        if (insideElementsVisible === 'never') {
                                            dispatchRenderer(hideInnerElements());
                                        } else if (insideElementsVisible === 'always') {
                                            dispatchRenderer(showInnerElements());
                                        }
                                    }}
                                    key={key}
                                />
                            ) : null;
                        })}
                    </ContainerForCardComponentWrapper>
                </div>
            ) : (
                <div>
                    {(() => {
                        const { EditComponent, label } = ALL_OPTIONS[details];

                        return (
                            <div>
                                <TitleWrapperStyledSmall>
                                    <IconWrapperLeft>
                                        <Button
                                            variant="outlined-white"
                                            icon={<ChevronLeftIcon />}
                                            onClick={() => {
                                                setDetails(null);
                                            }}
                                        ></Button>
                                    </IconWrapperLeft>
                                    <LinkXS>
                                        <span className="uppercase">{label}</span>
                                    </LinkXS>
                                </TitleWrapperStyledSmall>

                                <EditComponent
                                    isOpen={true}
                                    onOpenDetails={onOpenDetails}
                                    onChangeVisibilitySubDetails={onChangeVisibilitySubDetails}
                                />

                                <StickyFooterStyled padding="12px 0 32px 0">
                                    <Button
                                        full
                                        variant="primary"
                                        onClick={() => {
                                            setDetails(null);
                                        }}
                                        dataTestId="footer-sidebar-back-button"
                                    >
                                        Back
                                    </Button>
                                </StickyFooterStyled>
                            </div>
                        );
                    })()}
                </div>
            )}
        </div>
    );
};
