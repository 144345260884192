import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDoorHandlePositions } from '../../../../store/products/selectors';
import { CardOptionProps } from '../../../Card/components/CardOption/CardOption';
import { OptionSelect } from '../../../../services/products/domain/ThreeDParameter';
import { assignDoorHandlePosition } from '../../../../store/wardrobe/actions';
import { getSelectedDoorHandlePosition } from '../../../../store/wardrobe/selectors';
import { isDoorHandlePositionCode } from '../../../../services/nodes/wardrobe/wardrobe';
import { DetailsContentElement } from '../Details';
import { useLanguage } from '../../../Translations/LanguageProvider';

export const useHandlePositionOptions = (onOpenDetails: (detailsValues: DetailsContentElement) => void) => {
    const dispatch = useDispatch();
    const doorHandlePositions = useSelector(getDoorHandlePositions);
    const selectedDoorHandlePosition = useSelector(getSelectedDoorHandlePosition);
    const { currentLanguage } = useLanguage();

    const showItemDetails = useCallback(
        (item: OptionSelect) => {
            onOpenDetails({
                type: 'option',
                props: {
                    option: item,
                },
            });
        },
        [onOpenDetails],
    );

    return (doorHandlePositions || []).map<CardOptionProps>((doorHandlePosition) => ({
        price: null,
        isSelected: selectedDoorHandlePosition === doorHandlePosition.value,
        variant: 'text-outside',
        imgPath: doorHandlePosition.image.path,
        title: doorHandlePosition.name[currentLanguage] || '',
        dataTestId: doorHandlePosition.value,
        onDetailsClick: () => showItemDetails(doorHandlePosition),
        onClick: () => {
            const newPosition = doorHandlePosition.value;

            if (isDoorHandlePositionCode(newPosition)) {
                dispatch(assignDoorHandlePosition(newPosition));
            }
        },
    }));
};
