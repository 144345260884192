import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { WardrobeCode, availableWardrobeProducts } from '../../../../../services/products/domain/ProductCodes';
import { getProductByType, getWardrobeProducts } from '../../../../../store/products/selectors';
import { getWardrobeType } from '../../../../../store/wardrobe/selectors';
import { Product } from '../../../../../types/Product';
import { MobileMenuContainer } from '../../../../atoms/MobileMenuContainer/MobileMenuContainer';
import { CardMobileOption } from '../../../../Card/components/CardMobileOption/CardMobileOption';
import { MobileMenuItemTooltip } from '../../../../MobileMenuItemTooltip/MobileMenuItemTooltip';
import { ItemDetails } from '../../ItemDetails/ItemDetails';
import { useWardrobeProduct } from '../useWardrobeProduct';
import { useLanguage } from '../../../../Translations/LanguageProvider';

export const WardrobeTypeMobile: FC = () => {
    const wardrobeProducts = useSelector(getWardrobeProducts);
    const wardrobeType = useSelector(getWardrobeType);
    const selectedWardrobe = useSelector(getProductByType(wardrobeType));
    const [details, setDetails] = useState<Product | undefined>(undefined);
    const { setWardrobeProduct } = useWardrobeProduct();
    const { currentLanguage } = useLanguage();

    return (
        <>
            <MobileMenuContainer>
                {selectedWardrobe && (
                    <MobileMenuItemTooltip
                        label={selectedWardrobe.name[currentLanguage] || ''}
                        bottomSpacing={180}
                        onClick={() => setDetails(selectedWardrobe)}
                    ></MobileMenuItemTooltip>
                )}
                <div className="w-full flex overflow-x-auto">
                    {wardrobeProducts?.map((product, index) =>
                        availableWardrobeProducts.includes(product.code as any) ? (
                            <div key={index}>
                                <CardMobileOption
                                    isSelected={wardrobeType === product.code}
                                    imgPath={product.images[0]?.path}
                                    onClick={() => setWardrobeProduct(product.code as WardrobeCode)}
                                />
                            </div>
                        ) : null,
                    )}
                </div>
            </MobileMenuContainer>
            {details && (
                <ItemDetails
                    name={`${details.name}`}
                    description={details.description}
                    image={details.images[0]?.path}
                    onClose={() => {
                        setDetails(undefined);
                    }}
                />
            )}
        </>
    );
};
