import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { useSelector } from 'react-redux';
import { getWardrobeDimensions } from '../../../../../store/products/selectors';
import {
    getAdditionalFrameSize,
    getSelectedColumnIndex,
    getVisibleColumn,
    getVisibleColumnWall,
} from '../../../../../store/wardrobe/selectors';
import { MINIMAL_DOOR_SIZE } from './useMeasurementsDepthRange';

export const useMeasurementsWidthRange = (wall: WardrobeWall) => {
    const visibleColumn = useSelector(getVisibleColumn);
    const visibleColumnA = useSelector(getVisibleColumnWall('A'));
    const visibleColumnB = useSelector(getVisibleColumnWall('B'));
    const visibleColumnC = useSelector(getVisibleColumnWall('C'));
    const selectedColumnIndex = useSelector(getSelectedColumnIndex);
    const { singleClosetWidth, doubleClosetWidth, wardrobeWidth } = useSelector(getWardrobeDimensions);
    const { additionalWidth } = useSelector(getAdditionalFrameSize);

    if (singleClosetWidth === null || doubleClosetWidth === null || wardrobeWidth === null) {
        return null;
    }
    const minWidthForFirstB = (visibleColumnA[visibleColumnA.length - 1]?.data.depth || 0) + MINIMAL_DOOR_SIZE;
    const minWidthForLastB = (visibleColumnC[0]?.data.depth || 0) + MINIMAL_DOOR_SIZE;

    if (selectedColumnIndex === null) {
        if (wall === 'B') {
            if (visibleColumnC.length > 0) {
                return {
                    minValue: wardrobeWidth.min + minWidthForFirstB + minWidthForLastB + additionalWidth,
                    maxValue: wardrobeWidth.max,
                };
            }

            return {
                minValue: wardrobeWidth.min + minWidthForFirstB + additionalWidth,
                maxValue: wardrobeWidth.max,
            };
        }

        return {
            minValue: wardrobeWidth.min + additionalWidth,
            maxValue: wardrobeWidth.max,
        };
    }

    const selectedColumn = visibleColumn[selectedColumnIndex];

    if (selectedColumn === undefined) {
        return null;
    }

    if (selectedColumn.wall === 'B') {
        if (visibleColumnB[0] === selectedColumn) {
            return {
                minValue: Math.max(singleClosetWidth.min, minWidthForFirstB),
                maxValue: doubleClosetWidth.max,
            };
        } else if (visibleColumnB[visibleColumnB.length - 1] === selectedColumn && visibleColumnC.length > 0) {
            return {
                minValue: Math.max(singleClosetWidth.min, minWidthForLastB),
                maxValue: doubleClosetWidth.max,
            };
        }
    }

    return {
        minValue: singleClosetWidth.min,
        maxValue: doubleClosetWidth.max,
    };
};
