import styled from 'styled-components';

interface MobileMenuItemTooltipProps {
    bottomSpacing: number;
}

export const MobileMenuItemTooltipStyled = styled.div<MobileMenuItemTooltipProps>`
    position: fixed;
    bottom: ${(props) => `${props.bottomSpacing}px`};
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
`;
