import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
    getAdditionalFrameSize,
    getCurrentWardrobeMeasurements,
    getPreparedSelectedColumn,
} from '../../../../../../store/wardrobe/selectors';
import { Container, Wrapper } from '../MeasurementsMobile/MeasurementsMobile.styled';
import { getWardrobeDimensions } from '../../../../../../store/products/selectors';
import { Slider } from '../../../../../atoms/Slider/Slider';
import { MobileMenuItemTooltip } from '../../../../../MobileMenuItemTooltip/MobileMenuItemTooltip';
import { WARDROBE_HEIGHT_SPACE } from '../../utils';
import { convertValueFromMmToCm } from '../../../../../../store/products/utils';
import { useStore } from 'react-redux';
import { RootState } from '../../../../../../store';
import { setWardrobeDimensions } from '../../../../../../store/wardrobe/actions';
import { useLanguage } from '../../../../../Translations/LanguageProvider';

export const MeasurementsMobileHeightForAllWalls: FC = () => {
    const currentWardrobeMeasurements = useSelector(getCurrentWardrobeMeasurements);
    const { wardrobeHeight } = useSelector(getWardrobeDimensions);
    const currentColumn = useSelector(getPreparedSelectedColumn);
    const measuringElement = currentColumn || currentWardrobeMeasurements;
    const { additionalHeight } = useSelector(getAdditionalFrameSize);
    const { dispatch } = useStore<RootState, any>();
    const { t } = useLanguage();

    if (wardrobeHeight === null) {
        return null;
    }

    return (
        <Container bottomSpacing={105}>
            <Wrapper>
                <MobileMenuItemTooltip
                    label={t('measurement_info_height', 'This closet requires ${value}cm of ceiling height', {
                        value:
                            convertValueFromMmToCm(
                                currentColumn ? measuringElement.height : measuringElement.height + additionalHeight,
                            ) + WARDROBE_HEIGHT_SPACE,
                    })}
                    bottomSpacing={140}
                ></MobileMenuItemTooltip>

                <Slider
                    minValue={wardrobeHeight.min + additionalHeight}
                    maxValue={wardrobeHeight.max}
                    handleChange={(value) => {
                        dispatch(setWardrobeDimensions({ type: 'height', value: value - additionalHeight }));
                    }}
                    unit="cm"
                    value={measuringElement.height + additionalHeight}
                    step={10}
                    valueModify={convertValueFromMmToCm}
                />
            </Wrapper>
        </Container>
    );
};
