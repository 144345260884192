import React, { FC } from 'react';
import { MobileMenuContainer } from '../../../../atoms/MobileMenuContainer/MobileMenuContainer';
import { CardMobileOption } from '../../../../Card/components/CardMobileOption/CardMobileOption';
import { MobileMenuItemTooltip } from '../../../../MobileMenuItemTooltip/MobileMenuItemTooltip';
import { useShelvesOptions } from '../useShelvesOptions';
import { MobileMenuEditComponentProps } from '../../../constants';

export const ShelvesMobile: FC<MobileMenuEditComponentProps> = ({ onOpenDetails }) => {
    const options = useShelvesOptions(onOpenDetails, false);
    const activeOption = options.find((option) => option.isSelected);

    return (
        <MobileMenuContainer>
            {activeOption && (
                <MobileMenuItemTooltip
                    label={activeOption.title}
                    onClick={activeOption.onDetailsClick}
                    bottomSpacing={180}
                ></MobileMenuItemTooltip>
            )}
            <div className="w-full flex justify-center">
                {options.map((option, index) => (
                    <CardMobileOption key={index} {...option} />
                ))}
            </div>
        </MobileMenuContainer>
    );
};
