import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { useSelector } from 'react-redux';
import { getWardrobeDimensions } from '../../../../../store/products/selectors';
import {
    getSelectedColumnIndex,
    getVisibleColumn,
    getVisibleColumnWall,
} from '../../../../../store/wardrobe/selectors';

export const MINIMAL_DOOR_SIZE = 380;

export const useMeasurementsDepthRange = (wall: WardrobeWall) => {
    const visibleColumn = useSelector(getVisibleColumn);
    const visibleColumnA = useSelector(getVisibleColumnWall('A'));
    const visibleColumnB = useSelector(getVisibleColumnWall('B'));
    const visibleColumnC = useSelector(getVisibleColumnWall('C'));
    const selectedColumnIndex = useSelector(getSelectedColumnIndex);
    const { wardrobeDepth } = useSelector(getWardrobeDimensions);

    if (wardrobeDepth === null) {
        return null;
    }

    if (visibleColumnB.length > 0) {
        const maxDepthForA = (visibleColumnB[0]?.data.width || 0) - MINIMAL_DOOR_SIZE;
        const maxDepthForC = (visibleColumnB[visibleColumnB.length - 1]?.data.width || 0) - MINIMAL_DOOR_SIZE;

        if (selectedColumnIndex === null) {
            if (wall === 'A') {
                return {
                    minValue: wardrobeDepth.min,
                    maxValue: Math.min(wardrobeDepth.max, maxDepthForA),
                };
            } else if (wall === 'C') {
                return {
                    minValue: wardrobeDepth.min,
                    maxValue: Math.min(wardrobeDepth.max, maxDepthForC),
                };
            }

            return {
                minValue: wardrobeDepth.min,
                maxValue: wardrobeDepth.max,
            };
        }

        const selectedColumn = visibleColumn[selectedColumnIndex];

        if (selectedColumn !== undefined) {
            if (selectedColumn.wall === 'A' && visibleColumnA[visibleColumnA.length - 1] === selectedColumn) {
                return {
                    minValue: wardrobeDepth.min,
                    maxValue: Math.min(wardrobeDepth.max, maxDepthForA),
                };
            } else if (selectedColumn.wall === 'C' && visibleColumnC[0] === selectedColumn) {
                return {
                    minValue: wardrobeDepth.min,
                    maxValue: Math.min(wardrobeDepth.max, maxDepthForC),
                };
            }
        }
    }

    return {
        minValue: wardrobeDepth.min,
        maxValue: wardrobeDepth.max,
    };
};
