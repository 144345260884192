import styled from 'styled-components';

interface MeasurementsProps {
    bottomSpacing: number;
}

export const Container = styled.div<MeasurementsProps>`
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: ${(props) => `${props.bottomSpacing}px`};
    width: 100%;
    padding: 0 25px;
`;

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
`;
