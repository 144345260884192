import React from 'react';
import { useSelector } from 'react-redux';
import { CardComponent } from '../../Card/components/CardComponent/CardComponent';
import { checkIfColumnAvailableForSplitActionAvailable } from '../../../store/wardrobe/selectors';
import { useMediaByType } from '../../../store/media/selectors';

interface SplittingItemProps {
    onClick(): void;
}

export const SplittingItem = ({ onClick }: SplittingItemProps) => {
    const isColumnAvailableForSplitActionAvailable = useSelector(checkIfColumnAvailableForSplitActionAvailable);
    const splitColumnMedia = useMediaByType('split_columns');

    if (!isColumnAvailableForSplitActionAvailable) {
        return null;
    }

    return (
        <CardComponent
            subtitle="Splitting"
            key="Splitting"
            title=""
            onClick={onClick}
            imgPath={splitColumnMedia.image}
            dataTestId="splitting"
        />
    );
};
