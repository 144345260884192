import React from 'react';
import { useSelector } from 'react-redux';
import { CardComponent } from '../../Card/components/CardComponent/CardComponent';
import { getSelectedShelve } from '../../../store/wardrobe/selectors';
import { useLanguage } from '../../Translations/LanguageProvider';

interface ShelvesItemProps {
    onClick(): void;
}

export const ShelvesItem = ({ onClick }: ShelvesItemProps) => {
    const selectedShelve = useSelector(getSelectedShelve);
    const { currentLanguage } = useLanguage();

    if (!selectedShelve) {
        return null;
    }

    return (
        <CardComponent
            subtitle="Shelves"
            key="Shelves"
            title={selectedShelve.name[currentLanguage] || ''}
            imgPath={selectedShelve.images[0]?.path}
            onClick={onClick}
            dataTestId="shelves"
        />
    );
};
