import { useCallback, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { getProductByType } from '../../../../store/products/selectors';
import { setLed } from '../../../../store/wardrobe/actions';
import { getVisibleColumn, getWardrobeLed } from '../../../../store/wardrobe/selectors';
import { useMediaByType } from '../../../../store/media/selectors';
import { CardOptionProps } from '../../../Card/components/CardOption/CardOption';
import { useLockEffect } from '../../../../hooks/useLockEffect';
import { fetchWardrobePrice } from '../../../../store/configurations/actions';
import { RootState } from '../../../../store';
import { createEmptyWrapper } from '../../../../services/nodes/wardrobe/wardrobe';
import { LedNode } from '../../../../services/nodes/wardrobe/nodes/LedNode';
import { PriceVisitor } from '../../../../services/nodes/wardrobe/visitor/PriceVisitor';
import { SimplifyMedia } from '../../../../store/media/reducer';
import { DetailsContentElement } from '../Details';
import { useCurrency } from '../../../Currency/CurrencyProvider';

export function useLedOptions(
    onOpenDetails: (detailsValues: DetailsContentElement) => void,
    shouldFetchPrice: boolean,
) {
    const ledProduct = useSelector(getProductByType('led'));
    const wardrobeLedIsOn = useSelector(getWardrobeLed);
    const priceService = useSelector((state: RootState) => state.dependency.services?.price);
    const { dispatch, getState } = useStore<RootState, any>();
    const withLightingMedia = useMediaByType('with_lighting');
    const withoutLightingMedia = useMediaByType('without_lighting');
    const [price, setPrice] = useState<[null | number]>([null]);
    const { currentCurrency } = useCurrency();

    const fetchLedPrice = useCallback(async () => {
        if (!shouldFetchPrice) {
            return null;
        }

        if (!priceService) {
            return Promise.reject(new Error('priceService not exist'));
        }

        const state = getState();
        const columns = getVisibleColumn(state);
        const wardrobe = createEmptyWrapper(state);
        const led = getProductByType('led')(state);

        if (wardrobe && led) {
            columns.forEach((column) => {
                wardrobe.addChild(new LedNode({ product: led, height: column.data.height }));
                wardrobe.addChild(new LedNode({ product: led, height: column.data.height }));
            });

            const priceVisitor = new PriceVisitor();
            const natural = priceVisitor.visitWrapperNode(wardrobe);

            return priceService.fetchPrice(natural, currentCurrency);
        }

        return null;
    }, [getState, priceService, shouldFetchPrice, currentCurrency]);

    useLockEffect(
        fetchLedPrice,
        useCallback((woodenData) => {
            if (woodenData !== null) {
                setPrice([woodenData.unitPriceWithChildItems]);
            }
        }, []),
    );

    const showItemDetails = useCallback(
        (media: SimplifyMedia) => {
            onOpenDetails({
                type: 'media',
                props: {
                    details: media,
                },
            });
        },
        [onOpenDetails],
    );

    const setLedHandler = (led: boolean) => () => {
        dispatch(setLed(led));
        dispatch(
            fetchWardrobePrice({
                currencyCode: currentCurrency,
            }),
        );
    };

    const result: CardOptionProps[] = [];

    if (ledProduct) {
        result.push({
            variant: 'text-outside',
            isSelected: wardrobeLedIsOn,
            imgPath: withLightingMedia.image,
            title: withLightingMedia.name,
            dataTestId: 'with_lightning',
            price: price[0] || 0,
            isLoadingPrice: price[0] === null,
            onDetailsClick: () => showItemDetails(withLightingMedia),
            onClick: setLedHandler(true),
        });
    }

    result.push({
        variant: 'text-outside',
        isSelected: !wardrobeLedIsOn,
        imgPath: withoutLightingMedia.image,
        title: withoutLightingMedia.name,
        dataTestId: 'without_lightning',
        price: 0,
        isLoadingPrice: false,
        onDetailsClick: () => showItemDetails(withoutLightingMedia),
        onClick: setLedHandler(false),
    });

    return result;
}
