import React, { FC } from 'react';
import { Button } from '@formify/frontend-components';
import { StickyFooterStyled } from '../../../Sidebar/Sidebar.styled';
import { Container, Wrapper, ItemMobileButtonWrapper } from './ItemDetails.styled';
import { ClearIcon } from '../../../icons/Clear/Clear';
import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { Tr } from '../../../Translations/Tr';

interface SliderDetailsProps {
    onClose: () => void;
    children: React.ReactNode;
}

export const SliderDetails: FC<SliderDetailsProps> = ({ onClose, children }) => {
    const { isMobile } = useBreakpoints();

    return (
        <Container>
            <Wrapper>{children}</Wrapper>
            {isMobile ? (
                <ItemMobileButtonWrapper key="mobile">
                    <Button variant="primary" onClick={onClose} icon={<ClearIcon />} />
                </ItemMobileButtonWrapper>
            ) : (
                <StickyFooterStyled key="desktop">
                    <Button variant="primary" onClick={onClose} full>
                        <Tr defaultValue="Back to my design" labelKey="Back to my design" />
                    </Button>
                </StickyFooterStyled>
            )}
        </Container>
    );
};
