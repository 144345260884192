import styled from 'styled-components';
import { Color } from '../../../constants/Color.enum';

interface TooltipProps {
    isClickable: boolean;
    transparent: boolean;
}

export const TooltipWrapperStyled = styled.div<TooltipProps>`
    padding: 0 16px;
    background-color: ${(props) => (props.transparent ? 'rgba(51, 51, 51, 0.3)' : '#c1c1c1')};
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    border-radius: 36px;
    height: 30px;
    color: ${Color.Surface};
    cursor: ${(props) => (props.isClickable ? 'pointer' : 'initial')};

    &:hover {
        opacity: ${(props) => (props.isClickable ? 0.8 : 1)};
    }
`;

export const TooltipOnHoverContainer = styled.div`
    position: relative;
    > ${TooltipWrapperStyled} {
        position: absolute;
        top: 4px;
        right: calc(100% + 8px);
        pointer-events: none;
        opacity: 0;
        transition: all 0.2s ease-in-out;
        transform: translateX(10%);
        white-space: nowrap;
    }

    &:hover {
        > ${TooltipWrapperStyled} {
            opacity: 1;
            pointer-events: all;
            transform: translateX(0);
        }
    }
`;

export const TextStyled = styled.div`
    font-size: 12px;
    line-height: initial;
`;
