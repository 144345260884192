import React, { FC } from 'react';
import { Button } from '@formify/frontend-components';
import { Tooltip } from '../../../atoms/Tooltip/Tooltip';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getPreparedSelectedColumn } from '../../../../store/wardrobe/selectors';
import { MobileMenuEditComponentProps } from '../../constants';
import { useAllSplitActions } from './useAllSplitActions';

const Container = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 94px;
    width: 100%;
    padding: 0 25px;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
    justify-content: center;
`;

export const SplitActionsMobile: FC<MobileMenuEditComponentProps> = ({ onOpenDetails }) => {
    const actions = useAllSplitActions(onOpenDetails, false);
    const selectedColumn = useSelector(getPreparedSelectedColumn);

    return (
        <Container>
            <Wrapper>
                {actions.length === 0 && (
                    <Tooltip text={selectedColumn === null ? 'Tap a closet to edit' : 'Actions not available'} />
                )}

                {actions.map((action) => (
                    <Button variant="subtle" key={action.title} onClick={action.onClick}>
                        {action.title}
                    </Button>
                ))}
            </Wrapper>
        </Container>
    );
};
