import React from 'react';
import { useSelector } from 'react-redux';
import { CardComponent } from '../../Card/components/CardComponent/CardComponent';
import { getWardrobeLed } from '../../../store/wardrobe/selectors';
import { useMediaByType } from '../../../store/media/selectors';

interface LightingItemProps {
    onClick(): void;
}

export const LightingItem = ({ onClick }: LightingItemProps) => {
    const withLightingMedia = useMediaByType('with_lighting');
    const withoutLightingMedia = useMediaByType('without_lighting');
    const wardrobeLedIsOn = useSelector(getWardrobeLed);

    return (
        <CardComponent
            subtitle="Lighting"
            key="Led"
            title={wardrobeLedIsOn ? withLightingMedia.name : withoutLightingMedia.name}
            onClick={onClick}
            imgPath={wardrobeLedIsOn ? withLightingMedia.image : withoutLightingMedia.image}
            dataTestId="led"
        />
    );
};
