import React, { FC } from 'react';
import { CardOption } from '../../../Card/components/CardOption/CardOption';
import { SidePanelEditComponentProps } from '../../constants';
import { TwoColumnCardContainer } from '../../../Card/components/CardOption/CardOption.styled';
import { useDoorOptions } from './useDoorOptions';

type DoorOptionsProps = SidePanelEditComponentProps;

export const DoorOptions: FC<DoorOptionsProps> = ({ isOpen = true, onOpenDetails }) => {
    const options = useDoorOptions(onOpenDetails, isOpen);

    return (
        <TwoColumnCardContainer data-test="two_column_card_container">
            {options.map((option) => (
                <CardOption {...option} key={option.title} />
            ))}
        </TwoColumnCardContainer>
    );
};
