import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { getDefaultProjectSize, getProject } from '../services/project/project';
import { WardrobeBuilderFromProject } from '../services/nodes/wardrobe/builders/WardrobeBuilderFromProject';
import { getAllProducts } from '../services/nodes/wardrobe/wardrobe';
import { RootState } from '../store';
import { setLazyWidth, setVisibility } from '../store/wardrobe/actions';
import { useWardrobeDispatch } from '@formify/frontend-wardrobe-renderer/dist/modules/context';
import { setCameraStateAction } from '@formify/frontend-wardrobe-renderer/dist/modules/actions';
import { mainRendererContext } from '../rendererContexts/mainRendererContext';

export const useLoadProject = () => {
    const { getState, dispatch } = useStore<RootState, any>();
    const dispatchRenderer = useWardrobeDispatch(mainRendererContext);

    return useCallback(
        async (projectId: string) => {
            const state = getState();
            const formattedProducts = getAllProducts(state);

            const [project, wardrobeSize] = await Promise.all([
                getProject(projectId).catch(() => null),
                projectId === 'default' ? getDefaultProjectSize() : 0,
            ]);

            if (formattedProducts !== null && project !== null) {
                const builder = new WardrobeBuilderFromProject(formattedProducts);

                builder.getActionForStore(project).forEach((action) => {
                    dispatch(action);
                });

                if (projectId === 'default') {
                    dispatch(setLazyWidth(wardrobeSize));
                    dispatch(setVisibility(3));
                }

                const wardrobeType = builder.getWardrobeType(project);

                if (wardrobeType === 'wardrobe' || wardrobeType === 'wardrobe_free_standing') {
                    dispatchRenderer(
                        setCameraStateAction({
                            type: 'singleWall',
                            position: 'center',
                        }),
                    );
                } else {
                    dispatchRenderer(
                        setCameraStateAction({
                            type: 'uShape',
                            position: 'center',
                        }),
                    );
                }
            }
        },
        [dispatch, getState],
    );
};
