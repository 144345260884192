import React, { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    checkAlignHightVisible,
    getAdditionalFrameSize,
    getCurrentWardrobeMeasurementsWall,
    getPreparedSelectedColumn,
    getWallAtPosition,
} from '../../../../store/wardrobe/selectors';
import { TextXS } from '@formify/frontend-components';
import { DimensionsParams, useDimensions } from '../../../../hooks/useDimensions';
import { convertValueFromCmToMm, convertValueFromMmToCm, OptionDimensions } from '../../../../store/products/utils';
import { useMediaByType } from '../../../../store/media/selectors';
import { InfoIcon } from '../../../icons/Info/Info';
import { BIG_DEPTH, SMALL_DEPTH, WARDROBE_HEIGHT_SPACE } from './utils';
import { Button } from '@formify/frontend-components';
import { SidePanelEditComponentProps } from '../../constants';
import { TooltipDesktopWrapper } from '../Measurements.styled';
import { SubtitleXS } from '@formify/frontend-components';
import { MultipleToggle } from '../../../atoms/Toggle/MultipleToggle';
import { UnitStyled, ValueStyled } from '@formify/frontend-components/dist/components/Toggle/Toggle.styled';
import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { useMeasurementsDepthRange } from './hooks/useMeasurementsDepthRange';
import { WidthMeasurementSlider } from './components/WidthMeasurementSlider/WidthMeasurementSlider';
import { HeightMeasurementSlider } from './components/HeightMeasurementSlider/HeightMeasurementSlider';
import { DepthMeasurementSlider } from './components/DepthMeasurementSlider/DepthMeasurementSlider';
import { Tr } from '../../../Translations/Tr';
import { useLanguage } from '../../../Translations/LanguageProvider';
import { SliderGroupStyled } from '../../../atoms/Slider/Slider';

type MeasurementsProps = SidePanelEditComponentProps;

type ActiveMeasurementType = keyof OptionDimensions | null;

export const Measurements: FC<MeasurementsProps> = ({ isOpen = true, onOpenDetails, onChangeVisibilitySubDetails }) => (
    <div>
        <SpecificMeasurements
            isOpen={isOpen}
            onOpenDetails={onOpenDetails}
            wall="A"
            onChangeVisibilitySubDetails={onChangeVisibilitySubDetails}
        />
    </div>
);

export const MeasurementsCreator = (wallIndex: number): FC<MeasurementsProps> =>
    function MeasurementsWall({ isOpen = true, onOpenDetails, onChangeVisibilitySubDetails }) {
        const wall = useSelector(getWallAtPosition(wallIndex)) || 'A';

        return (
            <SpecificMeasurements
                isOpen={isOpen}
                onOpenDetails={onOpenDetails}
                wall={wall}
                onChangeVisibilitySubDetails={onChangeVisibilitySubDetails}
            />
        );
    };

interface SpecificMeasurementsProps extends SidePanelEditComponentProps {
    wall: WardrobeWall;
}

const SpecificMeasurements: FC<SpecificMeasurementsProps> = ({ isOpen = true, onOpenDetails, wall }) => {
    const { t } = useLanguage();
    const alignHightVisible = useSelector(checkAlignHightVisible);
    const [activeMeasurementType, setActiveMeasurementType] = useState<ActiveMeasurementType>(null);
    const measurementsDepthRange = useMeasurementsDepthRange(wall);
    const currentWardrobeMeasurements = useSelector(getCurrentWardrobeMeasurementsWall(wall));
    const selectedColumn = useSelector(getPreparedSelectedColumn);
    const { onChange, alignHeight } = useDimensions(wall);
    const measuringElement = selectedColumn || currentWardrobeMeasurements;

    const [showCustomDepth, setShowCustomDepth] = useState(
        !(measuringElement.depth === SMALL_DEPTH || measuringElement.depth === BIG_DEPTH),
    );

    const depthMediaMin = useMediaByType('43cm_depth');
    const depthMediaMax = useMediaByType('58cm_depth');

    const onMeasurementOptionChange = ({ type, value }: DimensionsParams) => {
        onChange({ type, value });
        setActiveMeasurementType(type);
    };

    const depthMedia = SMALL_DEPTH >= measuringElement.depth ? depthMediaMin : depthMediaMax;

    const onDepthOptionClick = useCallback(() => {
        onOpenDetails({
            type: 'media',
            props: {
                details: depthMedia,
                showImage: false,
            },
        });
    }, [depthMedia, onOpenDetails]);

    const { additionalHeight, additionalWidth } = useSelector(getAdditionalFrameSize);
    const additionalWidthNumber = selectedColumn ? 0 : additionalWidth;

    if (measurementsDepthRange === null) {
        return null;
    }

    return (
        <>
            <SubtitleXS>
                <TooltipDesktopWrapper className="flex items-center justify-center">
                    {activeMeasurementType === 'width' && (
                        <span>
                            <Tr
                                labelKey="measurement_info_width"
                                defaultValue="Width: ${value}cm"
                                variables={{
                                    value: convertValueFromMmToCm(measuringElement.width + additionalWidthNumber),
                                }}
                            />
                        </span>
                    )}
                    {activeMeasurementType === 'height' && (
                        <span>
                            <Tr
                                labelKey="measurement_info_height"
                                defaultValue="This closet requires ${value}cm of ceiling height"
                                variables={{
                                    value:
                                        convertValueFromMmToCm(measuringElement.height + additionalHeight) +
                                        WARDROBE_HEIGHT_SPACE,
                                }}
                            />
                        </span>
                    )}
                    {activeMeasurementType === 'depth' && (
                        <div className="flex items-center justify-center">
                            <div
                                className="mr-6 cursor-pointer"
                                onClick={onDepthOptionClick}
                                style={{ color: '#CB444B' }}
                            >
                                <InfoIcon size="16" />
                            </div>
                            {depthMedia.name}
                        </div>
                    )}
                </TooltipDesktopWrapper>
            </SubtitleXS>

            <div className="pt-14 pb-32">
                {isOpen && (
                    <>
                        <SliderGroupStyled>
                            <TextXS>
                                <Tr defaultValue="Width" labelKey="Width" />
                            </TextXS>
                            <div>
                                <WidthMeasurementSlider
                                    wall={wall}
                                    label=""
                                    onChange={() => {
                                        setActiveMeasurementType('width');
                                    }}
                                />
                            </div>
                            <TextXS>
                                <Tr defaultValue="Height" labelKey="Height" />
                            </TextXS>
                            <div>
                                <HeightMeasurementSlider
                                    wall={wall}
                                    label=""
                                    onChange={() => {
                                        setActiveMeasurementType('height');
                                    }}
                                />
                            </div>
                        </SliderGroupStyled>
                        <div className="flex justify-between mb-48 mt-24">
                            <div className="mr-20 flex items-center">
                                <TextXS>
                                    <Tr labelKey="depth" defaultValue="Depth" />
                                </TextXS>
                            </div>

                            <MultipleToggle<number | 'custom'>
                                dataTestId="toggle-depth"
                                values={(() => {
                                    const results: (number | 'custom')[] = [];

                                    if (measurementsDepthRange.maxValue >= SMALL_DEPTH) {
                                        results.push(convertValueFromMmToCm(SMALL_DEPTH));
                                    }

                                    if (measurementsDepthRange.maxValue >= BIG_DEPTH) {
                                        results.push(convertValueFromMmToCm(BIG_DEPTH));
                                    }

                                    results.push('custom');

                                    return results;
                                })()}
                                render={(value) => (
                                    <>
                                        <ValueStyled>{value === 'custom' ? t('Custom') : value}</ValueStyled>

                                        {value !== 'custom' && <UnitStyled className="ml-2">cm</UnitStyled>}
                                    </>
                                )}
                                selectedValue={
                                    showCustomDepth ? 'custom' : convertValueFromMmToCm(measuringElement.depth)
                                }
                                small
                                onSelect={(value) => {
                                    if (value !== 'custom') {
                                        onMeasurementOptionChange({
                                            type: 'depth',
                                            value: convertValueFromCmToMm(value),
                                        });
                                    }
                                    setShowCustomDepth(value === 'custom');
                                }}
                            />
                        </div>

                        {showCustomDepth && (
                            <div className="mb-48">
                                <DepthMeasurementSlider
                                    label={t('Custom')}
                                    wall={wall}
                                    onChange={() => {
                                        setActiveMeasurementType('depth');
                                    }}
                                />
                            </div>
                        )}

                        <div hidden={alignHightVisible}>
                            <div className="flex justify-between">
                                <div className="mr-160 flex items-center">
                                    <TextXS>Align</TextXS>
                                </div>
                                <Button
                                    variant="subtle"
                                    onClick={() => {
                                        alignHeight();
                                    }}
                                >
                                    Height
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
