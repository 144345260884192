import React, { FC, useCallback, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { getWardrobeType } from '../../../../store/wardrobe/selectors';
import { CardOption } from '../../../Card/components/CardOption/CardOption';
import { useLockEffect } from '../../../../hooks/useLockEffect';
import { RootState } from '../../../../store';
import { createWardrobeStructureBasedOnState, getAllProducts } from '../../../../services/nodes/wardrobe/wardrobe';
import { PriceVisitor } from '../../../../services/nodes/wardrobe/visitor/PriceVisitor';
import { WardrobeCode, availableWardrobeProducts } from '../../../../services/products/domain/ProductCodes';
import { SidePanelEditComponentProps } from '../../constants';
import { TwoColumnCardContainer } from '../../../Card/components/CardOption/CardOption.styled';
import { WardrobeColumn, fixCornerConfiguration } from '../../../../store/wardrobe/reducer';
import { WardrobeBuilder } from '../../../../services/nodes/wardrobe/builders/WardrobeBuilder';
import { crateWardrobeColumnByType, useWardrobeProduct } from './useWardrobeProduct';
import { sortColumnByWallName } from '../../../../store/wardrobe/helpers';
import { useLanguage } from '../../../Translations/LanguageProvider';
import { useCurrency } from '../../../Currency/CurrencyProvider';

type WardrobeTypeProps = SidePanelEditComponentProps;

type WardrobeTypePrice = {
    [key in WardrobeCode]: number | null;
};
const DEFAULT_PRICE: WardrobeTypePrice = {
    l_shaped_wardrobe_left: null,
    l_shaped_wardrobe_right: null,
    u_shaped_wardrobe: null,
    wardrobe: null,
    wardrobe_free_standing: null,
};

export const WardrobeType: FC<WardrobeTypeProps> = ({ isOpen = true, onOpenDetails }) => {
    const allProducts = useSelector(getAllProducts);
    const wardrobeType = useSelector(getWardrobeType);
    const wardrobeNode = useSelector(createWardrobeStructureBasedOnState);
    const priceService = useSelector((state: RootState) => state.dependency.services?.price);
    const { getState } = useStore<RootState, any>();
    const [price, setPrice] = useState<WardrobeTypePrice>(DEFAULT_PRICE);
    const { currentCurrency } = useCurrency();

    const fetchWardrobeEmbeddedPrice = useCallback(async () => {
        if (!isOpen) {
            return DEFAULT_PRICE;
        }

        if (!priceService) {
            return Promise.reject(new Error('priceService not exist'));
        }

        const state = getState();
        const products = getAllProducts(state);

        if (wardrobeNode && products) {
            const wardrobeParams = wardrobeNode.getParams();
            const priceVisitor = new PriceVisitor();

            const requests = availableWardrobeProducts.map(async (code) => {
                const builder = new WardrobeBuilder(
                    products,
                    wardrobeParams.led,
                    state.configurations.allConfigurations,
                );

                const columns: WardrobeColumn[] = sortColumnByWallName(crateWardrobeColumnByType(state, code));

                fixCornerConfiguration(columns, state.wardrobe);

                columns.forEach((column) => {
                    builder.addColumn(column);
                });

                const wardrobe = builder.getWardrobe(
                    {
                        material: wardrobeParams.material,
                    },
                    code,
                );

                const priceParams = priceVisitor.visitWardrobeNode(wardrobe);

                return {
                    code,
                    data: await priceService.fetchPrice(priceParams, currentCurrency),
                };
            });

            return Promise.all(requests).then((data) =>
                data.reduce(
                    (acc, { data, code }) => {
                        acc[code] = data.unitPriceWithChildItems;

                        return acc;
                    },
                    { ...DEFAULT_PRICE },
                ),
            );
        }

        return DEFAULT_PRICE;
    }, [getState, priceService, isOpen, wardrobeNode, currentCurrency]);

    useLockEffect(fetchWardrobeEmbeddedPrice, setPrice);

    const { setWardrobeProduct } = useWardrobeProduct();
    const { currentLanguage } = useLanguage();

    return (
        <TwoColumnCardContainer data-test="two_column_card_container">
            {allProducts &&
                availableWardrobeProducts.map((productCode) => {
                    const product = allProducts[productCode];

                    return (
                        <CardOption
                            key={productCode}
                            dataTestId={product.code}
                            variant="text-outside"
                            isSelected={wardrobeType === product.code}
                            imgPath={product.images[0]?.path}
                            title={product.name[currentLanguage] || ''}
                            price={price[productCode] || 0}
                            isLoadingPrice={price[productCode] === null}
                            onDetailsClick={() =>
                                onOpenDetails({
                                    type: 'product',
                                    props: {
                                        product: product,
                                    },
                                })
                            }
                            onClick={() => setWardrobeProduct(productCode)}
                        />
                    );
                })}
        </TwoColumnCardContainer>
    );
};
