import { useSelector } from 'react-redux';
import { MediaType } from '../../services/media/domain/MediaType';
import type { RootState } from '../index';
import { Media, SimplifyMedia } from './reducer';
import { useLanguage } from '../../components/Translations/LanguageProvider';

const defaultEmptyMedia = {
    description: '',
    image: '',
    name: '',
};

const getMediaByType =
    (type: MediaType) =>
    (state: RootState): Exclude<Media, undefined> | undefined =>
        state.media[type];

export const useMediaByType = (type: MediaType): SimplifyMedia => {
    const { currentLanguage } = useLanguage();
    const value = useSelector(getMediaByType(type));

    return value
        ? {
              ...value,
              name: value.name[currentLanguage] || '',
              description: value.description[currentLanguage] || '',
          }
        : defaultEmptyMedia;
};
