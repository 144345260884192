import React, { FC } from 'react';
import { Tooltip } from '../atoms/Tooltip/Tooltip';
import { InfoIcon } from '../icons/Info/Info';
import { MobileMenuItemTooltipStyled } from './MobileMenuItemTooltip.styled';

export interface MobileMenuItemTooltipProps {
    label: string;
    bottomSpacing: number;
    onClick?: () => void;
}

export const MobileMenuItemTooltip: FC<MobileMenuItemTooltipProps> = ({ label, bottomSpacing, onClick }) => (
    <MobileMenuItemTooltipStyled bottomSpacing={bottomSpacing}>
        <Tooltip text={label}>
            {onClick && (
                <div className="ml-6" onClick={onClick}>
                    <InfoIcon />
                </div>
            )}
        </Tooltip>
    </MobileMenuItemTooltipStyled>
);
