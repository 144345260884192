import React, { FC, useEffect } from 'react';
import { ItemWrapperStyled } from './LayoutItem.styled';
import { useSelector, useDispatch } from 'react-redux';
import { getConfigurationVariant, getSelectedColumnIndex } from '../../../../../../store/wardrobe/selectors';
import {
    getConfiguration,
    getConfigurationPrice,
    getIsFetchingConfigurationPrice,
} from '../../../../../../store/configurations/selectors';
import { fetchColumnConfigurationPrice } from '../../../../../../store/configurations/actions';
import { CardOption } from '../../../../../Card/components/CardOption/CardOption';
import { useCurrency } from '../../../../../Currency/CurrencyProvider';

export interface LayoutItemProps {
    configId: string;
    isSelected: boolean;
    handleDetailsClick: () => void;
    onClick: () => void;
    isOpen?: boolean;
}

export function useLayoutScreen(configId: string) {
    const item = useSelector(getConfigurationVariant(configId));

    return item?.image || '';
}

export const LayoutItem: FC<LayoutItemProps> = ({ configId, isSelected, handleDetailsClick, onClick, isOpen }) => {
    const dispatch = useDispatch<any>();
    const configuration = useSelector(getConfiguration(configId));
    const item = useSelector(getConfigurationVariant(configId));
    const price = useSelector(getConfigurationPrice(configId));
    const columnIndex = useSelector(getSelectedColumnIndex);
    const isLoadingPrice = useSelector(getIsFetchingConfigurationPrice(configId));
    const { currentCurrency } = useCurrency();

    useEffect(() => {
        if (isOpen && columnIndex !== null) {
            dispatch(fetchColumnConfigurationPrice({ configId, columnIndex, currencyCode: currentCurrency }));
        }
    }, [columnIndex, configId, dispatch, isOpen, currentCurrency]);

    const path = useLayoutScreen(configId);

    if (item === null || configuration === null) {
        return null;
    }

    return (
        <ItemWrapperStyled>
            <CardOption
                dataTestId={`layout_id_${configuration.hash}`}
                variant="text-outside"
                isSelected={isSelected}
                imgPath={path}
                title={item.name}
                price={price || 0}
                isLoadingPrice={isLoadingPrice}
                onDetailsClick={handleDetailsClick}
                onClick={onClick}
            />
        </ItemWrapperStyled>
    );
};
