import { useCallback, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { getProductByType, getShelveProducts } from '../../../../store/products/selectors';
import {
    getSelectedColumn,
    getSelectedColumnIndex,
    getSelectedShelve,
    getSelectedShelveForWardrobe,
} from '../../../../store/wardrobe/selectors';
import { CardOptionProps } from '../../../Card/components/CardOption/CardOption';
import { ShelveCode } from '../../../../services/products/domain/ProductCodes';
import { Product } from '../../../../types/Product';
import { useLockEffect } from '../../../../hooks/useLockEffect';
import { useShelves } from '../../../../hooks/useShelves';
import { createEmptyWrapper, createWardrobeStructureBasedOnState } from '../../../../services/nodes/wardrobe/wardrobe';
import { GlassShelveNode } from '../../../../services/nodes/wardrobe/nodes/GlassShelveNode';
import { ShelveNode } from '../../../../services/nodes/wardrobe/nodes/ShelveNode';
import { ColumnNode } from '../../../../services/nodes/wardrobe/nodes/ColumnNode';
import { PriceVisitor } from '../../../../services/nodes/wardrobe/visitor/PriceVisitor';
import { RootState } from '../../../../store';
import { DetailsContentElement } from '../Details';
import { useLanguage } from '../../../Translations/LanguageProvider';
import { useCurrency } from '../../../Currency/CurrencyProvider';

export const useShelvesOptions = (
    onOpenDetails: (detailsValues: DetailsContentElement) => void,
    shouldFetchPrice: boolean,
) => {
    const shelveProducts = useSelector(getShelveProducts);
    const selectedShelve = useSelector(getSelectedShelve);
    const selectedShelveForWardrobe = useSelector(getSelectedShelveForWardrobe);
    const selectedColumn = useSelector(getSelectedColumn);
    const selectedColumnIndex = useSelector(getSelectedColumnIndex);
    const priceService = useSelector((state: RootState) => state.dependency.services?.price);
    const { getState } = useStore<RootState>();
    const [prices, setPrices] = useState<[number | null, number | null]>([null, null]);
    const [glassShelvePrice, defaultShelvePrice] = prices;
    const { assignShelve } = useShelves();
    const { currentCurrency } = useCurrency();

    const fetchShelvePrices = useCallback<() => Promise<[number | null, number | null]>>(() => {
        if (!shouldFetchPrice) {
            return Promise.resolve([null, null]);
        }
        const state = getState();
        const wardrobe = createWardrobeStructureBasedOnState(state);
        const shelveProduct = getProductByType('shelve')(state);
        const glassShelveProduct = getProductByType('glass_shelve')(state);

        if (wardrobe === null || !shelveProduct || !glassShelveProduct) {
            return Promise.resolve([null, null]);
        }

        if (!priceService) {
            return Promise.reject(new Error('priceService not exist'));
        }

        const allColumn = wardrobe.findChildrenByInstance([ColumnNode]);
        const selectedColumn = selectedColumnIndex ? allColumn[selectedColumnIndex] : undefined;
        const { material } = wardrobe.getParams();

        const allShelve = (selectedColumn ? selectedColumn : wardrobe).findChildrenByInstance<
            GlassShelveNode | ShelveNode
        >([GlassShelveNode, ShelveNode]);

        const formattedShelveWardrobe = createEmptyWrapper(state);
        const formattedGlassShelveWardrobe = createEmptyWrapper(state);

        if (!material) {
            return Promise.resolve([null, null]);
        }

        allShelve.forEach((node) => {
            const params = node.getParams();

            formattedShelveWardrobe.addChild(
                new ShelveNode({
                    ...params,
                    product: shelveProduct,
                    material: material,
                    box_height: 150,
                }),
            );

            formattedGlassShelveWardrobe.addChild(
                new GlassShelveNode({
                    ...params,
                    product: glassShelveProduct,
                    box_height: 150,
                }),
            );
        });

        const priceVisitor = new PriceVisitor();

        setPrices([null, null]);

        return Promise.all([
            priceService.fetchPrice(priceVisitor.visitWrapperNode(formattedGlassShelveWardrobe), currentCurrency),
            priceService.fetchPrice(priceVisitor.visitWrapperNode(formattedShelveWardrobe), currentCurrency),
        ]).then(([glassShelveResponse, defaultShelveResponse]) => [
            glassShelveResponse.unitPriceWithChildItems,
            defaultShelveResponse.unitPriceWithChildItems,
        ]);
    }, [shouldFetchPrice, getState, priceService, selectedColumnIndex, currentCurrency]);

    useLockEffect(fetchShelvePrices, setPrices);

    const showItemDetails = useCallback(
        (product: Product) => {
            onOpenDetails({
                type: 'product',
                props: {
                    product,
                },
            });
        },
        [onOpenDetails],
    );

    const getShelvePriceByProductCode = (code: string) => {
        if (code === 'glass_shelve') {
            return glassShelvePrice;
        }

        return defaultShelvePrice;
    };

    const isShelveSelected = (code: string) =>
        (selectedColumn && selectedShelve?.code === code) ||
        (!selectedColumn && selectedShelveForWardrobe?.code === code);

    const { currentLanguage } = useLanguage();

    return (
        shelveProducts?.map((shelveProduct): CardOptionProps => {
            const price = getShelvePriceByProductCode(shelveProduct.code);

            return {
                isLoadingPrice: price === null,
                isSelected: isShelveSelected(shelveProduct.code),
                variant: 'text-outside',
                imgPath: shelveProduct.images[0]?.path,
                title: shelveProduct.name[currentLanguage] || '',
                price: price || 0,
                dataTestId: shelveProduct.code,
                onDetailsClick: () => showItemDetails(shelveProduct),
                onClick: assignShelve(shelveProduct.code as ShelveCode),
            };
        }) || []
    );
};
