import { useCallback, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import {
    getSelectedDoorProductForWardrobe,
    getProductByType,
    getDoorHandleProductsBaseOnDoor,
} from '../../../../store/products/selectors';
import { assignDoor } from '../../../../store/wardrobe/actions';
import { getSelectedColumn, getSelectedColumnIndex, getVisibleColumn } from '../../../../store/wardrobe/selectors';
import { useMediaByType } from '../../../../store/media/selectors';
import { CardOptionProps } from '../../../Card/components/CardOption/CardOption';
import { getColumnProductCodeByWidth } from '../../../../store/configurations/selectors';
import { Product } from '../../../../types/Product';
import { useLockEffect } from '../../../../hooks/useLockEffect';
import { fetchWardrobePrice } from '../../../../store/configurations/actions';
import { RootState } from '../../../../store';
import { DoorHandleCode, isDoorHandleCode } from '../../../../services/products/domain/ProductCodes';
import { WardrobeColumn } from '../../../../store/wardrobe/reducer';
import { DoorNode } from '../../../../services/nodes/wardrobe/nodes/DoorNode';
import { createEmptyWrapper } from '../../../../services/nodes/wardrobe/wardrobe';
import { PriceVisitor } from '../../../../services/nodes/wardrobe/visitor/PriceVisitor';
import { SimplifyMedia } from '../../../../store/media/reducer';
import { DetailsContentElement } from '../Details';
import { useCurrency } from '../../../Currency/CurrencyProvider';

const useDoorOption = () => {
    const { getState, dispatch } = useStore<RootState, any>();
    const { currentCurrency } = useCurrency();

    const setDoor = (door: Product | null) => () => {
        const state = getState();
        const doorHandleProducts = getDoorHandleProductsBaseOnDoor(door ? [door] : [])(state);

        dispatch(
            assignDoor({
                door,
                availableHandles: doorHandleProducts.reduce<DoorHandleCode[]>((acc, { code }) => {
                    if (isDoorHandleCode(code)) {
                        acc.push(code);
                    }

                    return acc;
                }, []),
            }),
        );

        dispatch(
            fetchWardrobePrice({
                currencyCode: currentCurrency,
            }),
        );
    };

    return { setDoor };
};

const createDoorsNodeFromColumn =
    (type: 'door' | 'door_glass', column: WardrobeColumn) =>
    (state: RootState): DoorNode[] => {
        const columnWidth = column.data.width;
        const code = getColumnProductCodeByWidth(columnWidth)(state);
        const doorProduct = getProductByType(type)(state);

        if (!doorProduct) {
            return [];
        }

        return new Array(code === 'double_closet' ? 2 : 1).fill(
            new DoorNode({
                material: column.color,
                position: 'RIGHT',
                product: doorProduct,
                width: code === 'double_closet' ? columnWidth / 2 : columnWidth,
                height: column.data.height,
            }),
        );
    };

const createDoorNode =
    (type: 'door' | 'door_glass') =>
    (state: RootState): DoorNode[] => {
        const selectedIndex = getSelectedColumnIndex(state);

        if (selectedIndex !== null) {
            const selectedRawColumn = getSelectedColumn(state);

            if (selectedRawColumn !== null) {
                return createDoorsNodeFromColumn(type, selectedRawColumn)(state);
            }
        }

        const columns = getVisibleColumn(state);

        return columns.reduce<DoorNode[]>((sum, column) => {
            sum.push(...createDoorsNodeFromColumn(type, column)(state));

            return sum;
        }, []);
    };

export const useDoorOptions = (onOpenDetails: (detailsValues: DetailsContentElement) => void, isOpen: boolean) => {
    const door = useSelector(getProductByType('door'));
    const door_glass = useSelector(getProductByType('door_glass'));
    const selectedDoorProduct = useSelector(getSelectedDoorProductForWardrobe);
    const priceService = useSelector((state: RootState) => state.dependency.services?.price);
    const { getState } = useStore<RootState, any>();
    const withDoorMedia = useMediaByType('with_doors');
    const glassDoorMedia = useMediaByType('glass_doors');
    const withoutDoorMedia = useMediaByType('without_doors');
    const [price, setPrice] = useState<[null | number, null | number]>([null, null]);
    const { currentCurrency } = useCurrency();

    const fetchDoorPrice = useCallback(() => {
        if (!isOpen) {
            return Promise.resolve(null);
        }

        if (!priceService) {
            return Promise.reject(new Error('priceService not exist'));
        }

        const state = getState();
        const priceVisitor = new PriceVisitor();
        const wardrobeWithWoodenDoors = createEmptyWrapper(state);

        wardrobeWithWoodenDoors.addChildren(createDoorNode('door')(state));

        const wardrobeWithGlassDoors = createEmptyWrapper(state);

        wardrobeWithGlassDoors.addChildren(createDoorNode('door_glass')(state));

        setPrice([null, null]);

        return Promise.all([
            priceService.fetchPrice(priceVisitor.visitWrapperNode(wardrobeWithWoodenDoors), currentCurrency),
            priceService.fetchPrice(priceVisitor.visitWrapperNode(wardrobeWithGlassDoors), currentCurrency),
        ]);
    }, [isOpen, getState, priceService, currentCurrency]);

    useLockEffect(
        fetchDoorPrice,
        useCallback((data) => {
            if (data !== null) {
                const [woodenData, glassData] = data;

                setPrice([woodenData.unitPriceWithChildItems, glassData.unitPriceWithChildItems]);
            }
        }, []),
    );

    const showItemDetails = useCallback(
        (media: SimplifyMedia) => {
            onOpenDetails({
                type: 'media',
                props: {
                    details: media,
                },
            });
        },
        [onOpenDetails],
    );

    const { setDoor } = useDoorOption();
    const result: CardOptionProps[] = [];

    if (door) {
        result.push({
            variant: 'text-outside',
            isSelected: selectedDoorProduct?.code === door?.code,
            imgPath: withDoorMedia.image,
            title: withDoorMedia.name,
            dataTestId: 'wooden_doors',
            price: price[0] || 0,
            isLoadingPrice: price[0] === null,
            onDetailsClick: () => showItemDetails(withDoorMedia),
            onClick: setDoor(door),
        });
    }

    if (door_glass) {
        result.push({
            variant: 'text-outside',
            isSelected: selectedDoorProduct?.code === door_glass?.code,
            imgPath: glassDoorMedia.image,
            title: glassDoorMedia.name,
            dataTestId: 'glass_doors',
            price: price[1] || 0,
            isLoadingPrice: price[1] === null,
            onDetailsClick: () => showItemDetails(glassDoorMedia),
            onClick: setDoor(door_glass),
        });
    }

    result.push({
        variant: 'text-outside',
        isSelected: selectedDoorProduct === null,
        imgPath: withoutDoorMedia.image,
        title: withoutDoorMedia.name,
        dataTestId: 'without_doors',
        price: 0,
        onDetailsClick: () => showItemDetails(withoutDoorMedia),
        onClick: setDoor(null),
    });

    return result;
};
