import React, { FC, useEffect, useState } from 'react';
import { ALL_OPTIONS, OptionName, OPTION_ORDER, SidePanelEditComponentProps } from '../../constants';
import { useAvailableOptions } from '../../../../hooks/useVisibleOptions';
import { StickyFooterStyled, TitleWrapperStyledSmall } from '../../../Sidebar/Sidebar.styled';
import { LinkXS } from '@formify/frontend-components';
import { SidebarPanelTabs } from '../SidebarPanelTabs/SidebarPanelTabs';
import { ContainerForCardComponentWrapper } from '../../../Card/components/CardComponent/CardComponent.styled';
import {
    hideDoors,
    hideInnerElements,
    showDoors,
    showInnerElements,
} from '@formify/frontend-wardrobe-renderer/dist/modules/actions';
import { useWardrobeDispatch } from '@formify/frontend-wardrobe-renderer/dist/modules/context';
import { mainRendererContext } from '../../../../rendererContexts/mainRendererContext';
import { IconWrapperLeft } from '../ColumnOption/ColumnOption';
import { Button } from '@formify/frontend-components';
import { ChevronLeftIcon } from '../../../icons/ChevronLeft/ChevronLeft';
import { Tr } from '../../../Translations/Tr';

type WardrobeOptionsProps = SidePanelEditComponentProps;

export const WardrobeOptions: FC<WardrobeOptionsProps> = ({ onOpenDetails, onChangeVisibilitySubDetails }) => {
    const allOptionVisibilityStatus = useAvailableOptions();
    const [details, setDetails] = useState<Exclude<OptionName, 'main' | 'summary'> | null>(null);
    const dispatchRenderer = useWardrobeDispatch(mainRendererContext);

    useEffect(() => {
        onChangeVisibilitySubDetails(details !== null);

        return () => onChangeVisibilitySubDetails(false);
    }, [details, onChangeVisibilitySubDetails]);

    if (details !== null) {
        return (
            <div>
                {(() => {
                    const { EditComponent, label } = ALL_OPTIONS[details];

                    return (
                        <div>
                            <TitleWrapperStyledSmall>
                                <IconWrapperLeft>
                                    <Button
                                        variant="outlined-white"
                                        icon={<ChevronLeftIcon />}
                                        onClick={() => {
                                            setDetails(null);
                                        }}
                                    ></Button>
                                </IconWrapperLeft>
                                <LinkXS>
                                    <span className="uppercase">{label}</span>
                                </LinkXS>
                            </TitleWrapperStyledSmall>

                            <EditComponent
                                isOpen={true}
                                onOpenDetails={onOpenDetails}
                                onChangeVisibilitySubDetails={onChangeVisibilitySubDetails}
                            />

                            <StickyFooterStyled padding="12px 0 32px 0">
                                <Button
                                    full
                                    variant="primary"
                                    onClick={() => {
                                        setDetails(null);
                                    }}
                                    dataTestId="footer-sidebar-back-button"
                                >
                                    <Tr labelKey="back_button" defaultValue="Back" />
                                </Button>
                            </StickyFooterStyled>
                        </div>
                    );
                })()}
            </div>
        );
    }

    return (
        <div>
            <SidebarPanelTabs />
            <ContainerForCardComponentWrapper>
                {OPTION_ORDER.map((key) => {
                    const visible = allOptionVisibilityStatus[key];
                    const { doorVisible, insideElementsVisible, ItemComponent } = ALL_OPTIONS[key];

                    return visible ? (
                        <ItemComponent
                            key={key}
                            onClick={() => {
                                setDetails(key);
                                if (doorVisible === 'never') {
                                    dispatchRenderer(hideDoors());
                                } else if (doorVisible === 'always') {
                                    dispatchRenderer(showDoors());
                                }

                                if (insideElementsVisible === 'never') {
                                    dispatchRenderer(hideInnerElements());
                                } else if (insideElementsVisible === 'always') {
                                    dispatchRenderer(showInnerElements());
                                }
                            }}
                        />
                    ) : null;
                })}
            </ContainerForCardComponentWrapper>
        </div>
    );
};
