import { assignShelves } from '../store/wardrobe/actions';
import { useDispatch } from 'react-redux';
import { ShelveCode } from '../services/products/domain/ProductCodes';
import { fetchWardrobePrice } from '../store/configurations/actions';
import { useCurrency } from '../components/Currency/CurrencyProvider';

export const useShelves = () => {
    const dispatch = useDispatch<any>();
    const { currentCurrency } = useCurrency();

    const assignShelve = (shelveType: ShelveCode) => () => {
        dispatch(
            assignShelves({
                shelveType,
            }),
        );

        dispatch(
            fetchWardrobePrice({
                currencyCode: currentCurrency,
            }),
        );
    };

    return { assignShelve };
};
