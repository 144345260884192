import { useCallback, useState } from 'react';
import { fetchTexture } from '@formify/frontend-wardrobe-renderer/dist/modules/actions';
import { useWardrobeDispatch } from '@formify/frontend-wardrobe-renderer/dist/modules/context';
import cocoaTextureColor from '../../../../resources/textures/wardrobe/wood/OSLO OAK COCOA BROWN.jpg';
import cocoaTextureNormal from '../../../../resources/textures/wardrobe/wood/OSLO OAK COCOA BROWN n.jpg';
import cocoaTextureSpecular from '../../../../resources/textures/wardrobe/wood/OSLO OAK COCOA BROWN s.jpg';
import minimalGreyTextureColor from '../../../../resources/textures/wardrobe/wood/OSLO OAK MINIMAL GREY.jpg';
import minimalGreyTextureNormal from '../../../../resources/textures/wardrobe/wood/OSLO OAK MINIMAL GREY n.jpg';
import minimalGreyTextureSpecular from '../../../../resources/textures/wardrobe/wood/OSLO OAK MINIMAL GREY s.jpg';
import warmGreyTextureColor from '../../../../resources/textures/wardrobe/wood/VALLEY ASH WARM GREY.jpg';
import warmGreyTextureNormal from '../../../../resources/textures/wardrobe/wood/VALLEY ASH WARM GREY n.jpg';
import warmGreyTextureSpecular from '../../../../resources/textures/wardrobe/wood/VALLEY ASH WARM GREY s.jpg';
import noceBaronaNaturaleTextureColor from '../../../../resources/textures/wardrobe/wood/DZ5_NOCE_BARONA_NATURALE.jpg';
import noceBaronaNaturaleTextureNormal from '../../../../resources/textures/wardrobe/wood/DZ5_NOCE_BARONA_NATURALE_n.jpg';
import noceBaronaNaturaleTextureSpecular from '../../../../resources/textures/wardrobe/wood/DZ5_NOCE_BARONA_NATURALE_s.jpg';
import olmoAralieBiancoTextureColor from '../../../../resources/textures/wardrobe/wood/P04_OLMO_ARALIE_BIANCO.jpg';
import olmoAralieBiancoTextureNormal from '../../../../resources/textures/wardrobe/wood/P04_OLMO_ARALIE_BIANCO_n.jpg';
import olmoAralieBiancoTextureSpecular from '../../../../resources/textures/wardrobe/wood/P04_OLMO_ARALIE_BIANCO_s.jpg';
import zemireCoffeeTextureColor from '../../../../resources/textures/wardrobe/wood/P45_ZEMIRE_COFFEE.jpg';
import zemireCoffeeTextureNormal from '../../../../resources/textures/wardrobe/wood/P45_ZEMIRE_COFFEE_n.jpg';
import zemireCoffeeTextureSpecular from '../../../../resources/textures/wardrobe/wood/P45_ZEMIRE_COFFEE_s.jpg';
import noceVanguardTextureColor from '../../../../resources/textures/wardrobe/wood/P58_NOCE_VANGUARD_LIGHT.jpg';
import noceVanguardTextureNormal from '../../../../resources/textures/wardrobe/wood/P58_NOCE_VANGUARD_LIGHT_n.jpg';
import noceVanguardTextureSpecular from '../../../../resources/textures/wardrobe/wood/P58_NOCE_VANGUARD_LIGHT_s.jpg';
import floorType1_basecolor from '../../../../resources/textures/kitchen/floor/Type 1 - AGT Pruva Lycia/baseColor.png';
import floorType1_normal from '../../../../resources/textures/kitchen/floor/Type 1 - AGT Pruva Lycia/normal.png';
import floorType1_ORM from '../../../../resources/textures/kitchen/floor/Type 1 - AGT Pruva Lycia/ORM.png';
import floorType2_basecolor from '../../../../resources/textures/kitchen/floor/Type 2 - AGT Effect8 Solaro/baseColor.png';
import floorType2_normal from '../../../../resources/textures/kitchen/floor/Type 2 - AGT Effect8 Solaro/normal.png';
import floorType2_ORM from '../../../../resources/textures/kitchen/floor/Type 2 - AGT Effect8 Solaro/ORM.png';
import floorType3_basecolor from '../../../../resources/textures/kitchen/floor/Type 3 - AGT Luna Vegas/baseColor.png';
import floorType3_normal from '../../../../resources/textures/kitchen/floor/Type 3 - AGT Luna Vegas/normal.png';
import floorType3_ORM from '../../../../resources/textures/kitchen/floor/Type 3 - AGT Luna Vegas/ORM.png';
import floorType4_basecolor from '../../../../resources/textures/kitchen/floor/Type 4 - AGT MarcoPolo Hudson/baseColor.png';
import floorType4_normal from '../../../../resources/textures/kitchen/floor/Type 4 - AGT MarcoPolo Hudson/normal.png';
import floorType4_ORM from '../../../../resources/textures/kitchen/floor/Type 4 - AGT MarcoPolo Hudson/ORM.png';
import { useSelector, useStore } from 'react-redux';
import { CardOptionProps } from '../../../Card/components/CardOption/CardOption';
import { setWoodColor } from '../../../../store/wardrobe/actions';
import { getSelectedMaterialValue, getWardrobeTotalPrice } from '../../../../store/wardrobe/selectors';
import {
    availableProductWoodenMaterialOption,
    isProductMaterialOption,
    OptionSelect,
    ProductWoodenMaterialOption,
} from '../../../../services/products/domain/ThreeDParameter';
import { useLockEffect } from '../../../../hooks/useLockEffect';
import { createWardrobeStructureBasedOnState } from '../../../../services/nodes/wardrobe/wardrobe';
import { PriceVisitor } from '../../../../services/nodes/wardrobe/visitor/PriceVisitor';
import { UpdateMaterialVisitor } from '../../../../services/nodes/wardrobe/visitor/UpdateMaterialVisitor';
import { getAvailableMaterialCodeFromWardrobe } from '../../../../store/products/selectors';
import { ColumnMaterialSetting } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { mainRendererContext } from '../../../../rendererContexts/mainRendererContext';
import { RootState } from '../../../../store';
import { CloneNodeVisitor } from '../../../../services/nodes/wardrobe/visitor/CloneNodeVisitor';
import { DetailsContentElement } from '../Details';
import { useLanguage } from '../../../Translations/LanguageProvider';
import { useCurrency } from '../../../Currency/CurrencyProvider';
import { CreateWoodMaterialProps } from '@formify/3d-common-components/dist/materials/createWoodMaterial';
import { RepeatWrapping } from 'three/src/constants';

const texturesKeys = ['textureColor', 'textureSpecular', 'textureNormal'] as const;

export const MATERIALS: {
    [key in ProductWoodenMaterialOption]: ColumnMaterialSetting;
} = {
    BLACK_WOOD: {
        color: 'white',
        textureColor: cocoaTextureColor.src,
        textureSpecular: cocoaTextureSpecular.src,
        textureNormal: cocoaTextureNormal.src,
        heightTextureScale: 130,
        widthTextureScale: 130,
        maskColor: '#fff',
        metalColor: 'rgb(51,45,36)',
        handleColor: 'rgb(236,202,146)',
    },
    NATURAL_WOOD: {
        color: 'white',
        textureColor: warmGreyTextureColor.src,
        textureSpecular: warmGreyTextureSpecular.src,
        textureNormal: warmGreyTextureNormal.src,
        heightTextureScale: 130,
        widthTextureScale: 130,
        maskColor: '#000',
        metalColor: 'rgb(51,45,36)',
        handleColor: 'rgb(236,202,146)',
    },
    WARM_GREY: {
        color: 'white',
        textureColor: minimalGreyTextureColor.src,
        textureSpecular: minimalGreyTextureSpecular.src,
        textureNormal: minimalGreyTextureNormal.src,
        heightTextureScale: 130,
        widthTextureScale: 130,
        maskColor: '#000',
        metalColor: 'rgb(207,201,176)',
        handleColor: 'rgb(207,201,176)',
    },
    QUARTZ: {
        color: 'rgb(61,59,60)',
        textureColor: undefined,
        textureSpecular: undefined,
        textureNormal: undefined,
        heightTextureScale: 130,
        widthTextureScale: 130,
        maskColor: '#fff',
        metalColor: 'rgb(30,31,28)',
        handleColor: 'rgb(30,31,28)',
    },
    NOCE_VANGUARD_LIGHT: {
        color: 'white',
        textureColor: noceVanguardTextureColor.src,
        textureSpecular: noceVanguardTextureSpecular.src,
        textureNormal: noceVanguardTextureNormal.src,
        heightTextureScale: 130,
        widthTextureScale: 130,
        maskColor: '#000',
        metalColor: 'rgb(51,45,36)',
        handleColor: 'rgb(236,202,146)',
    },
    OLMO_ARALIE_BIANCO: {
        color: 'white',
        textureColor: olmoAralieBiancoTextureColor.src,
        textureSpecular: olmoAralieBiancoTextureSpecular.src,
        textureNormal: olmoAralieBiancoTextureNormal.src,
        heightTextureScale: 130,
        widthTextureScale: 130,
        maskColor: '#000',
        metalColor: 'rgb(207,201,176)',
        handleColor: 'rgb(207,201,176)',
    },
    ZEMIRE_COFFEE: {
        color: 'white',
        textureColor: zemireCoffeeTextureColor.src,
        textureSpecular: zemireCoffeeTextureSpecular.src,
        textureNormal: zemireCoffeeTextureNormal.src,
        heightTextureScale: 130,
        widthTextureScale: 130,
        maskColor: '#fff',
        metalColor: 'rgb(51,45,36)',
        handleColor: 'rgb(236,202,146)',
    },
    NOCE_BARONA_NATURALE: {
        color: 'white',
        textureColor: noceBaronaNaturaleTextureColor.src,
        textureSpecular: noceBaronaNaturaleTextureSpecular.src,
        textureNormal: noceBaronaNaturaleTextureNormal.src,
        heightTextureScale: 130,
        widthTextureScale: 130,
        maskColor: '#000',
        metalColor: 'rgb(51,45,36)',
        handleColor: 'rgb(236,202,146)',
    },
    ELEGANT_BLACK: {
        color: 'rgb(30,31,28)',
        textureColor: undefined,
        textureSpecular: undefined,
        textureNormal: undefined,
        heightTextureScale: 130,
        widthTextureScale: 130,
        maskColor: '#fff',
        metalColor: 'rgb(30,31,28)',
        handleColor: 'rgb(30,31,28)',
    },
    WARM_WHITE: {
        color: 'rgb(207,201,176)',
        textureColor: undefined,
        textureSpecular: undefined,
        textureNormal: undefined,
        heightTextureScale: 130,
        widthTextureScale: 130,
        maskColor: '#000',
        metalColor: 'rgb(207,201,176)',
        handleColor: 'rgb(207,201,176)',
    },
};

export const FLOOR_MATERIALS: {
    [key in 'BLACK_WOOD' | 'NATURAL_WOOD' | 'WARM_GREY' | 'NULL']: CreateWoodMaterialProps;
} = {
    BLACK_WOOD: {
        mapTexturePath: floorType1_basecolor.src,
        aoTexturePath: floorType1_ORM.src,
        roughnessTexturePath: floorType1_ORM.src,
        metalnessTexturePath: floorType1_ORM.src,
        normalMapTexturePath: floorType1_normal.src,
        textureScale: 1.128,
        textureWrapping: RepeatWrapping,
    },
    NATURAL_WOOD: {
        mapTexturePath: floorType2_basecolor.src,
        aoTexturePath: floorType2_ORM.src,
        roughnessTexturePath: floorType2_ORM.src,
        metalnessTexturePath: floorType2_ORM.src,
        normalMapTexturePath: floorType2_normal.src,
        textureScale: 1.128,
        textureWrapping: RepeatWrapping,
    },
    WARM_GREY: {
        mapTexturePath: floorType3_basecolor.src,
        aoTexturePath: floorType3_ORM.src,
        roughnessTexturePath: floorType3_ORM.src,
        metalnessTexturePath: floorType3_ORM.src,
        normalMapTexturePath: floorType3_normal.src,
        textureScale: 1.128,
        textureWrapping: RepeatWrapping,
    },
    NULL: {
        mapTexturePath: floorType4_basecolor.src,
        aoTexturePath: floorType4_ORM.src,
        roughnessTexturePath: floorType4_ORM.src,
        metalnessTexturePath: floorType4_ORM.src,
        normalMapTexturePath: floorType4_normal.src,
        textureScale: 1.128,
        textureWrapping: RepeatWrapping,
    },
};

const INITIAL_PRICE_STATE: { [key in ProductWoodenMaterialOption]: null } = {
    BLACK_WOOD: null,
    NATURAL_WOOD: null,
    WARM_GREY: null,
    QUARTZ: null,
    NOCE_BARONA_NATURALE: null,
    NOCE_VANGUARD_LIGHT: null,
    OLMO_ARALIE_BIANCO: null,
    ZEMIRE_COFFEE: null,
    ELEGANT_BLACK: null,
    WARM_WHITE: null,
};

const visibleColors: ProductWoodenMaterialOption[] = [
    'BLACK_WOOD',
    'NATURAL_WOOD',
    'WARM_GREY',
    'QUARTZ',
    'NOCE_BARONA_NATURALE',
    'NOCE_VANGUARD_LIGHT',
    'OLMO_ARALIE_BIANCO',
    'ZEMIRE_COFFEE',
];

export const useColorOptions = (onOpenDetails: (detailsValues: DetailsContentElement) => void, isOpen: boolean) => {
    const rendererDispatch = useWardrobeDispatch(mainRendererContext);
    const selectedMaterial = useSelector(getSelectedMaterialValue);
    const totalPrice = useSelector(getWardrobeTotalPrice);
    const priceService = useSelector((state: RootState) => state.dependency.services?.price);
    const { getState, dispatch } = useStore<RootState, any>();
    const frames = useSelector(getAvailableMaterialCodeFromWardrobe);
    const { currentLanguage } = useLanguage();
    const { currentCurrency } = useCurrency();

    const [prices, setPrices] = useState<{
        [key in ProductWoodenMaterialOption]: null | number;
    }>(INITIAL_PRICE_STATE);

    const showItemDetails = useCallback(
        (itemDetails: OptionSelect | null) => {
            onOpenDetails({
                type: 'option',
                props: {
                    option: itemDetails,
                },
            });
        },
        [onOpenDetails],
    );

    const fetchPrices = useCallback<
        () => Promise<{
            [key in ProductWoodenMaterialOption]: null | number;
        }>
    >(() => {
        if (!isOpen) {
            return Promise.resolve(INITIAL_PRICE_STATE);
        }

        if (!priceService) {
            return Promise.reject(new Error('priceService not exist'));
        }

        const state = getState();
        const wardrobe = createWardrobeStructureBasedOnState(state);

        if (wardrobe === null || totalPrice === null) {
            return Promise.resolve(INITIAL_PRICE_STATE);
        }
        const cloneVisitor = new CloneNodeVisitor();
        const wardrobeClone = cloneVisitor.visitWardrobeNode(wardrobe);
        const priceVisitor = new PriceVisitor();

        return Promise.all(
            availableProductWoodenMaterialOption.map<Promise<{ [key in ProductWoodenMaterialOption]?: number }>>(
                async (option) => {
                    const changeMaterialVisitor = new UpdateMaterialVisitor(option);

                    changeMaterialVisitor.visitWardrobeNode(wardrobeClone);
                    const natural = priceVisitor.visitWardrobeNode(wardrobeClone);

                    return {
                        [option]:
                            (await priceService.fetchPrice(natural, currentCurrency)).unitPriceWithChildItems -
                            totalPrice,
                    };
                },
            ),
        ).then((prices) => {
            const result = prices.reduce<{
                [key in ProductWoodenMaterialOption]: null | number;
            }>((acc, price) => ({ ...acc, ...price }), { ...INITIAL_PRICE_STATE });

            return result;
        });
    }, [getState, priceService, isOpen, totalPrice, currentCurrency]);

    useLockEffect(fetchPrices, setPrices);

    return (frames || []).reduce<(CardOptionProps & { prepareTexture: () => void })[]>((acc, item) => {
        const value = isProductMaterialOption(item.value) ? item.value : null;

        if (value && (visibleColors.includes(value) || value === selectedMaterial)) {
            acc.push({
                onClick: () => {
                    dispatch(setWoodColor(value));
                },
                onDetailsClick: () => showItemDetails(item),
                title: item.name[currentLanguage] || '',
                price: prices[value] || 0,
                dataTestId: item.value.toLowerCase(),
                variant: 'text-outside',
                imgPath: item.image.path,
                isLoadingPrice: prices[value] === null,
                isSelected: selectedMaterial === value,
                prepareTexture: () => {
                    texturesKeys.forEach((key) => {
                        const src = MATERIALS[value][key];

                        src && rendererDispatch(fetchTexture(src));
                    });
                },
            });
        }

        return acc;
    }, []);
};
