import { useContext } from 'react';
import { configMachineServiceContext } from '../components/ConfiguratorStateMachineProvider/ConfiguratorStateMachineProvider';
import { useLoadProject } from './useLoadProject';

export const useResetProject = () => {
    const { configMachineService } = useContext(configMachineServiceContext);
    const loadProject = useLoadProject();

    return () => {
        if (configMachineService !== null) {
            configMachineService.send({
                type: 'RESET',
            });
            setTimeout(() => {
                configMachineService.send({
                    type: 'BACK_TO_CONFIG',
                });
            }, 1);
        }
        loadProject('default');
    };
};
