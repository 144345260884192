import { createMachine, assign, StatesConfig, Transition, send } from 'xstate';
import { VisibleOptionsResult } from '../../../hooks/useVisibleOptions';
import { OptionName } from '../constants';
import { STEPS_SYSTEM_ORDER } from '../SidePanelSteps';
// import { inspect } from '@xstate/inspect';

// if (typeof window !== 'undefined') {
//     inspect({
//         // options
//         // url: 'https://stately.ai/viz?inspect', // (default)
//         url: 'https://statecharts.io/inspect',
//         iframe: false, // open in new window
//     });
// }
interface NextActionPayload {
    visibility: VisibleOptionsResult;
    numberOfColumn: number;
}

type GoToActionPayload = {
    name: OptionName;
    columnIndex?: number;
    visibility?: VisibleOptionsResult;
};

type ConfigEvent =
    | { type: 'NEXT'; payload: NextActionPayload }
    | { type: 'GOTO'; payload: GoToActionPayload }
    | { type: 'RESET' }
    | { type: 'BACK_TO_CONFIG' };

interface ConfigContext {
    selectedColumn: number | null;
    visitedColumn: number | null;
    visited: OptionName[];
}

type ConfigTypeState = {
    value: OptionName;
    context: ConfigContext;
};

const addNextAndGoToAction = (
    next: Transition<ConfigContext, ConfigEvent>,
): StatesConfig<ConfigContext, any, any, any>[string]['on'] => ({
    NEXT:
        typeof next === 'string'
            ? STEPS_SYSTEM_ORDER.map((stepName) => ({
                  target: stepName,

                  actions: assign((ctx: ConfigContext, action: ConfigEvent) => {
                      if (stepName === 'columnSetting' && action.type === 'NEXT') {
                          return { selectedColumn: ctx.visitedColumn };
                      }

                      return {};
                  }),

                  cond: (ctx: ConfigContext, action: ConfigEvent) => {
                      const omitCurrentSteps = STEPS_SYSTEM_ORDER.slice(STEPS_SYSTEM_ORDER.indexOf(next as any));

                      const allVisited = omitCurrentSteps.every((name) => {
                          const isVisible =
                              action.type === 'NEXT' && name !== 'summary' && action.payload.visibility[name];

                          return ctx.visited.includes(name) || !isVisible;
                      });

                      const allLayoutVisited =
                          action.type === 'NEXT' && action.payload.numberOfColumn <= (ctx.visitedColumn || 0) + 1;

                      if (allVisited && allLayoutVisited) {
                          return action.type === 'NEXT' && stepName === 'summary';
                      }

                      if (allVisited) {
                          if (!allLayoutVisited) {
                              return action.type === 'NEXT' && stepName === 'columnSetting';
                          }
                          const firstVisibility = omitCurrentSteps.find(
                              (name) => action.type === 'NEXT' && name !== 'summary' && action.payload.visibility[name],
                          );

                          return action.type === 'NEXT' && stepName === firstVisibility;
                      }

                      const firstNotVisited = omitCurrentSteps.find(
                          (name) =>
                              !ctx.visited.includes(name) &&
                              action.type === 'NEXT' &&
                              name !== 'summary' &&
                              action.payload.visibility[name],
                      );

                      return action.type === 'NEXT' && stepName === firstNotVisited;
                  },
              }))
            : next,
    GOTO: STEPS_SYSTEM_ORDER.map((stepName) => ({
        target: stepName,

        cond: (ctx: ConfigContext, action: ConfigEvent) =>
            action.type === 'GOTO' && action.payload.name === stepName && ctx.visited.includes(stepName),
    })),
    RESET: 'resetWaiting',
});

const visitedAssign = (stepName: OptionName) =>
    assign((ctx: ConfigContext) => ({
        visited: ctx.visited.includes(stepName) ? ctx.visited : [...ctx.visited, stepName],
    }));

const nextIsColumnSettingStep = (ctx: ConfigContext, action: ConfigEvent) => {
    const selectedColumn = ctx.selectedColumn ?? 0;

    if (action.type === 'GOTO') {
        const index = action.payload.columnIndex;
        const visitedColumn = ctx.visitedColumn;

        if (typeof index === 'number' && typeof visitedColumn === 'number') {
            return visitedColumn > index - 1;
        }

        return false;
    } else if (action.type === 'NEXT') {
        const allLayoutVisited = action.payload.numberOfColumn <= (ctx.visitedColumn || 0) + 1;

        if (allLayoutVisited) {
            return false;
        }

        return selectedColumn < action.payload.numberOfColumn - 1;
    }

    return false;
};

const INITIAL_CONTEXT_VALUES: ConfigContext = {
    selectedColumn: null,
    visitedColumn: null,
    visited: [],
};

export const configMachine = createMachine<ConfigContext, ConfigEvent, ConfigTypeState>({
    id: 'config',
    initial: 'init',
    context: { ...INITIAL_CONTEXT_VALUES },
    states: {
        wardrobeType: {
            exit: [visitedAssign('wardrobeType')],
            entry: ['setFreeCamera', 'hideDoors', 'clearFocus'],
            on: addNextAndGoToAction('measurements'),
        },

        measurements: {
            exit: visitedAssign('measurements'),
            entry: ['setFreeCamera', 'clearFocus'],
            on: addNextAndGoToAction('frames'),
        },

        measurementsA: {
            exit: visitedAssign('measurementsA'),
            entry: ['setSelectedWallACamera', 'clearFocus'],
            on: addNextAndGoToAction('measurementsB'),
        },

        measurementsB: {
            exit: visitedAssign('measurementsB'),
            entry: ['setSelectedWallBCamera', 'clearFocus'],
            on: addNextAndGoToAction('measurementsC'),
        },

        measurementsC: {
            exit: visitedAssign('measurementsC'),
            entry: ['setSelectedWallCCamera', 'clearFocus'],
            on: addNextAndGoToAction('frames'),
        },

        frames: {
            exit: visitedAssign('frames'),
            entry: ['setFreeCamera', 'clearFocus', 'showInnerElements'],
            on: addNextAndGoToAction('doors'),
        },

        doors: {
            exit: visitedAssign('doors'),
            entry: ['setFreeCamera', 'showDoors', 'clearFocus'],
            on: addNextAndGoToAction('handles'),
        },

        handles: {
            exit: visitedAssign('handles'),
            entry: ['setFreeCamera', 'showDoors', 'clearFocus'],
            on: addNextAndGoToAction('led'),
        },

        led: {
            exit: visitedAssign('led'),
            entry: ['setFreeCamera', 'hideDoors', 'clearFocus'],
            on: addNextAndGoToAction('shelves'),
        },

        shelves: {
            exit: visitedAssign('shelves'),

            entry: ['setFreeCamera', 'hideDoors', 'clearFocus'],
            on: addNextAndGoToAction('columnSetting'),
        },

        columnSetting: {
            exit: [
                visitedAssign('columnSetting'),
                assign((ctx, action) => {
                    const nextIsColumn = nextIsColumnSettingStep(ctx, action);
                    const visitedColumn = Math.max(ctx.visitedColumn ?? 0, ctx.selectedColumn ?? 0);

                    if (nextIsColumn) {
                        return {
                            visitedColumn,
                        };
                    }

                    return {
                        visitedColumn,
                        selectedColumn: null,
                    };
                }),
                'clearFocus',
            ],

            entry: [
                'hideDoors',
                assign((ctx, action) => {
                    if (action.type === 'GOTO') {
                        const index = action.payload.columnIndex;

                        if (typeof index === 'number') {
                            return {
                                selectedColumn: action.payload.columnIndex,
                            };
                        }
                    } else if (action.type === 'NEXT') {
                        return {
                            selectedColumn: ctx.selectedColumn === null ? 0 : ctx.selectedColumn + 1,
                        };
                    }

                    return ctx;
                }),
                'focusColumn',
                'setSelectedWallCamera',
            ],
            on: addNextAndGoToAction([
                {
                    target: 'columnSetting',
                    cond: nextIsColumnSettingStep,
                },
                {
                    target: 'summary',
                },
            ]),
        },

        summary: {
            exit: [visitedAssign('summary')],
            entry: ['setFreeCamera'],
            on: addNextAndGoToAction('summary'),
        },

        init: {
            entry: [
                'setFreeCamera',
                'showDoors',
                'hideInnerElements',
                assign(() => ({ ...INITIAL_CONTEXT_VALUES })),
                send('RESET'),
            ],
            on: {
                RESET: 'wardrobeType',
            },
        },
        resetWaiting: {
            on: {
                BACK_TO_CONFIG: 'init',
            },
        },
    },
});
