import React, { FC } from 'react';
import { CardOption } from '../../../Card/components/CardOption/CardOption';
import { SidePanelEditComponentProps } from '../../constants';
import { TwoColumnCardContainer } from '../../../Card/components/CardOption/CardOption.styled';
import { useHandlePositionOptions } from './useHandlePositionOptions';

type HandlesProps = SidePanelEditComponentProps;

export const HandlePosition: FC<HandlesProps> = ({ onOpenDetails }) => {
    const options = useHandlePositionOptions(onOpenDetails);

    return (
        <TwoColumnCardContainer data-test="two_column_card_container">
            {options.map((option, index) => (
                <CardOption key={index} {...option} />
            ))}
        </TwoColumnCardContainer>
    );
};
