import React, { createContext } from 'react';
import { InterpreterFrom } from 'xstate';
import { configMachine } from '../SidePanel/machines/configMachine';
import { useInterpret } from '@xstate/react';
import { setSelectedColumnIndex } from '../../store/wardrobe/actions';
import {
    hideDoors,
    hideInnerElements,
    setCameraStateAction,
    setFocusWallAction,
    showDoors,
    showInnerElements,
} from '@formify/frontend-wardrobe-renderer/dist/modules/actions';
import { useWardrobeDispatch } from '@formify/frontend-wardrobe-renderer/dist/modules/context';
import { mainRendererContext } from '../../rendererContexts/mainRendererContext';
import { useStore } from 'react-redux';
import { RootState } from '../../store';
import { getWallAtPosition } from '../../store/wardrobe/selectors';

export const configMachineServiceContext = createContext<{
    configMachineService: InterpreterFrom<typeof configMachine> | null;
}>({
    configMachineService: null,
});

export const ConfiguratorStateMachineProvider = (props: { children: React.ReactNode }) => {
    const dispatchRenderer = useWardrobeDispatch(mainRendererContext);
    const { dispatch, getState } = useStore<RootState, any>();

    const configMachineService = useInterpret(configMachine, {
        // devTools: true,
        actions: {
            focusColumn: (ctx) => {
                dispatch(setSelectedColumnIndex(ctx.selectedColumn));
            },
            clearFocus: () => {
                dispatch(setSelectedColumnIndex(null));
            },
            hideDoors: () => {
                dispatchRenderer(hideDoors());
            },
            showDoors: () => {
                dispatchRenderer(showDoors());
            },

            hideInnerElements: () => {
                dispatchRenderer(hideInnerElements());
            },
            showInnerElements: () => {
                dispatchRenderer(showInnerElements());
            },

            setSelectedWallCamera: () => {
                const state = getState();
                const index = state.wardrobe.selectedColumnIndex || 0;
                const focusColumnWall = state.wardrobe.columns[index]?.wall || 'A';
                const isSingleWall = state.wardrobe.columns.every((column) => column.wall === 'A' || column.hidden);

                dispatchRenderer(setFocusWallAction(focusColumnWall));
                if (!isSingleWall) {
                    dispatchRenderer(
                        setCameraStateAction({
                            type: 'uShapeWall',
                            position: focusColumnWall,
                        }),
                    );
                }
            },

            setSelectedWallACamera: () => {
                const state = getState();

                dispatchRenderer(setFocusWallAction('A'));

                dispatchRenderer(
                    setCameraStateAction({
                        type: 'uShapeWall',
                        position: getWallAtPosition(0)(state) || 'A',
                    }),
                );
            },

            setSelectedWallBCamera: () => {
                const state = getState();

                dispatchRenderer(setFocusWallAction('B'));

                dispatchRenderer(
                    setCameraStateAction({
                        type: 'uShapeWall',
                        position: getWallAtPosition(1)(state) || 'B',
                    }),
                );
            },

            setSelectedWallCCamera: () => {
                const state = getState();

                dispatchRenderer(setFocusWallAction('C'));

                dispatchRenderer(
                    setCameraStateAction({
                        type: 'uShapeWall',
                        position: getWallAtPosition(2)(state) || 'C',
                    }),
                );
            },

            setFreeCamera: () => {
                const state = getState();
                const isSingleWall = state.wardrobe.columns.every((column) => column.wall === 'A' || column.hidden);

                dispatchRenderer(setFocusWallAction(null));
                if (isSingleWall) {
                    dispatchRenderer(setCameraStateAction({ type: 'singleWall', position: 'center' }));
                } else {
                    dispatchRenderer(setCameraStateAction({ type: 'uShape', position: 'center' }));
                }
            },
        },
    });

    return (
        <configMachineServiceContext.Provider value={{ configMachineService }}>
            {props.children}
        </configMachineServiceContext.Provider>
    );
};
