import React from 'react';
import { useSelector } from 'react-redux';
import { CardComponent } from '../../Card/components/CardComponent/CardComponent';
import { RootState } from '../../../store';
import { useMediaByType } from '../../../store/media/selectors';
import { getSelectedDoorProductForWardrobe, getProductByType } from '../../../store/products/selectors';
import { MediaType } from '../../../services/media/domain/MediaType';

function getCurrentDoorOption(state: RootState): MediaType {
    const selectedDoorProduct = getSelectedDoorProductForWardrobe(state);

    if (selectedDoorProduct === null) {
        return 'without_doors';
    }
    const glassProduct = getProductByType('door_glass')(state);

    return glassProduct === selectedDoorProduct ? 'glass_doors' : 'with_doors';
}

interface DoorItemProps {
    onClick(): void;
}

export const DoorItem = ({ onClick }: DoorItemProps) => {
    const mediaType = useSelector(getCurrentDoorOption);
    const currentDoorOption = useMediaByType(mediaType);

    return (
        <CardComponent
            subtitle="Door"
            key="Door"
            title={currentDoorOption.name}
            onClick={onClick}
            imgPath={currentDoorOption.image}
            dataTestId="door"
        />
    );
};
