import React, { FC, useState, useCallback, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
    getAdditionalFrameSize,
    getCurrentWardrobeMeasurementsWall,
    getPreparedSelectedColumn,
    getWallAtPosition,
} from '../../../../../../store/wardrobe/selectors';
import { Container, Wrapper } from './MeasurementsMobile.styled';
import { getWardrobeDimensions } from '../../../../../../store/products/selectors';
import { MobileMenuItemTooltip } from '../../../../../MobileMenuItemTooltip/MobileMenuItemTooltip';
import { useMediaByType } from '../../../../../../store/media/selectors';
import { SimplifyMedia } from '../../../../../../store/media/reducer';
import { ItemDetails } from '../../../ItemDetails/ItemDetails';
import { SMALL_DEPTH } from '../../utils';
import { convertValueFromMmToCm } from '../../../../../../store/products/utils';
import { DepthMeasurementSlider } from '../DepthMeasurementSlider/DepthMeasurementSlider';
import { WidthMeasurementSlider } from '../WidthMeasurementSlider/WidthMeasurementSlider';
import { mainRendererContext } from '../../../../../../rendererContexts/mainRendererContext';
import { setCameraStateAction, setFocusWallAction } from '@formify/frontend-wardrobe-renderer/dist/modules/actions';
import { useLanguage } from '../../../../../Translations/LanguageProvider';

interface MeasurementsMobileWallProps {
    wallIndex: number;
    isOpen: boolean;
}

export const MeasurementsMobileWall: FC<MeasurementsMobileWallProps> = ({ wallIndex, isOpen }) => {
    const wall = useSelector(getWallAtPosition(wallIndex)) || 'A';
    const [activeMeasurementType, setActiveMeasurementType] = useState<'width' | 'depth' | null>(null);
    const currentWardrobeMeasurements = useSelector(getCurrentWardrobeMeasurementsWall(wall));
    const { wardrobeDepth } = useSelector(getWardrobeDimensions);
    const selectedColumn = useSelector(getPreparedSelectedColumn);
    const measuringElement = selectedColumn || currentWardrobeMeasurements;
    const depthMediaMin = useMediaByType('43cm_depth');
    const depthMediaMax = useMediaByType('58cm_depth');
    const [details, setDetails] = useState<SimplifyMedia | null>(null);
    const { store } = useContext(mainRendererContext);
    const { t } = useLanguage();

    useEffect(() => {
        if (isOpen) {
            store.dispatch(setFocusWallAction(wall));

            store.dispatch(
                setCameraStateAction({
                    type: 'uShapeWall',
                    position: wall,
                }),
            );

            return () => {
                store.dispatch(setFocusWallAction(null));

                store.dispatch(
                    setCameraStateAction({
                        type: 'uShape',
                        position: 'center',
                    }),
                );
            };
        }
    }, [isOpen, store, wall]);

    const depthMedia = SMALL_DEPTH >= measuringElement.depth ? depthMediaMin : depthMediaMax;

    const onDepthOptionClick = useCallback(() => {
        setDetails(depthMedia);
    }, [depthMedia]);

    const { additionalWidth } = useSelector(getAdditionalFrameSize);

    if (wardrobeDepth === null) {
        return null;
    }

    return (
        <>
            <Container bottomSpacing={88}>
                <Wrapper>
                    {activeMeasurementType === 'depth' && (
                        <MobileMenuItemTooltip
                            label={depthMedia.name}
                            bottomSpacing={217}
                            onClick={onDepthOptionClick}
                        ></MobileMenuItemTooltip>
                    )}

                    {activeMeasurementType === 'width' && (
                        <MobileMenuItemTooltip
                            label={t('measurement_info_width', 'Width: ${value}cm', {
                                value: convertValueFromMmToCm(
                                    selectedColumn
                                        ? measuringElement[activeMeasurementType]
                                        : measuringElement[activeMeasurementType] + additionalWidth,
                                ),
                            })}
                            bottomSpacing={217}
                        ></MobileMenuItemTooltip>
                    )}
                    <div
                        style={{
                            background: '#F5F3F0',
                            padding: '30px 20px',
                            margin: '20px -25px -28px -25px',
                        }}
                    >
                        <div className="mb-48">
                            <WidthMeasurementSlider
                                onChange={() => {
                                    setActiveMeasurementType('width');
                                }}
                                wall={wall}
                            />
                        </div>
                        <DepthMeasurementSlider
                            onChange={() => {
                                setActiveMeasurementType('depth');
                            }}
                            wall={wall}
                        />
                    </div>
                </Wrapper>
            </Container>
            {details && (
                <ItemDetails
                    name={details.name}
                    description={details.description}
                    // image={details.image}
                    onClose={() => {
                        setDetails(null);
                    }}
                />
            )}
        </>
    );
};
