import { useDispatch } from 'react-redux';
import { useTakeScreenshot } from '../components/TakeScreenshot/useTakeScreenshot';
import {
    fetchOrderData,
    saveCurrentWardrobeAsProject,
    setLoadingAddingProjectToCartStatus,
} from '../store/project/actions';
import { SaveProjectResponse } from '../types/Project';
import { useGet3DFile } from './useGet3DFile';
import { getServices } from '../store/dependency/selectors';
import { useStore } from 'react-redux';
import { RootState } from '../store';
import { useCurrency } from '../components/Currency/CurrencyProvider';

export const useCurrentProjectSave = () => {
    const dispatch = useDispatch<any>();
    const getScreenshot = useTakeScreenshot();
    const get3DFile = useGet3DFile();
    const { currentCurrency } = useCurrency();

    return (email?: string, purpose?: string, threeDimensionFileType?: 'glb' | 'usdz-and-glb-for-ar') =>
        dispatch(
            saveCurrentWardrobeAsProject({
                email,
                purpose,
                getScreenshot,
                get3DFile,
                threeDimensionFileType,
                currencyCode: currentCurrency,
            }),
        ).unwrap() as Promise<SaveProjectResponse>;
};

export const useCurrentWardrobeAddToCart = () => {
    const { getState, dispatch } = useStore<RootState, any>();
    const getScreenshot = useTakeScreenshot();
    const get3DFile = useGet3DFile();
    const { currentCurrency } = useCurrency();

    return async () => {
        try {
            const state = getState();
            const { cart } = getServices(state);
            const cartIri = await cart.getCartIri();

            dispatch(setLoadingAddingProjectToCartStatus(true));
            await dispatch(
                saveCurrentWardrobeAsProject({ getScreenshot, cartIri, get3DFile, currencyCode: currentCurrency }),
            );

            dispatch(setLoadingAddingProjectToCartStatus(false));
        } catch (error) {
            dispatch(setLoadingAddingProjectToCartStatus(false));

            return Promise.reject(error);
        }
        await dispatch(fetchOrderData());
    };
};
