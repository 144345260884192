import React, { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@formify/frontend-components';
import { getIsAddingProject, getUpdateOrderByAdminSettings } from '../../../../store/project/selectors';
import { useActor } from '@xstate/react';
import { useCurrentProjectSave, useCurrentWardrobeAddToCart } from '../../../../hooks/useCurrentProjectSave';
import { authServiceFactory } from '../../../../services/auth/authServiceFactory';
import { fetchServiceFactory } from '../../../../services/fetch/fetchFabric';
import { captureException } from '@sentry/nextjs';
import { configMachineServiceContext } from '../../../ConfiguratorStateMachineProvider/ConfiguratorStateMachineProvider';
// import { __internal__sendEventToSDK } from 'formify-sdk';
import { cartContext } from '../../../CartSidePanel/CartSidePanelProvider';
import { Tr } from '../../../Translations/Tr';

export const AddCurrentWardrobeToCart = () => {
    const { configMachineService } = useContext(configMachineServiceContext);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [, send] = useActor(configMachineService!);
    const isAddingProductToCart = useSelector(getIsAddingProject);
    const updateOrderByAdminSettings = useSelector(getUpdateOrderByAdminSettings);
    const addWardrobeAddToCart = useCurrentWardrobeAddToCart();
    const saveCurrentWardrobeAsProject = useCurrentProjectSave();
    const [isLoading, setIsLoading] = useState(false);
    const { openCart } = useContext(cartContext);

    const handleAdd = useCallback(async () => {
        await addWardrobeAddToCart();

        send('RESET');

        openCart();
    }, [addWardrobeAddToCart, openCart, send]);

    // TODO: Add this back when we want support for embedding as iframe
    // if (window.parent !== window) {
    //     return (
    //         <Button
    //             className="ml-auto"
    //             variant="primary"
    //             onClick={async () => {
    //                 try {
    //                     setIsLoading(true);
    //                     const {
    //                         id,
    //                         mainItem: { images, projectItemFiles },
    //                     } = await saveCurrentWardrobeAsProject();

    //                     const mediaUrl = await getPublicEnv('MEDIA_DOMAIN_URL');
    //                     const domain = await getDomainName();

    //                     __internal__sendEventToSDK('projectSaved', {
    //                         id: id,
    //                         images: images.map(({ path, type }) => ({
    //                             type,
    //                             path: `${mediaUrl}${path}`,
    //                         })),
    //                         shopUrl: `${domain}/configurator?projectId=${id}`,
    //                         gltfFile: projectItemFiles.find(({ type }) => type === 'gltf')?.absoluteUrl ?? '',
    //                     });
    //                 } catch (error) {
    //                     captureException(error);
    //                 } finally {
    //                     setIsLoading(false);
    //                 }
    //             }}
    //             isLoading={isLoading}
    //             dataTestId="embed-button"
    //         >
    //             Save project
    //         </Button>
    //     );
    // }

    if (updateOrderByAdminSettings) {
        return (
            <Button
                className="ml-auto"
                variant="subtle"
                onClick={async () => {
                    try {
                        setIsLoading(true);

                        const { id } = await saveCurrentWardrobeAsProject();
                        const auth = authServiceFactory(updateOrderByAdminSettings.token);
                        const fetch = fetchServiceFactory(auth);

                        await fetch.post('/api/v2/admin/order-events', {
                            targetValue: {
                                orderItem: updateOrderByAdminSettings.orderItemId,
                                project: id,
                            },
                        });
                        window.close();
                    } catch (error) {
                        captureException(error);
                    } finally {
                        setIsLoading(false);
                    }
                }}
                isLoading={isLoading}
                dataTestId="update-order-button"
            >
                Update order
            </Button>
        );
    }

    return (
        <Button
            className="ml-auto"
            variant="primary"
            onClick={handleAdd}
            isLoading={isAddingProductToCart}
            dataTestId="add_to_cart_button"
        >
            <Tr labelKey="Add to cart" defaultValue="Add to cart" />
        </Button>
    );
};
