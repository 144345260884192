import React, { FC } from 'react';

export const InfoTextIcon: FC = () => (
    <svg width="2" height="8" viewBox="0 0 2 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.999114 0.300781C0.557749 0.300781 0.199951 0.658578 0.199951 1.09994C0.199951 1.54131 0.557749 1.89911 0.999114 1.89911C1.44048 1.89911 1.79827 1.54131 1.79827 1.09994C1.79827 0.658578 1.44048 0.300781 0.999114 0.300781ZM0.999119 2.56079C0.612983 2.56079 0.299957 2.87382 0.299957 3.25995V6.75326C0.299957 7.13486 0.605941 7.44598 0.987492 7.45232C1.37812 7.45882 1.69828 7.14394 1.69828 6.75326V3.25995C1.69828 2.87382 1.38526 2.56079 0.999119 2.56079Z"
            fill="currentColor"
        />
    </svg>
);
