import styled from 'styled-components';
import { Color } from '../../../../constants/Color.enum';

interface CardComponentProps {
    error?: boolean;
    disabled?: boolean;
}

export const LeftWrapper = styled.div`
    padding: 19px 20px 21px 19px;
    flex-grow: 1;
`;

export const ImageWrapperStyled = styled.div`
    display: flex;
    width: 80px;
    overflow: hidden;
    border-radius: 0px 10px 10px 0px;
    background-color: ${Color.Background};
`;

export const ContainerStyled = styled.div<CardComponentProps>`
    cursor: pointer;
    opacity: ${(props) => (props.disabled ? '0.2' : '1')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'initial')};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 342px;
    height: 90px;
    border: 1px solid transparent;

    &:active {
        border-color: ${(props) => (props.error ? Color.Error : Color.Primary)};
    }
`;

export const CardComponentWrapper = styled.div<CardComponentProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border: 1px solid ${Color.Background};
    border-radius: 10px;
`;

export const ContainerForCardComponentWrapper = styled.div<CardComponentProps>`
    display: grid;
    gap: 16px;
    padding: 8px 0;
`;
