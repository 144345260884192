import type { CreateScreenParams } from '@formify/frontend-wardrobe-renderer/dist/src/context/actions';
import { createContext } from 'react';

export type GetScreenFunction = (setting: CreateScreenParams) => Promise<Blob>;

export const takeScreenContext = createContext<{
    getScreenshot: GetScreenFunction;
}>({
    getScreenshot: () => Promise.reject('initial promise value'),
});
