import React from 'react';
import { useSelector, useStore } from 'react-redux';
import { Toggle } from '../../../atoms/Toggle/Toggle';
import { getSelectedColumnIndex, getPreviousSelectedColumnIndex } from '../../../../store/wardrobe/selectors';
import { setSelectedColumnIndex } from '../../../../store/wardrobe/actions';
import { WARDROBE_PARTS } from '../../../../constants/products/Wardrobe';
import { RootState } from '../../../../store';
import { useLanguage } from '../../../Translations/LanguageProvider';

export const SidebarPanelTabs = () => {
    const { dispatch } = useStore<RootState, any>();
    const selectedColumnIndex = useSelector(getSelectedColumnIndex);
    const previousSelectedColumnIndex = useSelector(getPreviousSelectedColumnIndex);
    const { t } = useLanguage();

    return (
        <div className="mb-16">
            <Toggle
                firstValue={t(WARDROBE_PARTS.exterior)}
                secondValue={t(WARDROBE_PARTS.interior)}
                selectedValue={selectedColumnIndex === null ? t(WARDROBE_PARTS.exterior) : t(WARDROBE_PARTS.interior)}
                onSelect={(selectedPart) => {
                    if (selectedPart === t(WARDROBE_PARTS.interior)) {
                        if (previousSelectedColumnIndex !== null) {
                            dispatch(setSelectedColumnIndex(previousSelectedColumnIndex));
                        } else {
                            dispatch(setSelectedColumnIndex(0));
                        }
                    } else {
                        dispatch(setSelectedColumnIndex(null));
                    }
                }}
                dataTestId="wardrobe-part"
            />
        </div>
    );
};
